<div class="workbench-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzSpinning]="loading" [nzIndicator]="indicatorTemplate">
    <div *ngIf="!detailType">
      <div class="row" style="margin-bottom: 16px;">
        <div>
          <button nz-button nzType="default"
                  [style]="{color: searchParams.module === 'UNDERWRITING' ? '#1D64C4' : '',
                  background: searchParams.module != 'UNDERWRITING' ? '#F4F4F5' : ''}"
                  appThrottleClick (throttleClick)="onChangeModule('UNDERWRITING')">
            {{ 'Underwriting' | translate }}
          </button>
          <button nz-button nzType="default"
                  [style]="{color: searchParams.module === 'POLICY' ? '#1D64C4' : '',
                  background: searchParams.module != 'POLICY' ? '#F4F4F5' : ''}"
                  style="margin-left: 16px;" appThrottleClick
                  (throttleClick)="onChangeModule('POLICY')">
            {{ 'Policys' | translate }}
          </button>
        </div>

        <div style="display: flex;align-items: center;">
          <button nz-button nzType="default" style="margin-right: 16px;padding: 4px 6px"
                  nz-tooltip [nzTooltipTitle]="'Filter' | translate"
                  nz-popover [nzPopoverContent]="filterTemplate"
                  [(nzPopoverVisible)]="filterPopoverVisible"
                  nzPopoverTrigger="click"
                  nzPopoverPlacement="rightTop">
            <img alt="" width="20" src="assets/images/ic_filter.svg">
          </button>

          <nz-select [(ngModel)]="searchParams.pivot" style="margin-right: 16px;width: 155px;" [nzAllowClear]="true"
                     [nzPlaceHolder]="('PleaseSelect' | translate)"
                     (ngModelChange)="pivotChange($event)">
            <nz-option *ngFor="let option of pivotOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>

          <nz-select *ngIf="searchParams.module === 'UNDERWRITING'"
                     [(ngModel)]="searchParams.status" nzMode="multiple"
                     (ngModelChange)="statusChange($event)" [nzShowSearch]="false"
                     [nzMaxTagCount]="1" [nzShowArrow]="true"
                     [nzAllowClear]="true" [nzPlaceHolder]="('PleaseSelect' | translate)"
                     style="margin-right: 16px;min-width: 155px;">
            <nz-option *ngFor="let option of statusOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>

          <div class="switch-btn">
            <div [class]="{'select-btn': searchParams.dateFilter === 'MONTH'}"
                 style="cursor: pointer;"
                 appThrottleClick (throttleClick)="onChangeDate('MONTH')">{{ 'CurrentMonth' | translate }}</div>
            <div [class]="{'select-btn': searchParams.dateFilter === 'YEAR'}"
                 style="cursor: pointer;"
                 appThrottleClick (throttleClick)="onChangeDate('YEAR')">{{ 'CurrentYear' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="card-row" style="margin-bottom: 16px;">
        <div class="row" *ngIf="searchParams.module === 'UNDERWRITING'">
          <div>{{'DataOverviewUnderwriting' | translate}}</div>
          <div *ngIf="resData.underwriting" style="color: #1A64DD;font-size: 14px;cursor: pointer;" appThrottleClick (throttleClick)="onDetail('UNDERWRITING')">
            {{ 'DetailedData' | translate }}
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </div>
        </div>
        <div class="row" *ngIf="searchParams.module === 'POLICY'">
          <div>{{ 'DataOverviewPolicy' | translate }}</div>
          <div *ngIf="resData.policy" style="color: #1A64DD;font-size: 14px;cursor: pointer;" appThrottleClick (throttleClick)="onDetail('POLICY')">
            {{ 'DetailedData' | translate }}
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </div>
        </div>
        <nz-divider style="margin: 16px 0 24px;"></nz-divider>
        <div nz-row>
          <div nz-col nzSpan="8">
            <div class="text-center">{{ 'CountDashboard' | translate }}</div>
            <div class="text-center text-bold">{{ (resData.count | number) || '-' }}<span *ngIf="resData.count">份</span>
            </div>
          </div>
          <div nz-col nzSpan="8">
            <div class="text-center">{{ 'AFYP' | translate }}</div>
            <div class="text-center text-bold">HKD {{ (resData.premium | number) || '-'}}</div>
          </div>
          <div nz-col nzSpan="8">
            <div class="text-center">{{ 'CompanyForecastPayIn' | translate }}</div>
            <div class="text-center text-bold">HKD {{ (resData.firstCommission | number) || '-'}}</div>
          </div>
        </div>
      </div>
      <div class="card-row" style="position: relative;">
        <div *ngIf="valueChartsOption?.xAxis.data.length" style="margin-bottom: 16px;position: absolute;left: 30px;z-index: 100;">
          <nz-select [(ngModel)]="searchParams.chartsType" style="margin-right: 16px;width: 155px;"
                     (ngModelChange)="chartsChange($event)">
            <nz-option nzValue="countDate" [nzLabel]="'Quantity' | translate"></nz-option>
            <nz-option nzValue="premiumDate" [nzLabel]="'AFYP' | translate "></nz-option>
            <nz-option nzValue="firstCommissionDate" [nzLabel]="'CompanyForecastPayIn' | translate"></nz-option>
          </nz-select>
        </div>

        <div *ngIf="valueChartsOption?.xAxis.data.length" echarts [options]="valueChartsOption" style="height: 500px" [loading]="loading"></div>
        <nz-empty *ngIf="!valueChartsOption?.xAxis.data.length" nzNotFoundImage="simple"></nz-empty>

        <div *ngIf="valueChartsOption?.xAxis.data.length" style="font-size: 12px;color: #999999;padding-left: 10px;display: flex;align-items: center;">
          <span style="display: inline-block;width: 6px;height: 6px;border-radius: 50%;background: #999999;margin-right: 8px;"></span>
          <span style="margin-right: 40px;">{{'SubordinateDirectSubordinate' | translate}}</span>
          <span style="display: inline-block;width: 6px;height: 6px;border-radius: 50%;background: #999999;margin-right: 8px;"></span>
          <span>{{'AllSubordinatesIncludingLevels' | translate}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="detailType === 'UNDERWRITING'">
      <div class="row" style="margin-bottom: 16px;">
        <div>
          <button nz-button nzType="default"
                  appThrottleClick (throttleClick)="onBack()">
            <img alt="" width="20" src="assets/images/ic_left.svg">
            {{'ReturnBtn' | translate}}
          </button>
        </div>

        <div style="display: flex;align-items: center;">
          <nz-select [(ngModel)]="underwritingSearchParams.pivot" style="margin-right: 16px;width: 155px;" [nzAllowClear]="true"
                     [nzPlaceHolder]="('PleaseSelect' | translate)"
                     (ngModelChange)="pivotUnderwritingChange($event)">
            <nz-option *ngFor="let option of pivotOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>

          <nz-select [(ngModel)]="underwritingSearchParams.status" nzMode="multiple"
                     (ngModelChange)="statusUNChange($event)"
                     [nzMaxTagCount]="1"
                     [nzAllowClear]="true" [nzPlaceHolder]="('PleaseSelect' | translate)"
                     style="margin-right: 16px;min-width: 155px;">
            <nz-option *ngFor="let option of statusOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>

          <div class="switch-btn">
            <div [class]="{'select-btn': underwritingSearchParams.dateFilter === 'MONTH'}"
                 style="cursor: pointer;"
                 appThrottleClick (throttleClick)="onChangeDate('MONTH')">{{ 'CurrentMonth' | translate }}</div>
            <div [class]="{'select-btn': underwritingSearchParams.dateFilter === 'YEAR'}"
                 style="cursor: pointer;"
                 appThrottleClick (throttleClick)="onChangeDate('YEAR')">{{ 'CurrentYear' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="card-row" style="padding: 0;">
        <div *ngIf="!underwritings">
          <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
        </div>
        <div *ngIf="underwritings">
          <nz-table [nzBordered]="false"
                    [nzOuterBordered]="false"
                    [nzShowPagination]="true"
                    [nzLoadingIndicator]="indicatorTemplate"
                    [nzFrontPagination]="false"
                    [nzData]="underwritings.list"
                    [nzTitle]="tableHeader"
                    [nzScroll]="{ x: '1800px' }">
            <ng-template #tableHeader>
              <nz-row>
                <nz-col [nzSpan]="12">
                  <div class="list-header-title">{{'UnderwritingFollowUp' | translate}}
                  </div>
                </nz-col>
                <nz-col [nzSpan]="12">
                  <div style="width: 100%; display: flex; justify-content: flex-end;">
                    <button nz-button nzType="default" nzSize="large" nzShape="circle"
                            class="icon-center"
                            nz-tooltip [nzTooltipTitle]="'Reload' | translate"
                            appThrottleClick (throttleClick)="getUnderwritingList()">
                      <img alt="" width="24" src="assets/images/fna_reload.svg">
                    </button>

                    <button nz-button nzType="default" nzSize="large" nzShape="circle"
                            nz-tooltip [nzTooltipTitle]="'Search' | translate"
                            class="icon-center" style="margin-left: 16px;"
                            nz-popover [nzPopoverContent]="underwritingTemplate"
                            [(nzPopoverVisible)]="underwritingVisible"
                            nzPopoverTrigger="click"
                            nzPopoverPlacement="bottomRight">
                      <img alt="" width="24" src="assets/images/ic_filter.svg">
                    </button>
                  </div>
                </nz-col>
              </nz-row>
            </ng-template>
            <thead>
              <tr>
                <th nzLeft nzWidth="165px">
                  <span nz-tooltip
                        [nzTooltipTitle]="'UnderwritingNumber' | translate">{{'UnderwritingNumber' | translate}}</span>
                </th>
                <th nzWidth="165px">
                  <span nz-tooltip [nzTooltipTitle]="'answer39' | translate">{{'answer39' | translate}}</span>
                </th>
                <th nzWidth="160px">
                  <span nz-tooltip [nzTooltipTitle]="'Referee' | translate">{{'Referee' | translate}}</span>
                </th>
                <th *ngIf="isShowBusiness" nzWidth="160px">
                  <span nz-tooltip [nzTooltipTitle]="'BusinessNumber' | translate">{{'BusinessNumber' | translate}}</span>
                </th>
                <th nzWidth="220px">
                  <span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span>
                </th>
                <th nzWidth="220px">
                  <span nz-tooltip [nzTooltipTitle]="'Holder' | translate">{{'Holder' | translate}}</span>
                </th>
                <th nzWidth="220px">
                  <span>{{'Insured' | translate}}</span>
                </th>
                <th nzWidth="160px">
                  <span nz-tooltip [nzTooltipTitle]="'PaymentTerm' | translate">{{'PaymentTerm' | translate}}</span>
                </th>
                <th nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
                </th>
                <th nzWidth="220px">
                  <span nz-tooltip [nzTooltipTitle]="'SignDate' | translate">{{'SignDate' | translate}}</span>
                </th>
                <th nzRight nzWidth="100px">
                  <span>{{'Functions' | translate}}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let underwriting of underwritings.list; let i = index">
                <td nzLeft>
                  <span style="font-weight: bold; font-size: 12px">
                    #{{ underwriting.underwritingNo }}
                  </span>
                </td>
                <td>
                  <span style="font-weight: bold; font-size: 12px">
                    {{ underwriting.policyNo }}
                  </span>
                </td>
                <td>
                  <div>
                    <span>{{ dynamicEncryption('salesName', underwriting.salesName) }}</span>
                    <span>{{ dynamicEncryption('salesName', underwriting.accountName) }}</span>
                  </div>
                  <div *ngIf="workbench.secondAccountName">
                    <span>{{ dynamicEncryption('salesName', underwriting.secondSalesName) }}</span>
                    <span>{{ dynamicEncryption('salesName', underwriting.secondAccountName) }}</span>
                  </div>
                </td>
                <td *ngIf="isShowBusiness"><span>{{ underwriting.newBusinessNumber || 'N/A' }}</span></td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="underwriting.companyLogo" alt=""/>
                    <span>{{ underwriting.productName || 'N/A' }}<span
                      *ngIf="underwriting.productYear">&nbsp; ({{ underwriting.custom ? (underwriting.productYear | yearConversion) : (underwriting.productYear | yearsOrMonthOrAge)}})</span></span>
                  </div>
                </td>
                <td>
                  <span>{{(dynamicEncryption('name', underwriting.name))}}</span>
                  <br>
                  <span>{{(dynamicEncryption('name', underwriting.pinyin))}}</span>
                </td>
                <td>{{ dynamicEncryption('insuredName', underwriting.insuredName) }}<br>{{dynamicEncryption('insuredName', underwriting.insuredPinyin)}}</td>
                <td>{{ underwriting.productYear }}</td>
                <td>
                  <div [ngSwitch]="underwriting.status">
                    <div>
                      <span *ngSwitchCase="'CHECKING'" class="text-warning">●</span>
                      <span *ngSwitchCase="'UNDERWRITING'" class="text-warning">●</span>
                      <span *ngSwitchCase="'UNDERWRITE'" class="text-warning">●</span>
                      <span *ngSwitchCase="'SENDING'" class="text-warning">●</span>
                      <span *ngSwitchCase="'PENDING_PREMIUM'" class="text-warning">●</span>
                      <span *ngSwitchCase="'FINISHED'" class="text-success">●</span>
                      <span *ngSwitchCase="'FINISHED_PENDING_PREMIUM'" class="text-warning">●</span>
                      <span *ngSwitchCase="'EFFECTIVE'" class="text-success">●</span>
                      <span *ngSwitchCase="'DECLINED'" class="text-cancel">●</span>
                      <span *ngSwitchCase="'DELETED'" class="text-warning">●</span>
                      <span> {{ underwriting.status | metadataTranslate: 'underwritingStatus' }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  {{ (underwriting.signDate | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                </td>
                <td nzRight>
                  <div class="functions">
                    <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate"
                       style="padding-right: 0"
                       appThrottleClick
                       (throttleClick)="onUnderwritingDetail(underwriting.underwritingNo); $event.stopPropagation();">
                      <img alt="" width="20" src="../../../assets/images/ic_details.svg">
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </nz-table>

          <div style="margin: 0 0 15px;text-align: right;padding-right: 25px;">
            <nz-pagination [nzPageIndex]="underwritingSearchParams.pageNum"
                           [nzPageSize]="underwritingSearchParams.pageSize"
                           [nzTotal]="underwritings.total"
                           [nzShowTotal]="totalTemplate"
                           (nzPageIndexChange)="onSearch($event)">
            </nz-pagination>
            <ng-template #totalTemplate let-total>{{'HedgeProposalTotal' | translate}}: {{ total }} </ng-template>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="detailType === 'POLICY'">
      <div class="row" style="margin-bottom: 16px;">
        <div>
          <button nz-button nzType="default"
                  appThrottleClick (throttleClick)="onBack()">
            <img alt="" width="20" src="assets/images/ic_left.svg">
            {{'ReturnBtn' | translate}}
          </button>
        </div>

        <div style="display: flex;align-items: center;">
          <nz-select [(ngModel)]="policySearchParams.pivot" style="margin-right: 16px;width: 155px;" [nzAllowClear]="true"
                     [nzPlaceHolder]="('PleaseSelect' | translate)"
                     (ngModelChange)="pivotPolicyChange($event)">
            <nz-option *ngFor="let option of pivotOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>

          <div class="switch-btn">
            <div [class]="{'select-btn': policySearchParams.dateFilter === 'MONTH'}"
                 style="cursor: pointer;"
                 appThrottleClick (throttleClick)="onChangeDate('MONTH')">{{ 'CurrentMonth' | translate }}</div>
            <div [class]="{'select-btn': policySearchParams.dateFilter === 'YEAR'}"
                 style="cursor: pointer;"
                 appThrottleClick (throttleClick)="onChangeDate('YEAR')">{{ 'CurrentYear' | translate }}</div>
          </div>
        </div>
      </div>

      <div *ngIf="!policys">
        <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
      </div>
      <div *ngIf="policys" style="margin-top: 30px;">
        <nz-table [nzBordered]="false"
                  [nzOuterBordered]="false"
                  [nzShowPagination]="true"
                  [nzLoadingIndicator]="indicatorTemplate"
                  [nzFrontPagination]="false"
                  [nzData]="policys.list"
                  [nzTitle]="tableHeader"
                  [nzScroll]="{ x: '1415'}">
          <ng-template #tableHeader>
            <nz-row>
              <nz-col [nzSpan]="12">
                <div class="list-header-title">{{'Policy' | translate}}
                </div>
              </nz-col>

              <nz-col [nzSpan]="12">
                <div style="width: 100%; display: flex; justify-content: flex-end;">
                  <button nz-button nzType="default" nzSize="large" nzShape="circle"
                          class="icon-center"
                          nz-tooltip [nzTooltipTitle]="'Reload' | translate"
                          appThrottleClick (throttleClick)="getPolicyList()">
                    <img alt="" width="24" src="assets/images/fna_reload.svg">
                  </button>

                  <button nz-button nzType="default" nzSize="large" nzShape="circle"
                          nz-tooltip [nzTooltipTitle]="'Search' | translate"
                          class="icon-center" style="margin-left: 16px;"
                          nz-popover [nzPopoverContent]="policyTemplate"
                          [(nzPopoverVisible)]="policyVisible"
                          nzPopoverTrigger="click"
                          nzPopoverPlacement="bottomRight">
                    <img alt="" width="24" src="assets/images/ic_filter.svg">
                  </button>

                </div>
              </nz-col>
            </nz-row>
          </ng-template>
          <thead>
          <tr>
            <th nzLeft nzWidth="165px">
            <span nz-tooltip
                  [nzTooltipTitle]="'PolicyNo' | translate">{{'PolicyNo' | translate}}</span>
            </th>
            <th *ngIf="isShowBusiness" nzWidth="160px">
              <span nz-tooltip [nzTooltipTitle]="'BusinessNumber' | translate">{{'BusinessNumber' | translate}}</span>
            </th>
            <th nzWidth="160px">
              <span nz-tooltip [nzTooltipTitle]="'Referee' | translate">{{'Referee' | translate}}</span>
            </th>
            <th nzWidth="220px">
              <span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span>
            </th>

            <th nzWidth="220px">
              <span nz-tooltip [nzTooltipTitle]="'Insured' | translate">{{'Insured' | translate}}</span>
            </th>
            <th *ngIf="isShowBusiness" nzWidth="160px">
              <span nz-tooltip [nzTooltipTitle]="'Dda' | translate">{{'Dda' | translate}}</span>
            </th>
            <th nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'IsTransferIn' | translate">{{'IsTransferIn' | translate}}</span>
            </th>
            <th nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
            </th>
            <th nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'Premium' | translate">{{'Premium' | translate}}</span>
            </th>
            <th nzWidth="220px">
              <span nz-tooltip [nzTooltipTitle]="'PolicyDate' | translate">{{'PolicyDate' | translate}}</span>
            </th>
            <th nzRight nzWidth="100px">
              <span>{{'Functions' | translate}}</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let policy of policys.list; let i = index">
            <td nzLeft>
            <span style="font-weight: bold; font-size: 12px">
              {{ policy.policyNo }}
            </span>
            </td>
            <td *ngIf="isShowBusiness"><span>{{ policy.newBusinessNumber || 'N/A' }}</span></td>
            <td>
              <div>
                <span>{{ dynamicEncryption('salesName', policy.salesName) }}</span>
                <span>{{ dynamicEncryption('accountName', policy.accountName) }}</span>
              </div>
              <div *ngIf="workbench.secondAccountName">
                <span>{{ dynamicEncryption('salesName', policy.secondSalesName) }}</span>
                <span>{{ dynamicEncryption('accountName', policy.secondAccountName) }}</span>
              </div>
            </td>
            <td>
              <div style="display: flex; align-items: center;">
                <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="policy.companyLogo" alt=""/>
                <span>{{ policy.productName || 'N/A' }} <span
                  *ngIf="policy.productYear">&nbsp;({{ policy.custom ? (policy.productYear | yearConversion) :(policy.productYear | yearsOrMonthOrAge)}})</span></span>
              </div>
            </td>
            <td>
              <div>{{ (dynamicEncryption('insuredName', policy.insuredName)) }}</div>
              <div>{{ (dynamicEncryption('insuredName', policy.insuredPinyin)) }}</div>
            </td>
            <td *ngIf="isShowBusiness">
              <p> {{ policy.autoTransfer == null ? 'N/A' :  policy.autoTransfer ? ('Yes' | translate) : ('No' | translate) }}</p>
            </td>
            <td>
              <p> {{ policy.isTransferIn == null ? 'N/A' :  policy.isTransferIn ? ('Yes' | translate) : ('No' | translate) }}</p>
            </td>
            <td>
              <div [ngSwitch]="policy.status">
                <div>
                  <span *ngSwitchCase="'SURRENDERED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'DECEASED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'DECLINED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'CLOSED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'TERMINATED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'EXPIRATION'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'TRANSFER_IN_FAIL'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'CLAIMS_COMPLETED_LAPSED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'CLAIMS_COMPLETED_EFFECTIVE'" class="text-success">●</span>
                  <span *ngSwitchCase="'INFORCE'" class="text-success">●</span>
                  <span *ngSwitchCase="'RIDER_INFORCE'" class="text-success">●</span>
                  <span *ngSwitchCase="'WAIVER_INFORCE'" class="text-success">●</span>
                  <span *ngSwitchCase="'UNDERWRITING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'LAPSED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'MATURED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'TRANSFERRED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'POSTPONED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'COOLOFF_SURRENDERING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'COOLOFF_SURRENDERED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'SURRENDERING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'TRANSFER_IN'" class="text-warning">●</span>
                  <span *ngSwitchCase="'REDUCED_PAID_UP'" class="text-warning">●</span>
                  <span *ngSwitchCase="'MAKING_CLAIMS'" class="text-warning">●</span>
                  <span *ngSwitchCase="'POLICY_LOANS'" class="text-warning">●</span>
                  <span *ngSwitchCase="'PREMIUM_HOLIDAY'" class="text-warning">●</span>
                  <span *ngSwitchCase="'DORMANT'" class="text-warning">●</span>
                  <span *ngSwitchCase="'NOT_AFFECTED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'PENDING_PREMIUM'" class="text-warning">●</span>
                  <span *ngSwitchCase="'RENEWAL_PREMIUM'" class="text-warning">●</span>
                  <span *ngSwitchCase="'TRANSFERRING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'RIDER_LAPSED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'OTHER'" class="text-warning">●</span>
                  <!--                <span *ngSwitchDefault class="text-warning">●</span>-->
                  <span> {{ policy.status | metadataTranslate: 'policyStatus' }}</span>
                </div>
              </div>
            </td>
            <td>
              <div>{{ policy.premium | number }}{{policy.currency | metadataTranslate: 'currency'}}</div>
            </td>
            <td>
              {{ (policy.policyDate | date:'yyyy-MM-dd') || 'N/A' }}
            </td>
            <td nzRight>
              <div class="functions">
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate"
                   style="padding-right: 0"
                   appThrottleClick (throttleClick)="onPolicyDetail(policy.policyId)"
                >
                  <img alt="" width="20" src="../../../assets/images/ic_details.svg">
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </nz-table>

        <div style="margin: 0 0 15px;text-align: right;padding-right: 25px;">
          <nz-pagination [nzPageIndex]="policySearchParams.pageNum"
                         [nzPageSize]="policySearchParams.pageSize"
                         [nzTotal]="policys.total"
                         [nzShowTotal]="totalTemplate"
                         (nzPageIndexChange)="onPolicySearch($event)">
          </nz-pagination>
          <ng-template #totalTemplate let-total>{{'HedgeProposalTotal' | translate}}: {{ total }} </ng-template>
        </div>
      </div>

    </div>

  </nz-spin>

  <!-- 气泡Template -->
  <ng-template #filterTemplate>
    <div style="width: 400px; padding: 5px;">
      <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
      <div class="property-bottom-line-blue"></div>
      <!-- 公司 -->
      <app-property-select [title]="'Insurer' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                           [(value)]="searchParams.companyCode"
                           [options]="companyOptions"></app-property-select>

      <app-property-select *ngIf="searchParams.module == 'UNDERWRITING'" [title]="'DateField' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('DateField' | translate)"
                           [(value)]="searchParams.dateField" [allowClear]="false"
                           [options]="underwritingOptions"></app-property-select>

      <app-property-select *ngIf="searchParams.module == 'POLICY'" [title]="'DateField' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('DateField' | translate)"
                           [(value)]="searchParams.dateField" [allowClear]="false"
                           [options]="policyOptions"></app-property-select>

      <div style="display: flex;justify-content: space-between;align-items: center;padding: 8px 0;border-bottom: 1px solid #E2EBF6;">
        <div style="color: #999999;">{{''}}</div>
        <div>
          <nz-range-picker class="date-picker-row" nzMode="month"
                           [nzFormat]="'YYYY-MM'"
                           [nzDisabledDate]="disabledDate"
                           [nzPlaceHolder]="[('StartTime' | translate), ('EndTime' | translate)]"
                           nzBorderless [(ngModel)]="dates" (ngModelChange)="onDateChange($event)"></nz-range-picker>
        </div>
      </div>

      <nz-row [nzGutter]="5">
        <nz-col nzSpan="12">
          <button nz-button nzType="default" style="width: 100%; margin: 24px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
            <span>{{'Reset' | translate}}</span>
          </button>
        </nz-col>
        <nz-col nzSpan="12">
          <button nz-button nzType="primary" style="width: 100%; margin: 24px 0 0 0;" appThrottleClick (throttleClick)="getData()">
            <span>{{'Confirm' | translate}}</span>
          </button>
        </nz-col>
      </nz-row>
    </div>
  </ng-template>

  <ng-template #underwritingTemplate>
    <div style="width: 400px; padding: 5px;">
      <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
      <div class="property-bottom-line-blue"></div>
      <app-property-input [title]="'UnderwritingNumber' | translate"
                          [placeholder]="('PleaseSelect' | translate) + ('UnderwritingNumber' | translate)"
                          [(value)]="underwritingSearchParams.underwritingNo"></app-property-input>

      <app-property-input [title]="'answer39' | translate"
                          [placeholder]="('PleaseSelect' | translate) + ('answer39' | translate)"
                          [(value)]="underwritingSearchParams.policyNo"></app-property-input>

      <app-property-input [title]="'ClientName' | translate"
                          [placeholder]="'PleaseEnterClientName' | translate"
                          [(value)]="underwritingSearchParams.name"></app-property-input>
      <!-- 客户名称 -->
      <app-property-input [title]="'CustomCode' | translate"
                          [placeholder]="'PleaseEnter' | translate"
                          [(value)]="underwritingSearchParams.customCode"></app-property-input>
      <!-- 公司 -->
      <app-property-select [title]="'Insurer' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                           [(value)]="underwritingSearchParams.companyCode"
                           [options]="companyOptions"></app-property-select>

      <app-property-select [title]="'DateField' | translate" [allowClear]="false"
                           [placeholder]="('PleaseSelect' | translate) + ('DateField' | translate)"
                           [(value)]="underwritingSearchParams.dateField"
                           [options]="underwritingOptions"></app-property-select>

      <div style="display: flex;justify-content: space-between;align-items: center;padding: 8px 0;border-bottom: 1px solid #E2EBF6;">
        <div style="color: #999999;">{{''}}</div>
        <div>
          <nz-range-picker class="date-picker-row" nzMode="month"
                           [nzFormat]="'YYYY-MM'"
                           [nzDisabledDate]="disabledDate"
                           [nzPlaceHolder]="[('StartTime' | translate), ('EndTime' | translate)]"
                           nzBorderless [(ngModel)]="underwritingDates" (ngModelChange)="onUnDateChange($event)"></nz-range-picker>
        </div>
      </div>

      <nz-row [nzGutter]="5">
        <nz-col nzSpan="12">
          <button nz-button nzType="default" style="width: 100%; margin: 24px 0 0 0;" appThrottleClick (throttleClick)="onResetUnderwritingSearch()">
            <span>{{'Reset' | translate}}</span>
          </button>
        </nz-col>
        <nz-col nzSpan="12">
          <button nz-button nzType="primary" style="width: 100%; margin: 24px 0 0 0;" appThrottleClick (throttleClick)="getUnderwritingList(true)">
            <span>{{'Confirm' | translate}}</span>
          </button>
        </nz-col>
      </nz-row>
    </div>
  </ng-template>

  <ng-template #policyTemplate>
    <div style="width: 400px; padding: 5px;">
      <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
      <div class="property-bottom-line-blue"></div>
      <app-property-input [title]="'answer39' | translate"
                          [placeholder]="('PleaseSelect' | translate) + ('answer39' | translate)"
                          [(value)]="policySearchParams.policyNo"></app-property-input>

      <app-property-input [title]="'ClientName' | translate"
                          [placeholder]="'PleaseEnterClientName' | translate"
                          [(value)]="policySearchParams.name"></app-property-input>
      <!-- 客户名称 -->
      <app-property-input [title]="'CustomCode' | translate"
                          [placeholder]="'PleaseEnter' | translate"
                          [(value)]="policySearchParams.customCode"></app-property-input>
      <!-- 公司 -->
      <app-property-select [title]="'Insurer' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                           [(value)]="policySearchParams.companyCode"
                           [options]="companyOptions"></app-property-select>

      <app-property-select [title]="'DateField' | translate" [allowClear]="false"
                           [placeholder]="('PleaseSelect' | translate) + ('DateField' | translate)"
                           [(value)]="policySearchParams.dateField"
                           [options]="policyOptions"></app-property-select>

      <div style="display: flex;justify-content: space-between;align-items: center;padding: 8px 0;border-bottom: 1px solid #E2EBF6;">
        <div style="color: #999999;">{{''}}</div>
        <div>
          <nz-range-picker class="date-picker-row" nzMode="month"
                           [nzFormat]="'YYYY-MM'"
                           [nzDisabledDate]="disabledDate"
                           [nzPlaceHolder]="[('StartTime' | translate), ('EndTime' | translate)]"
                           nzBorderless [(ngModel)]="policyDates" (ngModelChange)="onPoDateChange($event)"></nz-range-picker>
        </div>
      </div>

      <nz-row [nzGutter]="5">
        <nz-col nzSpan="12">
          <button nz-button nzType="default" style="width: 100%; margin: 24px 0 0 0;" appThrottleClick (throttleClick)="onResetPolicySearch()">
            <span>{{'Reset' | translate}}</span>
          </button>
        </nz-col>
        <nz-col nzSpan="12">
          <button nz-button nzType="primary" style="width: 100%; margin: 24px 0 0 0;" appThrottleClick (throttleClick)="getPolicyList(true)">
            <span>{{'Confirm' | translate}}</span>
          </button>
        </nz-col>
      </nz-row>
    </div>
  </ng-template>
</div>
