import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';
import { InstanceCodeComponent } from './auth/instance-code/instance-code.component';
import { GuideGalleryComponent } from './auth/guide-gallery/guide-gallery.component';
import { LoginByAccountComponent } from './auth/login-by-account/login-by-account.component';
import { LoginByCodeComponent } from './auth/login-by-code/login-by-code.component';
import { SalesComponent } from './sales/sales.component';
import { WorkbenchComponent } from './workbench/workbench.component';
import { ProposalComponent } from './proposal/proposal.component';
import { ProposalMainComponent } from './proposal/proposal-main.component';
import { BookingMainComponent } from './booking/booking-main.component';
import { BookingComponent } from './booking/booking.component';
import { MessageMainComponent } from './message/message-main.component';
import { MessageComponent } from './message/message.component';
import { ProposalDetailComponent } from './proposal/proposal-detail/proposal-detail.component';
import { ProductComponent } from './product/product.component';
import { ProductMainComponent } from './product/product-main.component';
import { AmlMainComponent } from './aml/aml-main.component';
import { AmlComponent } from './aml/aml.component';
import { InformationMainComponent } from './information/information-main.component';
import { InformationComponent } from './information/information.component';
import { PolicyMainComponent } from './policy/policy-main.component';
import { PolicyComponent } from './policy/policy.component';
import { FnaMainComponent } from './fna/fna-main.component';
import { FnaComponent } from './fna/fna.component';
import { TicketComponent } from './ticket/ticket.component';
import { TicketMainComponent } from './ticket/ticket-main.component';
import { UnderwritingComponent } from './underwriting/underwriting.component';
import { UnderwritingMainComponent } from './underwriting/underwriting-main.component';
import { DocumentMainComponent } from './document/document-main.component';
import { DocumentComponent } from './document/document.component';
import { PolicyReminderComponent } from './policy-reminder/policy-reminder.component';
import { PolicyReminderMainComponent } from './policy-reminder/policy-reminder-main.component';
import { KnowledgeMainComponent } from './knowledge/knowledge-main.component';
import { KnowledgeComponent } from './knowledge/knowledge.component';
import { CommissionMainComponent } from './commission/commission-main.component';
import { CommissionComponent } from './commission/commission.component';
import { ProductComparisonComponent } from './product/product-comparison/product-comparison.component';
import { ContactMainComponent } from './contact/contact-main.component';
import { ContactComponent } from './contact/contact.component';
import { ShareMainComponent } from './share/share-main/share-main.component';
import { ShareComponent } from './share/share.component';
import { ContractMainComponent } from './contract/contract-main/contract-main.component';
import { ContractComponent } from './contract/contract.component';
import { QuotationMainComponent } from './quotation/quotation-main.component';
import { QuotationComponent } from './quotation/quotation.component';
import { PreSalesMainComponent } from './pre-sales/pre-sales-main.component';
import { PreSalesComponent } from './pre-sales/pre-sales.component';
import { ScheduleMainComponent } from './schedule/schedule-main.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ProposalCompareComponent } from './proposal-compare/proposal-compare.component';
import { LeaveWordMainComponent } from './leave-word/leave-word-main.component';
import { LeaveWordComponent } from './leave-word/leave-word.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ProposalCompareListComponent } from './proposal-compare/proposal-compare-list/proposal-compare-list.component';
import { SalesPactMainComponent } from './sales-pact/sales-pact-main.component';
import { SalesPactComponent } from './sales-pact/sales-pact.component';
import { OpenAuthComponent } from './auth/open-auth/open-auth.component';
import { ToDoMainComponent } from './to-do/to-do-main/to-do-main.component';
import { ToDoComponent } from './to-do/to-do.component';
import { ProposalCustomMainComponent } from './proposal-custom/proposal-custom-main.component';
import { ProposalCustomComponent } from './proposal-custom/proposal-custom.component';
import { CommissionForecastMainComponent } from './commission-forecast/commission-forecast-main.component';
import { CommissionForecastComponent } from './commission-forecast/commission-forecast.component';
import { ProductPromotionMainComponent } from './product-promotion/product-promotion-main.component';
import { ProductPromotionComponent } from './product-promotion/product-promotion.component';
import { SaleReportMainComponent } from './sale-report/sale-report-main.component';
import { SaleReportComponent } from './sale-report/sale-report.component';
import { NewFnaComponent } from './new-fna/new-fna.component';
import { NewFnaMainComponent } from './new-fna/new-fna-main.component';
import { DashboardComponent } from './workbench/dashboard/dashboard.component';
import { PhoneNumberLoginComponent } from './auth/phone-number-login/phone-number-login.component';
import { NewFnaTemplateComponent } from './new-fna-template/new-fna-template.component';
import { FnaTemplateOverviewComponent } from './new-fna-template/fna-template-overview/fna-template-overview.component';
import { PremiumFinancingComponent } from './premium-financing/premium-financing.component';
import { PremiumFinancingMainComponent } from './premium-financing/premium-financing-main.component';
import { ZuuFnaTemplateComponent } from './zuu-fna-template/zuu-fna-template.component';
import { ZuuTemplateOverviewComponent } from './zuu-fna-template/zuu-template-overview/zuu-template-overview.component';
import { BvModuleComponent } from './bv-module/bv-module.component';
import { BvModuleMainComponent } from './bv-module/bv-module-main/bv-module-main.component';
import { NewFnaPibaComponent } from './new-fna-piba/new-fna-piba.component';
import { PibaTemplateOverviewComponent } from './new-fna-piba/piba-template-overview/piba-template-overview.component';
import { TicketOrderListComponent } from './ticket-order/ticket-order-list/ticket-order-list.component';
import { TicketOrderComponent } from './ticket-order/ticket-order.component';
import { InvitationComponent } from './invitation/invitation.component';
import { InvitationMainComponent } from './invitation/invitation-main/invitation-main.component';
import { CryptographicStrengtComponent } from './auth/cryptographic-strengt/cryptographic-strengt.component';
import { DataBoardComponent } from './workbench/data-board/data-board.component';


const messageRoutes: Routes = [
  {path: '', redirectTo: 'message-main', pathMatch: 'full'},
  {path: 'message-main', component: MessageMainComponent},
];

const productRoutes: Routes = [
  {path: '', redirectTo: 'main', pathMatch: 'full'},
  {path: 'main', component: ProductMainComponent},
  {path: 'comparison', component: ProductComparisonComponent},
];

const proposalRoutes: Routes = [
  {path: '', redirectTo: 'proposal-main', pathMatch: 'full'},
  {path: 'proposal-main', component: ProposalMainComponent},
  {path: 'proposal-detail', component: ProposalDetailComponent},
];

const bookingRoutes: Routes = [
  {path: '', redirectTo: 'booking-main', pathMatch: 'full'},
  {path: 'booking-main', component: BookingMainComponent},
];

const amlRoutes: Routes = [
  {path: '', redirectTo: 'aml-main', pathMatch: 'full'},
  {path: 'aml-main', component: AmlMainComponent},
];

const informationRoutes: Routes = [
  {path: '', redirectTo: 'information-main', pathMatch: 'full'},
  {path: 'information-main', component: InformationMainComponent},
];

const invitationRoutes: Routes = [
  {path: '', redirectTo: 'invitation-main', pathMatch: 'full'},
  {path: 'invitation-main', component: InvitationMainComponent},
];

const productPromotionRoutes: Routes = [
  {path: '', redirectTo: 'product-promotion-main', pathMatch: 'full'},
  {path: 'product-promotion-main', component: ProductPromotionMainComponent},
];

const policyRoutes: Routes = [
  {path: '', redirectTo: 'policy-main', pathMatch: 'full'},
  {path: 'policy-main', component: PolicyMainComponent},
];

const ticketRoutes: Routes = [
  {path: '', redirectTo: 'ticket-main', pathMatch: 'full'},
  {path: 'ticket-main', component: TicketMainComponent},
];

const ticketOrderRoutes: Routes = [
  {path: '', redirectTo: 'ticket-order-list', pathMatch: 'full'},
  {path: 'ticket-order-list', component: TicketOrderListComponent},
];

const fnaRoutes: Routes = [
  {path: '', redirectTo: 'fna-main', pathMatch: 'full'},
  {path: 'fna-main', component: FnaMainComponent},
];

const underwritingRoutes: Routes = [
  {path: '', redirectTo: 'underwriting-main', pathMatch: 'full'},
  {path: 'underwriting-main', component: UnderwritingMainComponent}
];

const documentRoutes: Routes = [
  {path: '', redirectTo: 'document-main', pathMatch: 'full'},
  {path: 'document-main', component: DocumentMainComponent}
];

const knowledgeRoutes: Routes = [
  {path: '', redirectTo: 'knowledge-main', pathMatch: 'full'},
  {path: 'knowledge-main', component: KnowledgeMainComponent}
];

const policyReminderRouter: Routes = [
  {path: '', redirectTo: 'policy-reminder', pathMatch: 'full'},
  {path: 'policy-reminder', component: PolicyReminderMainComponent }
];

const commissionRouter: Routes = [
  {path: '', redirectTo: 'commission-main', pathMatch: 'full'},
  {path: 'commission-main', component: CommissionMainComponent }
];

const shareRouter: Routes = [
  {path: '', redirectTo: 'share-main', pathMatch: 'full'},
  {path: 'share-main', component: ShareMainComponent }
];
const contactRouter: Routes = [
  {path: '', redirectTo: 'contact-main', pathMatch: 'full'},
  {path: 'contact-main', component: ContactMainComponent }
];
const contractRouter: Routes = [
  {path: '', redirectTo: 'contract-main', pathMatch: 'full'},
  {path: 'contract-main', component: ContractMainComponent}
];

const quotationRouter: Routes = [
  {path: '', redirectTo: 'quotation-main', pathMatch: 'full'},
  {path: 'quotation-main', component: QuotationMainComponent }
];

const preSalesRouter: Routes = [
  {path: '', redirectTo: 'pre-sales-main', pathMatch: 'full'},
  {path: 'pre-sales-main', component: PreSalesMainComponent }
];

const scheduleRouter: Routes = [
  {path: '', redirectTo: 'schedule-main', pathMatch: 'full'},
  {path: 'schedule-main', component: ScheduleMainComponent }
];

const proposalCompareRouter: Routes = [
  {path: '', redirectTo: 'proposal-compare', pathMatch: 'full'},
  {path: 'proposal-compare', component: ProposalCompareListComponent }
];

const leaveWordRouter: Routes = [
  {path: '', redirectTo: 'leave-word-main', pathMatch: 'full'},
  {path: 'leave-word-main', component: LeaveWordMainComponent }
];

const salesPactRouter: Routes = [
  {path: '', redirectTo: 'user-pact-main', pathMatch: 'full'},
  {path: 'user-pact-main', component: SalesPactMainComponent }
];

const toDoRouter: Routes = [
  {path: '', redirectTo: 'to-do-main', pathMatch: 'full'},
  {path: 'to-do-main', component: ToDoMainComponent }
];

const proposalCustomRouter: Routes = [
  {path: '', redirectTo: 'proposal-custom-main', pathMatch: 'full'},
  {path: 'proposal-custom-main', component: ProposalCustomMainComponent }
];

const CommissionForecastRouter: Routes = [
  {path: '', redirectTo: 'commission-forecast-main', pathMatch: 'full'},
  {path: 'commission-forecast-main', component: CommissionForecastMainComponent }
];

const saleReportRoutes: Routes = [
  {path: '', redirectTo: 'sale-report-main', pathMatch: 'full'},
  {path: 'sale-report-main', component: SaleReportMainComponent }
];

const newFnaRoutes: Routes = [
  {path: '', redirectTo: 'new-fna-main', pathMatch: 'full'},
  {path: 'new-fna-main', component: NewFnaMainComponent }
];

const BVRoutes: Routes = [
  {path: '', redirectTo: 'bv-main', pathMatch: 'full'},
  {path: 'bv-main', component: BvModuleMainComponent},
];

const premiumFinancingRoutes: Routes = [
  {path: '', redirectTo: 'premium-financing-main', pathMatch: 'full'},
  {path: 'premium-financing-main', component: PremiumFinancingMainComponent }
];

const routes: Routes = [
  {path: '', redirectTo: 'user', pathMatch: 'full'},
  {path: 'auth/instance-code', component: InstanceCodeComponent},
  {path: 'auth/guide-gallery', component: GuideGalleryComponent},
  {path: 'auth/login-by-account', component: LoginByAccountComponent},
  {path: 'auth/login-by-code', component: LoginByCodeComponent},
  {path: 'auth/forgot-password', component: ForgotPasswordComponent},
  {path: 'auth/cryptographic-strength', component: CryptographicStrengtComponent},
  {path: 'auth/open-auth', component: OpenAuthComponent},
  {path: 'auth/phone-number-login', component: PhoneNumberLoginComponent},
  {
    path: 'user',
    component: SalesComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'workbench', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'data-board', component: DataBoardComponent},
      {path: 'message', component: MessageComponent, children: messageRoutes},
      {path: 'product', component: ProductComponent, children: productRoutes},
      {path: 'proposal', component: ProposalComponent, children: proposalRoutes},
      {path: 'booking', component: BookingComponent, children: bookingRoutes},
      {path: 'aml', component: AmlComponent, children: amlRoutes},
      {path: 'information', component: InformationComponent, children: informationRoutes},
      {path: 'invitation', component: InvitationComponent, children: invitationRoutes},
      {path: 'product-promotion', component: ProductPromotionComponent, children: productPromotionRoutes},
      {path: 'workbench', component: WorkbenchComponent},
      {path: 'ticket', component: TicketComponent, children: ticketRoutes},
      {path: 'ticketOrder', component: TicketOrderComponent, children: ticketOrderRoutes},
      {path: 'policy', component: PolicyComponent, children: policyRoutes},
      {path: 'fna', component: FnaComponent, children: fnaRoutes},
      {path: 'underwriting', component: UnderwritingComponent, children: underwritingRoutes},
      {path: 'document', component: DocumentComponent, children: documentRoutes},
      {path: 'policyReminder', component: PolicyReminderComponent, children: policyReminderRouter},
      {path: 'document', component: DocumentComponent, children: documentRoutes},
      {path: 'knowledge', component: KnowledgeComponent, children: knowledgeRoutes},
      {path: 'commission', component: CommissionComponent, children: commissionRouter},
      {path: 'contact', component: ContactComponent, children: contactRouter},
      {path: 'share', component: ShareComponent, children: shareRouter},
      {path: 'quotation', component: QuotationComponent, children: quotationRouter},
      {path: 'contract', component: ContractComponent, children: contractRouter},
      {path: 'pre-sales', component: PreSalesComponent, children: preSalesRouter},
      {path: 'schedule', component: ScheduleComponent, children: scheduleRouter},
      {path: 'leave-word', component: LeaveWordComponent, children: leaveWordRouter},
      {path: 'proposal-compare', component: ProposalCompareComponent, children: proposalCompareRouter},
      {path: 'user-pact', component: SalesPactComponent, children: salesPactRouter},
      {path: 'to-do', component: ToDoComponent, children: toDoRouter},
      {path: 'proposal-custom', component: ProposalCustomComponent, children: proposalCustomRouter},
      {path: 'commission-forecast', component: CommissionForecastComponent, children: CommissionForecastRouter},
      {path: 'sale-report', component: SaleReportComponent, children: saleReportRoutes},
      {path: 'new-fna', component: NewFnaComponent, children: newFnaRoutes},
      {path: 'bv', component: BvModuleComponent, children: BVRoutes},
      {path: 'new-fna-template', component: NewFnaTemplateComponent},
      {path: 'zuu-fna-template', component: ZuuFnaTemplateComponent},
      {path: 'new-fna-piba', component: NewFnaPibaComponent},
      {path: 'new-fna-view', component: FnaTemplateOverviewComponent},
      {path: 'zuu-fna-view', component: ZuuTemplateOverviewComponent},
      {path: 'piba-fna-view', component: PibaTemplateOverviewComponent},
      {path: 'premium-financing', component: PremiumFinancingComponent, children: premiumFinancingRoutes},
    ]
  },
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, useHash: true})],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {
}
