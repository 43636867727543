<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!toDoList">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="toDoList" style="margin-top: 30px;">
      <nz-table #rowSelectionTable
                [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzShowSizeChanger]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzData]="toDoList"
                [nzTotal]="toDoList.length"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'MessageToDoList' | translate}}</div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div class="col-radio-solid" style="width: 100%; display: flex; justify-content: flex-end;">
                <nz-radio-group [(ngModel)]="mode" nzButtonStyle="solid"
                                [nzSize]="'small'"
                                (ngModelChange)="onReload()">
                  <label nz-radio-button nzValue="OWN">{{'My' | translate}}</label>
                  <label nz-radio-button nzValue="SUBORDINATE">{{'MySubordinates' | translate}}</label>
                </nz-radio-group>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'Refresh' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>

        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ toDoList.length }}</span>
          </div>
        </ng-template>
        <thead>
        <tr>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'Module' | translate">{{'Module' | translate}}</span>
          </th>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'ToDoNo' | translate">{{'ToDoNo' | translate}}</span>
          </th>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'ClientName' | translate">{{'ClientName' | translate}}</span>
          </th>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'Date' | translate">{{'Date' | translate}}</span>
          </th>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'AgentQuantity' | translate">{{'AgentQuantity' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'State' | translate">{{'State' | translate}}</span>
          </th>
          <th nzWidth="100px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let list of rowSelectionTable.data; let i = index" appThrottleClick (throttleClick)="onDetail(list); $event.stopPropagation();">
          <td><span>{{ getModule(list) }}</span></td>
          <td>
            <span [ngSwitch]="list.module">
              <span *ngSwitchCase="'BOOKING'">{{list.bookingNo}}</span>
              <span *ngSwitchCase="'UNDERWRITING'">{{list.underwritingNo}}</span>
              <span *ngSwitchCase="'POLICY'">{{list.policyNo}}</span>
            </span>
          </td>
          <td><span>{{ list.clientName }}</span></td>
          <td><span>{{ list.time | date:'yyyy-MM-dd HH:mm:ss'}}</span></td>
          <td><span class="text-danger">{{ list.dialogCount }}</span></td>
          <td><span class="text-danger">●</span> {{ 'UnconfirmedToDoList' | translate }}</td>
          <td>
            <div class="functions">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                 appThrottleClick (throttleClick)="onDetail(list); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_details.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>
