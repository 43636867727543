import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  BusinessSourceListRes,
  ContactInfoRes,
  ContactListRes,
  ContactsType,
  CreateContactsType,
  MemberList
} from '../contact-types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import {
  Customer,
  DataMode,
  Instance,
  Material,
  Phone,
  SmokeCondition,
  UploadTokenReq,
  UploadType
} from '../../api/types';
import * as CryptoJS from 'crypto-js';
import { Phones } from '../../shared/utils/phones';
import { PhoneEditComponent } from '../../shared/component/phone-edit/phone-edit.component';
import { Addresses } from '../../shared/utils/addresses';
import { AddressEditComponent } from '../../shared/component/address-edit/address-edit.component';
import { Observable } from 'rxjs/internal/Observable';
import { ToastrService } from 'ngx-toastr';
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from 'ng2-file-upload';
import { Subscriber } from 'rxjs/internal-compatibility';
import { StorageService } from '../../shared/service/storage.service';
import { ContactService } from '../contact.service';
import { DatePipe } from '@angular/common';
import { AttachmentFileAlert } from '../../shared/component/attachments/attachments.component';
import { FileLikeObject } from 'ng2-file-upload/file-upload/file-like-object.class';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AccountService } from '../../account/account.service';
import { plainToClass, plainToInstance } from 'class-transformer';
import { ContactFamilyComponent } from '../contact-family/contact-family.component';
import { ProposalApplyComponent } from '../../proposal/proposal-apply/proposal-apply.component';
import { ContactsMeetingRecordComponent } from '../components/contacts-meeting-record/contacts-meeting-record.component';
import { BookingCreateComponent } from '../../booking/booking-create/booking-create.component';
import { BookingDetailComponent } from '../../booking/booking-detail/booking-detail.component';
import { Booking } from '../../booking/booking-types';
import { BookingService } from '../../booking/booking.service';
import { Proposal } from '../../proposal/proposal-types';
import { Times } from '../../shared/utils/times';
import { PhonesEditComponent } from '../../shared/component/phones-edit/phones-edit.component';
import { RegionSelectionComponent } from '../../shared/component/region-selection/region-selection.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ImportBookingInfoComponent } from '../../shared/component/import-booking-info/import-booking-info.component';
import { ImportPolicyInfoComponent } from '../../shared/component/import-policy-info/import-policy-info.component';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';
import {
  ImportUnderwritingInfoComponent
} from '../../shared/component/import-underwriting-info/import-underwriting-info.component';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.less'],
  providers: [DatePipe]
})
export class ContactInfoComponent implements OnInit {
  @Input()
  mode = DataMode.OWN;
  sourceType: string;
  loading = false;
  status: ContactsType;
  uploadTokenReq: UploadTokenReq = new UploadTokenReq();
  materialMap: Map<string, Material> = new Map();
  @Output()
  ContactInfoSummit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  ContactInfoSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  contactInfo: ContactInfoRes = new ContactInfoRes();
  contactsType: CreateContactsType; // 联系人类型

  infoID: number;
  isVisible = false; // 家庭成员弹窗
  isBookingVisible = false; // 发起预约弹窗
  radioValue = 'Holder';
  importVisible = false;
  importInfoValue = 'BOOKING';
  holder = 'Holder';
  insured = 'Insured';
  uploader: FileUploader;
  @ViewChild('fileUpload')
  fileUpload: ElementRef;
  fileAlert = new AttachmentFileAlert();

  memberQuery: MemberList = new MemberList();
  instance: Instance;
  isInputName: boolean;
  workbench: Workbench;

  // 快捷地址和电话其他数据源
  otherContact: ContactInfoRes;
  businessSourceOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];
  regionOptions: PropertySelectOption[] = [];
  smokeOptions: PropertySelectOption[] = [];
  titleOptions: PropertySelectOption[] = [];
  marriageOptions: PropertySelectOption[] = [];
  educationOptions: PropertySelectOption[] = [];
  longtimeAbroadOptions: PropertySelectOption[] = [];
  riskAppetiteOptions: PropertySelectOption[] = [];
  sportsOptions: PropertySelectOption[] = [];
  gamesOptions: PropertySelectOption[] = [];
  movieOptions: PropertySelectOption[] = [];
  cuisineOptions: PropertySelectOption[] = [];

  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private toastr: ToastrService,
              private storageService: StorageService,
              private datePipe: DatePipe,
              private contactService: ContactService,
              private accountService: AccountService,
              private bookingService: BookingService,
              private notificationService: NzNotificationService,
              private modalService: NzModalService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.metadataService.mockInstance;
    this.isDefaultFill();
    if (this.instance.isFone()) {
      const businessSource = this.metadataService.values('businessSource');
      for (const source of businessSource) {
        this.businessSourceOptions.push(new PropertySelectOption(source.value, source.key));
      }
    } else {
      this.getBusinessSourceList();
    }
    this.uploadTokenReq.uploadType = UploadType.CONTACT;
    this.uploadTokenReq.middleName = 'content/' + this.accountService.passport.accountName;
    this.uploader = new FileUploader({
      // headers: headers,
      // allowedMimeType: ["application/vnd.ms-excel"],
      disableMultipart: true,
      autoUpload: false,
      removeAfterUpload: false,
      maxFileSize: this.fileAlert.maxFileSize
    });

    this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
    this.uploader.onErrorItem = this.onUploadError.bind(this);
    this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed.bind(this);


    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }

    const regions = this.metadataService.values('residenceRegion');
    for (const region of regions) {
      this.regionOptions.push(new PropertySelectOption(region.value, region.key));
    }
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'S'), SmokeCondition.S));
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'NS'), SmokeCondition.NS));

    const titles = this.metadataService.values('title');
    for (const title of titles) {
      this.titleOptions.push(new PropertySelectOption(title.value, title.key));
    }
    const marriageStatus = this.metadataService.values('marriageStatus');
    for (const marriage of marriageStatus) {
      this.marriageOptions.push(new PropertySelectOption(marriage.value, marriage.key));
    }
    const educations = this.metadataService.values('education');
    for (const education of educations) {
      this.educationOptions.push(new PropertySelectOption(education.value, education.key));
    }
    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.longtimeAbroadOptions.push(new PropertySelectOption(res.Yes, true));
        this.longtimeAbroadOptions.push(new PropertySelectOption(res.No, false));
      });

    const riskAppetites = this.metadataService.values('riskAppetite');
    for (const riskAppetite of riskAppetites) {
      this.riskAppetiteOptions.push(new PropertySelectOption(riskAppetite.value, riskAppetite.key));
    }
    const sports = this.metadataService.values('sports');
    for (const sport of sports) {
      this.sportsOptions.push(new PropertySelectOption(sport.value, sport.key));
    }

    const games = this.metadataService.values('games');
    for (const game of games) {
      this.gamesOptions.push(new PropertySelectOption(game.value, game.key));
    }
    const movies = this.metadataService.values('movie');
    for (const movie of movies) {
      this.movieOptions.push(new PropertySelectOption(movie.value, movie.key));
    }

    const cuisines = this.metadataService.values('cuisine');
    for (const cuisine of cuisines) {
      this.cuisineOptions.push(new PropertySelectOption(cuisine.value, cuisine.key));
    }
  }

  isDefaultFill(): void {
    if (this.instance.isFoneOrDev() || this.instance.isWDF()) {
      this.isInputName = true;
      if (!this.contactInfo.lastName) {
        this.contactInfo.lastName = '-';
      }
      if (!this.contactInfo.lastNamePinyin) {
        this.contactInfo.lastNamePinyin = '-';
      }
    }
  }

  onMobileNumber(): void {
    if (this.isDisabled()) {
      return;
    }
    this.translate.get('MobileNumber').subscribe(
      (title: any) => {

        const quickPhones = Phones.getContactQuickPhones([this.contactInfo, this.otherContact]);

        const phone = new Phone();
        phone.areaCode = this.contactInfo.areaCode;
        phone.phone = this.contactInfo.phone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones,
            requiredPhone: true
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.contactInfo.areaCode = data?.areaCode;
              this.contactInfo.phone = data?.phone;
            }
          );
        });
      });
  }

  onOtherPhones(): void {
    if (this.isDisabled()) {
      return;
    }
    this.translate.get('OtherPhones').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<PhonesEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: PhonesEditComponent,
          nzContentParams: {
            title,
            phones: this.contactInfo.phones
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.drawerRef = drawerRef;
          component.phonesSaved.subscribe(
            phones => {
              this.contactInfo.phones = phones;
            }
          );
        });
      });
  }
  onResidenceAddress(): void {
    if (this.isDisabled()) {
      return;
    }
    this.translate.get('LegalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getContactQuickAddresses([this.contactInfo, this.otherContact]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.contactInfo.residenceAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.contactInfo.residenceAddress = data;
            }
          );
        });
      });
  }

  onPostalAddress(): void {
    if (this.isDisabled()) {
      return;
    }
    this.translate.get('PostalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getContactQuickAddresses([this.contactInfo, this.otherContact]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.contactInfo.postalAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.contactInfo.postalAddress = data;
            }
          );
        });
      });
  }

  onResidentialPhoneNumber(): void {
    if (this.isDisabled()) {
      return;
    }
    this.translate.get('ResidentialPhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getContactQuickPhones([this.contactInfo, this.otherContact]);

        const phone = new Phone();
        phone.areaCode = this.contactInfo.residenceAreaCode;
        phone.phone = this.contactInfo.residenceTelephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.contactInfo.residenceAreaCode = data?.areaCode;
              this.contactInfo.residenceTelephone = data?.phone;
            }
          );
        });
      });
  }

  onAddress(): void {
    if (this.isDisabled()) {
      return;
    }
    this.translate.get('ResidentialAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getContactQuickAddresses([this.contactInfo, this.otherContact]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.contactInfo.address,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.contactInfo.address = data;
            }
          );
        });
      });
  }

  onCompanyAddress(): void {
    if (this.isDisabled()) {
      return;
    }
    console.log(this.contactInfo.companyAddress);
    this.translate.get('CompanyAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getContactQuickAddresses([this.contactInfo, this.otherContact]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.contactInfo.companyAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.contactInfo.companyAddress = data;
            }
          );
        });
      });
  }

  onOfficePhoneNumber(): void {
    if (this.isDisabled()) {
      return;
    }
    this.translate.get('OfficePhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getContactQuickPhones([this.contactInfo, this.otherContact]);

        const phone = new Phone();
        phone.areaCode = this.contactInfo.companyAreaCode;
        phone.phone = this.contactInfo.companyTelephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.contactInfo.companyAreaCode = data?.areaCode;
              this.contactInfo.companyTelephone = data?.phone;
            }
          );
        });
      });
  }


  selectedAvatarChanged(event): void {
  }

  onUploaderImg(): void {
    if (this.isDisabled()) {
      return;
    }
    if (this.fileUpload) {
      this.fileUpload.nativeElement.click();
    }
  }

  uploadFileItem(fileItem: FileItem): void {
    const req: UploadTokenReq = {
      uploadType: this.uploadTokenReq.uploadType,
      middleName: this.uploadTokenReq.middleName,
      fileName: fileItem.file.name
    };

    this.computeMD5(fileItem)
      .subscribe(
        md5 => {
          this.storageService.uploadToken(req)
            .subscribe(
              data => {
                fileItem.method = 'PUT';
                fileItem.url = data.signedUrl;
                const headers: Headers[] = [];
                headers.push({name: 'Content-Type', value: data.contentType});
                headers.push({name: 'x-amz-meta-content-md5', value: md5});
                fileItem.headers = headers;
                fileItem.upload();
                const material = new Material();
                material.fileName = fileItem.file.name;
                material.filePath = data.filePath;
                material.uploadTime = new Date().valueOf();
                material.author = this.storageService.passport.accountName;
                this.materialMap.set(fileItem.file.name, material);
              },
              error => {
              });
        }
      );

  }

  computeMD5(item: FileItem): Observable<string> {
    return new Observable((subscriber: Subscriber<string>) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(e.target.result));
        const md5 = hash.toString(CryptoJS.enc.Hex);
        subscriber.next(md5);
        subscriber.complete();
      };
      reader.readAsBinaryString(item._file);
    });
  }


  // 向队列中添加一个单独的文件后触发
  onAfterAddingFile(fileItem: FileItem): any {
    fileItem.withCredentials = false;
    if (this.fileUpload) {
      // 重置文件选择，否则无法重复上传同一个文件
      this.fileUpload.nativeElement.value = '';
    }
    const suffix = fileItem.file.name.substr(fileItem.file.name.lastIndexOf('.') + 1)?.toLowerCase();
    switch (suffix) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        this.uploadFileItem(fileItem);
        break;
      default:
        this.translate.get(['UnsupportedFormat']).subscribe(
          (res) => {
            this.toastr.warning(res.UnsupportedFormat);
          }
        );
        break;
    }
    // this.uploadFileItem(fileItem);
  }

  // 一个文件上传成功后触发
  onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    this.uploader.removeFromQueue(item);
    const attachments = [];
    const material = this.materialMap.get(item.file.name);
    if (material) {
      attachments.push(material);
      this.contactInfo.avatar = material.filePath;
      // this.valueChange.emit(this.attachments);
      this.materialMap.delete(item.file.name);
    }
  }

  onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    this.uploader.removeFromQueue(item);
    console.log(response + ' for ' + item.file.name + ' status ' + status);
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): any {
    if (filter.name === 'fileSize') {
      this.fileAlert.showAlert = true;
      this.fileAlert.fileSize = item.size;
      this.translate.get('ExceedsTheLimitsOfFileSize',
        {maxLength: this.fileAlert.maxFileSizeStr, length: this.fileAlert.fileSizeStr})
        .subscribe(
          text => {
            this.notificationService.create(
              'warning',
              '',
              text
            );
          }
        );
    }
  }

  // 家庭成員
  onFamilyMember(val): void {
    if (val) {
      this.memberQuery.familyId = val;
      this.contactService.memberList(this.memberQuery)
        .subscribe(
          data => {
            const drawerRef = this.drawerService.create<ContactFamilyComponent, { value: any }, string>({
              nzWidth: 600,
              nzContent: ContactFamilyComponent,
              nzContentParams: {
                id: val,
                memberList: data
              }
            });
            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
              component.Close
                .subscribe(
                  info => {
                    drawerRef.close();
                    this.onRefresh();
                  }
                );
            });
          },
          error => {
          }
        );
    } else {
      this.isVisible = true;
    }

  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.isVisible = false;
    this.contactInfo.familyId = this.contactInfo.id;
    this.contactService.updateInfo(this.contactInfo)
      .subscribe(
        data => {
          if (data) {
            this.onFamilyMember(this.contactInfo.familyId);
          }
        },
        error => {
        });
  }

  onMeetingRecord(val): void {
    this.contactService.meeting(val)
      .subscribe(
        data => {
          const drawerRef = this.drawerService.create<ContactsMeetingRecordComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: ContactsMeetingRecordComponent,
            nzContentParams: {
              id: val,
              meetingRecord: data
            }
          });
        },
        error => {
          console.log(error);
        }
      );
  }

  onProposal(): void {
    const proposalInfo = new Proposal();
    proposalInfo.lastName = this.contactInfo.lastName;
    proposalInfo.firstName = this.contactInfo.firstName;
    proposalInfo.birthday = this.contactInfo.birthday;
    proposalInfo.age = this.contactInfo.age;
    proposalInfo.gender = this.contactInfo.gender;
    proposalInfo.smoke = this.contactInfo.smoke;
    proposalInfo.residenceRegion = this.contactInfo.country;
    // console.log(proposalInfo);
    const drawerRef = this.drawerService.create<ProposalApplyComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: ProposalApplyComponent,
      nzContentParams: {
        applyReq: proposalInfo
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.proposalApplied
        .subscribe(data => {
          drawerRef.close();
        });
    });
  }

  openBooking(): void {
    const instance = this.metadataService.mockInstance;
    if (instance.isFoneOrDev()) {
      this.translate.get(['FNAToBooking', 'Tips']).subscribe(
        res => {
          this.modalService.info({
            nzCentered: true,
            nzTitle: res.Tips,
            nzContent: res.FNAToBooking,
            nzClosable: true,
            nzOnOk: () => {},
          });
        }
      );
      return;
    }
    this.isBookingVisible = true;
  }

  handleClose(): void {
    this.isBookingVisible = false;
  }

  onSelect(val): void {
    // this.onDetermine();
    console.log(val);
  }

  onDetermine(): void {
    this.isBookingVisible = false;
    // console.log(this.radioValue);
    const contacts = plainToInstance(Customer, this.contactInfo);
    const drawerRef = this.drawerService.create<BookingCreateComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: BookingCreateComponent,
      nzContentParams: {},
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      // console.log(this.radioValue);
      switch (this.radioValue) {
        case 'Holder':
          component.draftReq.owner = contacts;
          break;
        case 'Insured':
          component.draftReq.insured = contacts;
          break;
      }
      component.bookingDraftSaved.subscribe(
        data => {
          drawerRef.close();
          this.onBookingDetail(data.bookingNo);
        }
      );
      component.bookingApplied.subscribe(
        data => {
          drawerRef.close();
        }
      );
    });
  }

  onBookingDetail(bookingNo: string): void {
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {

          const booking = bookingDetail.toBooking();

          const drawerRef = this.drawerService.create<BookingDetailComponent, { value: Booking }, string>({
            nzWidth: 800,
            nzContent: BookingDetailComponent,
            nzContentParams: {
              bookingDetail,
              booking
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
        });
  }

  onRefresh(): void {
    this.loading = true;
    this.contactService.detail(this.infoID)
      .subscribe(data => {
          this.contactInfo = data;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  onReset(): void {
    const info = new ContactListRes();
    this.contactInfo = plainToClass(ContactInfoRes, info);
  }

  onSave(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.isOther();
    this.contactService.updateInfo(this.contactInfo)
      .subscribe(
        data => {
          this.loading = false;
          this.ContactInfoSave.emit(data);
        },
        error => {
          this.loading = false;
        });

    // this.customerSaved.emit(customerEntity);
  }

  onSummit(): void {
    if (this.loading) {
      return;
    }
    if (this.contactsType === 'PRE_SALES') {
      // console.log('PRE_SALES');
      this.contactInfo.type = this.contactsType;
    }
    this.loading = true;
    this.isOther();
    this.contactService.create(this.contactInfo)
      .subscribe(
        data => {
          this.loading = false;
          this.ContactInfoSummit.emit(data);
        },
        error => {
          this.loading = false;
        });
  }

  containsOther(options: string[]): boolean {
    if (options) {
      return options.includes('OTHERS');
    }
  }

  isOther(): void {
    if (this.contactInfo.sports && !this.contactInfo.sports.includes('OTHERS')) {
      this.contactInfo.sportsOther = null;
    }
    if (this.contactInfo.games && !this.contactInfo.games.includes('OTHERS')) {
      this.contactInfo.gamesOther = null;
    }
    if (this.contactInfo.movie && !this.contactInfo.movie.includes('OTHERS')) {
      this.contactInfo.movieOther = null;
    }
    if (this.contactInfo.cuisine && !this.contactInfo.cuisine.includes('OTHERS')) {
      this.contactInfo.cuisineOther = null;
    }
  }

  onBirthdayChange(value: any): void {
    if (value) {
      // const birthDayTime = this.datePipe.transform(value, 'yyyyMMdd');
      // console.log('birthDayTime:' + birthDayTime);
      // const nowTime = new Date().getFullYear();
      const age = Times.getAge(value);
      // console.log(nowTime, birthDayTime);
      this.contactInfo.age = Times.getDateStrAge(value);
    }
  }

  get countryString(): string {
    const country = this.metadataService.translate('countries', this.contactInfo.country);
    return country;
  }

  onCountry(): void {
    if (this.isDisabled()) {
      return;
    }
    const drawerRef = this.drawerService.create<RegionSelectionComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: RegionSelectionComponent,
      nzContentParams: {
      },
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.regionSelected.subscribe(
        data => {
          this.contactInfo.country = data.countryCode;
          drawerRef.close();
        }
      );
    });
  }

  openImportDrawer(): void {
    this.importVisible = false;
    console.log(this.importInfoValue);
    switch (this.importInfoValue) {
      case 'BOOKING':
        if (this.isShow('BOOKING')) {
          const drawerRef = this.drawerService.create<ImportBookingInfoComponent, { value: any }, string>({
            nzWidth: 1000,
            nzMaskClosable: false,
            nzContent: ImportBookingInfoComponent,
            nzContentParams: {},
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.ContactsInfo.subscribe(
              data => {
                this.contactInfo = data;
                drawerRef.close();
              }
            );
          });
        }
        break;
      case 'UNDERWRITING':
        if (this.isShow('UNDERWRITING')) {
          const drawerRef = this.drawerService.create<ImportUnderwritingInfoComponent, { value: any }, string>({
            nzWidth: 1000,
            nzMaskClosable: false,
            nzContent: ImportUnderwritingInfoComponent,
            nzContentParams: {},
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.ContactsInfo.subscribe(
              data => {
                this.contactInfo = data;
                drawerRef.close();
              }
            );
          });
        }
        break;
      case 'POLICY':
        if (this.isShow('POLICY')) {
          const importPolicy = this.drawerService.create<ImportPolicyInfoComponent, { value: any }, string>({
            nzWidth: 1000,
            nzMaskClosable: false,
            nzContent: ImportPolicyInfoComponent,
            nzContentParams: {},
          });
          importPolicy.afterOpen.subscribe(() => {
            const component = importPolicy.getContentComponent();
            component.ContactsPolicy.subscribe(
              data => {
                this.contactInfo = data;
                importPolicy.close();
              }
            );
          });
        }
        break;
    }
  }

  getBusinessSourceList(): void {
    this.contactService.businessSourceList()
      .subscribe(
        res => {
          if (res.length) {
            for (const source of res) {
              this.businessSourceOptions.push(new PropertySelectOption(source.businessSource, source.businessSource));
            }
          } else {
            this.businessSourceOptions = [];
          }
        }
      );
  }

  isShow(str: string): boolean {
    let flag = false;
    this.workbenchService.workbench.features.map(item => {
      item.squares.map(info => {
        if (info.name === str) {
          flag = info.enable;
        }
      });
    });
    return flag;
  }

  isDisabled(): boolean {
    let flag = false;
    if (this.sourceType === 'PRE_SALES' && this.mode === 'SUBORDINATE') {
      flag = true;
    }
    return flag;
  }
}
