import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkbenchComponent } from './workbench.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { DataBoardComponent } from './data-board/data-board.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';



@NgModule({
  declarations: [
    WorkbenchComponent,
    DashboardComponent,
    DataBoardComponent
  ],
  imports: [
    CommonModule,
    NzGridModule,
    NzAvatarModule,
    TranslateModule,
    NzButtonModule,
    NzToolTipModule,
    NzSkeletonModule,
    NzEmptyModule,
    NzTableModule,
    SharedModule,
    NzIconModule,
    NzDatePickerModule,
    FormsModule,
    NgxEchartsModule,
    NzTagModule,
    NzSelectModule,
    NzDividerModule,
    NzSpinModule,
    NzPopoverModule,
    NzPaginationModule
  ]
})
export class WorkbenchModule { }
