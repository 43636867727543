<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'ProposalDetail' | translate}}
    </div>
    <div class="section-wrapper" style="">
      <div
        style="padding: 15px 0 15px 0;display: flex; flex-direction: row; align-items: center; justify-content: start;">
        <div style="flex: 1; display: flex; flex-direction: row; align-items: center;">
          <img alt="" width="20" src="assets/images/ic_details.svg">
          <span
            style="margin-left: 5px; font-weight: bold; color: #111111; font-size: 16px">{{proposal.salesStatusNote}}</span>
        </div>
        <a *ngIf="workbench.downloadProposal && proposal.status === 'COMPLETED'" nz-button nzType="link" nzSize="small" nz-tooltip
           [nzTooltipTitle]="'Preview' | translate"
           appThrottleClick (throttleClick)="onPreview(); $event.stopPropagation();">
          <img alt="" width="20" src="assets/images/ic_view.svg">
        </a>
        <a *ngIf="workbench.downloadProposal && proposal.status === 'COMPLETED'" nz-button nzType="link" nzSize="small" nz-tooltip
           [nzTooltipTitle]="'Download' | translate"
           appThrottleClick (throttleClick)="onDownload(); $event.stopPropagation();">
          <img alt="" width="20" src="assets/images/ic_download.svg">
        </a>
        <button *ngIf="proposal.status === 'PENDING' && proposal.canRetry()" nz-button nzType="primary" nzShape="round"
                appThrottleClick (throttleClick)="onRetry()">
          {{'Retry' | translate}}
        </button>
      </div>
    </div>

    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'ProductPlan' | translate}}</span>
    </div>

    <nz-row [nzGutter]="15">
      <nz-col [nzSpan]="24">
        <!-- 计划书语言 -->
        <app-property-text [displayMode]="'vertical'" [title]="'ProposalLanguage' | translate"
                           [bottomLineVisible]="false" [value]="proposal.language"></app-property-text>
      </nz-col>
    </nz-row>

    <nz-row [nzGutter]="15">
      <nz-col [nzSpan]="12">
        <!-- 公司名稱 -->
        <app-property-text [displayMode]="'vertical'" [title]="'Insurer' | translate"
                           [bottomLineVisible]="false" [value]="proposal.companyName"></app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12">
        <!-- 产品 -->
        <app-property-text [displayMode]="'vertical'" [title]="'InsuranceProduct' | translate"
                           [bottomLineVisible]="false" [value]="proposal.productName"></app-property-text>
      </nz-col>
    </nz-row>

    <nz-row [nzGutter]="15">
      <nz-col [nzSpan]="12">
        <!-- 供款年期 -->
        <app-property-text [displayMode]="'vertical'" [title]="'PaymentTerm' | translate"
                           [bottomLineVisible]="false" [value]="proposal.paymentTerm"></app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12">
        <!-- 货币 -->
        <app-property-text [displayMode]="'vertical'" [title]="'Currency' | translate"
                           [bottomLineVisible]="false" [value]="proposal.currency"></app-property-text>
      </nz-col>
    </nz-row>

    <nz-row [nzGutter]="15">
      <nz-col [nzSpan]="12">
        <!-- 保額 -->
        <app-property-text [displayMode]="'vertical'" [title]="'SumAssured' | translate"
                           [bottomLineVisible]="false" [value]="proposal.sumAssured"></app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12">
        <!-- 保費 -->
        <app-property-text [displayMode]="'vertical'" [title]="'Premium' | translate"
                           [bottomLineVisible]="false" [value]="proposal.premium"></app-property-text>
      </nz-col>
    </nz-row>
    <nz-row nzGutter="15" *ngIf="proposal.dynamicFields && proposal.dynamicFields.length">
      <nz-col [nzSpan]="12" *ngFor="let dynamicField of proposal.dynamicFields">
        <app-property-text [displayMode]="'vertical'" [title]="dynamicField.fieldName"
                           [bottomLineVisible]="false" [value]="dynamicField.value"></app-property-text>
      </nz-col>
    </nz-row>
    <nz-row [nzGutter]="15">
      <nz-col [nzSpan]="12">
        <!-- 付款频率 -->
        <app-property-text *ngIf="product && product.proposalFrequencySupported" [displayMode]="'vertical'"
                           [title]="'PaymentFrequency' | translate"
                           [bottomLineVisible]="false"
                           [value]="proposal.frequency | metadataTranslate: 'frequency'"></app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12">
        <!-- 是否预缴 -->
        <app-property-text *ngIf="product && product.proposalPrepaymentSupported" [displayMode]="'vertical'"
                           [title]="'PrepaidOrNot' | translate"
                           [bottomLineVisible]="false"
                           [value]="(proposal.prepay ? ('Yes' | translate) : ('No' | translate))"></app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12">
        <app-property-text *ngIf="proposal.prepay" [displayMode]="'vertical'"
                           [title]="'PrepayYear' | translate"
                           [bottomLineVisible]="false"
                           [value]="proposal.prepayYear"></app-property-text>
      </nz-col>
    </nz-row>

    <div *ngIf="product?.categoryCode === 'MEDICAL'">
      <div class="section-title">
        <span style="margin-left: 8px">{{'MedicalPlan' | translate}}</span>
      </div>
      <nz-row [nzGutter]="15">
        <nz-col [nzSpan]="12">
          <!-- 保障地區 -->
          <app-property-text [displayMode]="'vertical'" [title]="'GuaranteedArea' | translate"
                             [bottomLineVisible]="false"
                             [value]="proposal.coverageRegion | metadataTranslate:'medicalRegion'"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <!-- 醫療等級 -->
          <app-property-text [displayMode]="'vertical'" [title]="'MedicalLevel' | translate"
                             [bottomLineVisible]="false"
                             [value]="proposal.medicalLevel | metadataTranslate:'medicalLevel'"></app-property-text>
        </nz-col>
      </nz-row>
      <nz-row [nzGutter]="15">
        <nz-col [nzSpan]="12">
          <!-- 自付额 -->
          <app-property-text [displayMode]="'vertical'" [title]="'Deductible' | translate"
                             [bottomLineVisible]="false" [value]="proposal.deductible"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <!-- 附加保障 -->
          <app-property-tags [displayMode]="'vertical'" [title]="'SupplementaryBenefits' | translate"
                             [bottomLineVisible]="false"
                             [value]="proposal.supplementaryBenefits | metadataTranslate:'supplementaryBenefit'"></app-property-tags>
        </nz-col>
      </nz-row>
    </div>

    <nz-tabset [nzSize]="'large'" [nzCentered]="false">
      <nz-tab [nzTitle]=basicInfo>
        <!-- 附加險-->
        <div *ngIf="proposal.riders && proposal.riders.length">
          <div class="section-title">
            <span style="margin-left: 8px">{{'Rider' | translate}}</span>
          </div>
          <div *ngFor="let rider of proposal.riders; let i = index" class="riders-box">
            <div style="flex: 1">
              <div style="font-size: 15px; color: black">
                <div>{{rider.productName}}</div>
              </div>
              <div style="color: #857e7e; font-size: 12px" *ngIf="rider.categoryCode === 'MEDICAL'">
                <span>{{'Deductible' | translate}}:</span>
                <span *ngIf="rider.medicalPlan.deductible">
                  {{rider.medicalPlan.deductible}}
                  {{rider.medicalPlan.deductible && proposal?.currency ? (proposal?.currency | metadataTranslate: 'currency') : ''}}
                </span>
              </div>
              <div style="color: #857e7e; font-size: 12px" *ngIf="rider.categoryCode !== 'MEDICAL'">
                <span>{{'Premium' | translate}}: </span>
                <span *ngIf="rider.premium">
                  {{rider.premium}}
                  {{rider.premium && proposal?.currency ? (proposal?.currency | metadataTranslate: 'currency') : ''}}
                </span>
                <span style="margin-left: 5px;">{{'SumAssured' | translate}}: </span>
                <span *ngIf="rider.sumAssured">
                  {{rider.sumAssured}}
                  {{rider.sumAssured && proposal?.currency ? (proposal?.currency | metadataTranslate: 'currency') : ''}}
                </span>
              </div>
              <div style="color: #857e7e; font-size: 12px" *ngIf="rider.categoryCode !== 'MEDICAL'">
                <span>{{'PaymentTerm' | translate}}: </span>
                <span *ngIf="rider.productYear">
                  {{rider.productYear}}
                  {{rider.productYear ? ('Year' | translate) : ''}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="section-title">
          <span style="margin-left: 8px">{{'Insured' | translate}}</span>
        </div>

        <nz-row [nzGutter]="15">
          <nz-col [nzSpan]="12">
            <!-- 姓名 -->
            <app-property-text [displayMode]="'vertical'" [title]="'FirstName' | translate"
                               [bottomLineVisible]="false" [value]="proposal.firstName"></app-property-text>
          </nz-col>
          <nz-col *ngIf="!workbench.hiddenLastName"  [nzSpan]="12">
            <!-- 名字 -->
            <app-property-text [displayMode]="'vertical'" [title]="'LastName' | translate"
                               [bottomLineVisible]="false" [value]="proposal.lastName"></app-property-text>
          </nz-col>
        </nz-row>

        <nz-row [nzGutter]="15">
          <nz-col [nzSpan]="12">
            <!-- 年龄 -->
            <app-property-text [displayMode]="'vertical'"
                               [title]="proposal.companyCode === 'PRUDENTIAL' ? ('NextYearAge' | translate) : ('Age' | translate)"
                               [bottomLineVisible]="false" [value]="proposal.age"></app-property-text>
          </nz-col>
          <nz-col [nzSpan]="12">
            <!-- 性别 -->
            <app-property-text [displayMode]="'vertical'" [title]="'Gender' | translate"
                               [bottomLineVisible]="false"
                               [value]="proposal.gender | metadataTranslate: 'gender'"></app-property-text>
          </nz-col>
        </nz-row>

        <nz-row [nzGutter]="15">
          <nz-col [nzSpan]="12">
            <!-- 是否吸烟 -->
            <app-property-text [displayMode]="'vertical'" [title]="'SmokeCondition' | translate"
                               [bottomLineVisible]="false" [value]="proposal.smoke | metadataTranslate:'smoke'">
            </app-property-text>
          </nz-col>
          <nz-col [nzSpan]="12" *ngIf="proposal.smoke === 'S'">
            <app-property-text [displayMode]="'vertical'" [title]="'SmokingVolume' | translate"
                               [bottomLineVisible]="false" [value]="((proposal.smokeQuantity) + '/' + ('AD' | translate))">
            </app-property-text>
          </nz-col>
          <nz-col [nzSpan]="12">
            <!-- 国家地区 -->
            <app-property-text [displayMode]="'vertical'" [title]="'Country' | translate"
                               [bottomLineVisible]="false"
                               [value]="proposal.residenceRegion | metadataTranslate: 'countries'">
            </app-property-text>
          </nz-col>
        </nz-row>

        <div *ngIf="proposal.owner && !isOwnerEmpty" class="section-title" style="margin-top: 20px">
          <span style="margin-left: 8px">{{'Holder' | translate}}
            ({{proposal.relationship | metadataTranslate:'relationship'}})
          </span>
        </div>

        <div *ngIf="proposal.owner && !isOwnerEmpty">

          <nz-row [nzGutter]="15">
            <nz-col [nzSpan]="12">
              <!-- 姓名 -->
              <app-property-text [displayMode]="'vertical'" [title]="'FirstName' | translate"
                                 [bottomLineVisible]="false" [value]="proposal.owner.firstName"></app-property-text>
            </nz-col>
            <nz-col *ngIf="!workbench.hiddenLastName"  [nzSpan]="12">
              <!-- 名字 -->
              <app-property-text [displayMode]="'vertical'" [title]="'LastName' | translate"
                                 [bottomLineVisible]="false" [value]="proposal.owner.lastName"></app-property-text>
            </nz-col>
          </nz-row>

          <nz-row [nzGutter]="15">
            <nz-col [nzSpan]="12">
              <!-- 年龄 -->
              <app-property-text [displayMode]="'vertical'"
                                 [title]="proposal.companyCode === 'PRUDENTIAL' ? ('NextYearAge' | translate) : ('Age' | translate)"
                                 [bottomLineVisible]="false" [value]="proposal.owner.age"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <!-- 性别 -->
              <app-property-text [displayMode]="'vertical'" [title]="'Gender' | translate"
                                 [bottomLineVisible]="false"
                                 [value]="proposal.owner.gender | metadataTranslate: 'gender'"></app-property-text>
            </nz-col>
          </nz-row>

          <nz-row [nzGutter]="15">
            <nz-col [nzSpan]="12">
              <!-- 是否吸烟 -->
              <app-property-text [displayMode]="'vertical'" [title]="'SmokeCondition' | translate"
                                 [bottomLineVisible]="false"
                                 [value]="proposal.owner.smoke | metadataTranslate:'smoke'"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12" *ngIf="proposal.owner.smoke === 'S'">
              <app-property-text [displayMode]="'vertical'" [title]="'SmokingVolume' | translate"
                                 [bottomLineVisible]="false" [value]="((proposal.owner.smokeQuantity) + '/' + ('AD' | translate))">
              </app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <!-- 国家地区 -->
              <app-property-text [displayMode]="'vertical'" [title]="'Country' | translate"
                                 [bottomLineVisible]="false"
                                 [value]="proposal.owner.residenceRegion | metadataTranslate: 'countries'"></app-property-text>
            </nz-col>
          </nz-row>
        </div>
      </nz-tab>
      <ng-template #basicInfo>
        {{'BasicInformation' | translate}}
      </ng-template>
      <nz-tab [nzTitle]=withdrawalPlan>
<!--        <div *ngIf="proposal.doHedge" style="margin-bottom: 10px">-->
<!--          &lt;!&ndash; 对冲方案&ndash;&gt;-->
<!--          <div class="section-title">-->
<!--            <span style="margin-left: 8px">{{'HedgeProposal' | translate}}</span>-->
<!--          </div>-->
<!--          <nz-row [nzGutter]="15">-->
<!--            <nz-col [nzSpan]="14">-->
<!--              <app-property-text [displayMode]="'vertical'" [title]="'ProductName' | translate"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [value]="proposal.hedgePlan.productCode"></app-property-text>-->
<!--            </nz-col>-->
<!--          </nz-row>-->
<!--          <nz-row [nzGutter]="15">-->
<!--            <nz-col [nzSpan]="12">-->
<!--              <app-property-text [displayMode]="'vertical'" [title]="'AgeTo' | translate"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [value]="proposal.hedgePlan.endAge"></app-property-text>-->
<!--            </nz-col>-->
<!--            <nz-col [nzSpan]="12">-->
<!--              <app-property-text [displayMode]="'vertical'" [title]="'GuaranteedArea' | translate"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [value]="(proposal.hedgePlan.coverageRegion | metadataTranslate:'medicalRegion')"></app-property-text>-->
<!--            </nz-col>-->
<!--          </nz-row>-->
<!--          <nz-row [nzGutter]="15">-->
<!--            <nz-col [nzSpan]="12">-->
<!--              <app-property-text [displayMode]="'vertical'" [title]="'MedicalLevel' | translate"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [value]="(proposal.hedgePlan.medicalLevel | metadataTranslate:'medicalLevel')"></app-property-text>-->
<!--            </nz-col>-->
<!--            <nz-col [nzSpan]="12">-->
<!--              <app-property-text [displayMode]="'vertical'" [title]="'Deductible' | translate"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [value]="(proposal.hedgePlan.deductible || 0)"></app-property-text>-->
<!--            </nz-col>-->
<!--          </nz-row>-->
<!--          <nz-row [nzGutter]="15">-->
<!--            <nz-col [nzSpan]="12">-->
<!--              <app-property-text [displayMode]="'vertical'" [title]="'SupplementaryBenefits' | translate"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [value]="proposal.hedgePlan.supplementaryBenefits | metadataTranslate:'supplementaryBenefit'"></app-property-text>-->
<!--            </nz-col>-->
<!--          </nz-row>-->
<!--        </div>-->
        <!--        提取计划-->
        <div *ngIf="proposal.withdrawals">

          <div class="section-title">
            <span style="margin-left: 8px">{{'WithdrawalPlan' | translate}}</span>
          </div>

          <div *ngIf="proposal.withdrawals.length > 0; else dataNull">
            <div *ngFor="let withdrawal of proposal.withdrawals; let i = index" class="proposal-withdrawal">
              <div class="flex-row">
                <div class="flex-column" style="flex: 1">
                  <div style="color: #1d64c4; font-size: 18px;display: flex;align-items: center">
                    <div *ngIf="!withdrawal.max">{{withdrawal.amount | number: '.2'}}</div>
                    <div *ngIf="withdrawal.max">MAX</div>
                    <span *ngIf="instance.isFone() ? false : withdrawal.name" class="tab-name">
                      {{withdrawal.name}}
                    </span>
                  </div>
                  <div style="color: #555555; font-size: 13px">
                    <div>{{withdrawal.str ?? ''}}</div>
                  </div>
                </div>
                <div style="flex-shrink: 1; color: #AAAAAA; font-size: 28px; margin: 0 15px 0 0">
                  {{(i + 1) | number: '2.0'}}
                </div>
              </div>
            </div>

            <div *ngIf="proposal.companyCode !== 'AXA' && proposal.companyCode !== 'AXAMACAU'"
                 style="margin: 10px">{{'AllowReduceBasicAmount' | translate}}
              / {{ (proposal.reduceBasic ? 'Yes' : 'No') | translate}}
            </div>
            <div *ngIf="proposal.companyCode === 'AXA' || proposal.companyCode === 'AXAMACAU'">
              {{'WithdrawalArrangement' | translate}} /
              <span>{{ proposal.reduceBasic ? ('PARTIAL_WITHDRAWAL' | metadataTranslate:'withdrawalType') : ('PERIODIC_WITHDRAWAL' | metadataTranslate:'withdrawalType') }}</span>
            </div>
          </div>
          <ng-template #dataNull>
            <nz-empty nzNotFoundImage="simple"></nz-empty>
          </ng-template>

        </div>
      </nz-tab>
      <ng-template #withdrawalPlan>
        {{'WithdrawalPlan' | translate}}
      </ng-template>

      <nz-tab *ngIf="proposal.extracted" [nzTitle]=valueDemonstration>
        <div>
          <!--      價值演示-->
          <div class="section-title" style="display: flex; justify-content: space-between;">
            <span style="margin-left: 8px">{{'ValueDemonstration' | translate}}</span>

            <button nz-button [nzSize]="'small'" nzType="link"
                    appThrottleClick (throttleClick)="onViewData();">{{'view_full' | translate}}
            </button>
          </div>

          <div style="margin: 15px 0 15px 0">
            <nz-row [nzGutter]="10">
              <nz-col [nzSpan]="12">
                <nz-select style="display: block;" [(ngModel)]="surrenderValueType" class="select"
                           (ngModelChange)="dealValueDemonstration()">
                  <nz-option *ngFor="let option of surrenderValueTypeOptions" [nzLabel]="option.label | translate"
                             [nzValue]="option.value" [nzDisabled]="option.disabled"></nz-option>
                </nz-select>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-select style="display: block;" [(ngModel)]="surrenderValueScenario" class="select"
                           (ngModelChange)="dealValueDemonstration()">
                  <nz-option *ngFor="let option of surrenderValueScenarioOptions" [nzLabel]="option.label | translate"
                             [nzValue]="option.value" [nzDisabled]="option.disabled"></nz-option>
                </nz-select>
              </nz-col>
            </nz-row>
          </div>
          <div echarts [options]="valueDemoChartsOption" style="height: 400px" [loading]="loading"></div>

          <div class="section-title" style="margin-top: 20px">
            <span style="margin-left: 8px">{{'RateDemonstration' | translate}}</span>
          </div>

          <div style="margin: 15px 0 15px 0">
            <nz-row [nzGutter]="10">
              <nz-col [nzSpan]="12">
                <nz-select style="display: block;" [(ngModel)]="surrenderRateType" class="select"
                           (ngModelChange)="dealRateDemonstration()">
                  <nz-option *ngFor="let option of surrenderRateTypeOptions" [nzLabel]="option.label | translate"
                             [nzValue]="option.value" [nzDisabled]="option.disabled"></nz-option>
                </nz-select>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-select style="display: block;" [(ngModel)]="surrenderRateScenario" class="select"
                           (ngModelChange)="dealRateDemonstration()">
                  <nz-option *ngFor="let option of surrenderRateScenarioOptions" [nzLabel]="option.label | translate"
                             [nzValue]="option.value" [nzDisabled]="option.disabled"></nz-option>
                </nz-select>
              </nz-col>
            </nz-row>
          </div>
          <div echarts [options]="rateDemoChartsOption" style="height: 400px" [loading]="loading"></div>

        </div>
      </nz-tab>
      <ng-template #valueDemonstration>
        {{'ValueDemonstration' | translate}}
      </ng-template>

      <!--    保费融资-->
<!--      <nz-tab *ngIf="proposal.doPremiumFinancing" [nzTitle]=premiumFinancing>-->
<!--        <div class="section-title">-->
<!--          <span style="margin-left: 8px">{{'PremiumFinancing' | translate}}</span>-->
<!--        </div>-->

<!--        <div class="secondary-title">-->
<!--          <span>{{'BackInfo' | translate}}</span>-->
<!--        </div>-->
<!--        <nz-row [nzGutter]="15">-->
<!--          <nz-col [nzSpan]="12">-->
<!--            &lt;!&ndash; 银行名称 &ndash;&gt;-->
<!--            <app-property-text [displayMode]="'vertical'" [title]="'BankName' | translate"-->
<!--                               [bottomLineVisible]="false"-->
<!--                               [value]="proposal.premiumFinancing.bankInfo.name"></app-property-text>-->
<!--          </nz-col>-->
<!--          <nz-col [nzSpan]="12">-->
<!--            &lt;!&ndash; 貸款比例 &ndash;&gt;-->
<!--            <app-property-text [displayMode]="'vertical'" [title]="'LoanRatio' | translate"-->
<!--                               [bottomLineVisible]="false"-->
<!--                               [value]="toDecimal(proposal.premiumFinancing.bankInfo.loanRatio)"></app-property-text>-->
<!--          </nz-col>-->
<!--        </nz-row>-->
<!--        <nz-row [nzGutter]="15">-->
<!--          <nz-col [nzSpan]="12">-->
<!--            &lt;!&ndash; 贷款年限 &ndash;&gt;-->
<!--            <app-property-text [displayMode]="'vertical'" [title]="'LoanYear' | translate"-->
<!--                               [bottomLineVisible]="false"-->
<!--                               [value]="proposal.premiumFinancing.bankInfo.year + ('Year' | translate)"></app-property-text>-->
<!--          </nz-col>-->
<!--          <nz-col [nzSpan]="12">-->
<!--            &lt;!&ndash; 年利率 &ndash;&gt;-->
<!--            <app-property-text [displayMode]="'vertical'" [title]="'AnnualInterestRate' | translate"-->
<!--                               [bottomLineVisible]="false"-->
<!--                               [value]="toDecimal(proposal.premiumFinancing.bankInfo.annualInterestRate)"></app-property-text>-->
<!--          </nz-col>-->
<!--        </nz-row>-->
<!--        <nz-row [nzGutter]="15">-->
<!--          <nz-col [nzSpan]="12">-->
<!--            &lt;!&ndash; 預付保費利息 &ndash;&gt;-->
<!--            <app-property-text [displayMode]="'vertical'" [title]="'PrepaymentInterestRate' | translate"-->
<!--                               [bottomLineVisible]="false"-->
<!--                               [value]="toDecimal(proposal.premiumFinancing.bankInfo.prepaymentInterestRate)"></app-property-text>-->
<!--          </nz-col>-->
<!--          <nz-col [nzSpan]="12">-->
<!--            &lt;!&ndash; 貸款手續費率 &ndash;&gt;-->
<!--            <app-property-text [displayMode]="'vertical'" [title]="'PolicyLoanHandlingFeeRate' | translate"-->
<!--                               [bottomLineVisible]="false"-->
<!--                               [value]="toDecimal(proposal.premiumFinancing.bankInfo.policyLoanHandlingFeeRate)"></app-property-text>-->
<!--          </nz-col>-->
<!--        </nz-row>-->
<!--        &lt;!&ndash;      折扣信息&ndash;&gt;-->
<!--        <div class="secondary-title">-->
<!--          <span>{{'DiscountInfo' | translate}}</span>-->
<!--        </div>-->
<!--        <div>-->
<!--          <nz-row [nzGutter]="15">-->
<!--            <nz-col [nzSpan]="12">-->
<!--              &lt;!&ndash; 首年折扣優惠 &ndash;&gt;-->
<!--              <app-property-text [displayMode]="'vertical'" [title]="'FirstYearPremiumDiscount' | translate"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [value]="toDecimal(proposal.premiumFinancing.discountInfo.firstYearPremiumDiscount)"></app-property-text>-->
<!--            </nz-col>-->
<!--            <nz-col [nzSpan]="12">-->
<!--              &lt;!&ndash; 每年折扣優惠 &ndash;&gt;-->
<!--              <app-property-text [displayMode]="'vertical'" [title]="'AnnualPremiumDiscount' | translate"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [value]="toDecimal(proposal.premiumFinancing.discountInfo.annualPremiumDiscount)"></app-property-text>-->
<!--            </nz-col>-->
<!--          </nz-row>-->
<!--          <nz-row [nzGutter]="15">-->
<!--            <nz-col [nzSpan]="12">-->
<!--              &lt;!&ndash; 保費回贈 &ndash;&gt;-->
<!--              <app-property-text [displayMode]="'vertical'" [title]="'PremiumCashback' | translate"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [value]="toDecimal(proposal.premiumFinancing.discountInfo.premiumCashback)"></app-property-text>-->
<!--            </nz-col>-->

<!--          </nz-row>-->
<!--        </div>-->
<!--      </nz-tab>-->
<!--      <ng-template #premiumFinancing>-->
<!--        {{'PremiumFinancing' | translate}}-->
<!--      </ng-template>-->
      <nz-tab [nzTitle]="other">
        <div *ngIf="proposal.doPremiumOffset" class="section-title">
          <!-- 保费扣除-->
          <span style="margin-left: 8px">{{'PremiumOffset' | translate}}</span>
        </div>
        <nz-row *ngIf="proposal.doPremiumOffset" [nzGutter]="15">
          <nz-col [nzSpan]="12">
            <app-property-text [displayMode]="'vertical'" [title]="'PolicyYear' | translate"
                               [bottomLineVisible]="false"
                               [value]="proposal.premiumOffsetStartYear"></app-property-text>
          </nz-col>
          <nz-col [nzSpan]="12">
            <app-property-text [displayMode]="'vertical'" [title]="'PolicyEndYear' | translate"
                               [bottomLineVisible]="false"
                               [value]="proposal.premiumOffsetEndYear"></app-property-text>
          </nz-col>
        </nz-row>
        <div *ngIf="proposal.special" class="section-title">
          <!--      附加需求-->
          <span style="margin-left: 8px">{{'AdditionalRequirements' | translate}}</span>
        </div>
        <div>
          {{proposal.special}}
        </div>
        <div *ngIf="proposal.doFlexiPremium">
          <div class="section-title">
            <!--      是否弹性保费-->
            <span style="margin-left: 8px">{{'DoFlexiPremium' | translate}}</span>
          </div>
          <app-property-text [displayMode]="'vertical'" [title]="'FlexiPremiumOption' | translate"
                             [bottomLineVisible]="false"
                             [value]="proposal.flexiPremiumOption + ('Year' | translate)"></app-property-text>
        </div>
      </nz-tab>
      <ng-template #other>
        {{'Other' | translate}}
      </ng-template>
    </nz-tabset>

  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col [nzSpan]="proposal.status === 'CANCELLED' || proposal.status === 'PROCESSING' ? 24 : 12"
           class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReuse()">
          <!--          <em nz-icon nzType="reload" nzTheme="outline"></em>-->
          <span>{{'Reuse' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary"
           *ngIf="proposal.status !== 'COMPLETED' && proposal.status !== 'CANCELLED' && proposal.status !== 'PROCESSING'">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
          <span>{{'CancelApplication' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary" *ngIf="proposal.status === 'COMPLETED'">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReserveNow()">
          <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
          <span>{{'MakeReservationNow' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

  <nz-drawer [nzWidth]="800" [nzMaskClosable]="true" [nzVisible]="dataVisible"
             [nzTitle]="'ValueDemonstration' | translate"
             (nzOnClose)="dataDrawerClose()">
    <ng-container *nzDrawerContent>
      <div class="drawer-container">

        <nz-row [nzGutter]="10">
          <nz-col [nzSpan]="12">
            <nz-select style="display: block;" [(ngModel)]="valueTableType" class="select"
                       (ngModelChange)="changeDataTable()">
              <nz-option *ngFor="let option of valueTableTypeOptions" [nzLabel]="option.label | translate"
                         [nzValue]="option.value" [nzDisabled]="option.disabled"></nz-option>
            </nz-select>
          </nz-col>
          <nz-col [nzSpan]="12">
            <nz-select style="display: block;" [(ngModel)]="secondValueTableType" class="select"
                       (ngModelChange)="changeDataTable()">
              <nz-option *ngFor="let option of surrenderValueScenarioOptions" [nzLabel]="option.label | translate"
                         [nzValue]="option.value" [nzDisabled]="option.disabled"></nz-option>
            </nz-select>
          </nz-col>
        </nz-row>

        <div style="margin-top: 20px;">
          <nz-table [nzBordered]="false"
                    [nzOuterBordered]="true"
                    [nzShowPagination]="false"
                    [nzLoading]="loadingTable"
                    [nzLoadingIndicator]="indicatorTemplate"
                    [nzFrontPagination]="false"
                    [nzData]="tableValueArray"
                    [nzTitle]="null"
                    [nzFooter]="null"
                    [nzScroll]="{ x: '1690px', y: '600px'}">
            <thead>
            <tr>
              <th nzLeft nzWidth="150px"><span>{{'PolicyYear' | translate}}</span></th>
              <th><span>{{'total_premiums_paid' | translate}}</span></th>
              <th><span>{{'TotalCashValue' | translate}}</span></th>
              <th><span>{{'GuaranteedCashValue' | translate}}</span></th>
              <th><span>{{'WithdrawalAmount' | translate}}</span></th>
              <th><span>{{'RateOfReturn' | translate}}</span></th>
              <th><span>{{'AnnualRateOfReturn' | translate}}</span></th>
              <th><span>{{'InternalRateOfReturn' | translate}}</span></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tableValueArray; let i = index">
              <td nzLeft>
                {{ item.age }}{{ 'YearsOld' | translate}} ({{item.year}}{{'Year' | translate}})
              </td>
              <td>{{ item.totalPremiumsPaid | number: '1.2' }}</td>
              <td>{{ item.total | number: '1.2'}}</td>
              <td>{{ item.guaranteed | number: '1.2' }}</td>
              <td>{{ item.withdrawnAmount | number: '1.2' }}</td>
              <td>{{ changeDecimalToPercentage(item.rateOfReturn) }}</td>
              <td>{{ changeDecimalToPercentage(item.annualRateOfReturn) }}</td>
              <td>{{ changeDecimalToPercentage(item.internalRateOfReturn) }}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>

        <div *ngIf="workbench.downloadProposalDetailValue && proposal.contentImage && proposal.contentImage.filePath"
             class="footer" style="z-index: 999;">
          <div nz-row style="width: 100%; height: 100%;">
            <div nz-col [nzSpan]="24" class="footer-button">
              <a nz-button nzType="link" nzBlock nzSize="large"
                 appThrottleClick (throttleClick)="onDownloadImg()">
                <span style="display: flex;align-items: center;justify-content: center;">
                  {{'ValueDemonstration' | translate}}PDF
                  <nz-tag [nzColor]="'#f66667'" style="font-size: 11px;padding: 0 4px;border-radius: 6px;margin-left: 4px;">
                    {{ 'Trial' | translate }}</nz-tag>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>

</div>
