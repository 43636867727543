import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerEditMode } from '../../shared';
import {
  ContingentOwner,
  Customer,
  CustomerCorporate,
  CustomerType, DataDesensitization, DataMode,
  Gender, Instance,
  Phone,
  Relationship,
  SmokeCondition, Underwriting, UnderwritingDetailResp
} from '../../../api/types';
import { PropertySelectOption } from '../property-select/property-select.component';
import { MetadataService } from '../../service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { Addresses } from '../../utils/addresses';
import { AddressEditComponent } from '../address-edit/address-edit.component';
import { Phones } from '../../utils/phones';
import { PhoneEditComponent } from '../phone-edit/phone-edit.component';
import { CustomerEditComponent, CustomerEntity } from '../customer-edit/customer-edit.component';
import { Mode } from 'fs';
import { plainToClass } from 'class-transformer';
import { UnderwritingService } from '../../../underwriting/underwriting.service';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { ProductService } from '../../../product/product.service';

@Component({
  selector: 'app-customer-read',
  templateUrl: './customer-read.component.html',
  styleUrls: ['./customer-read.component.less']
})
export class CustomerReadComponent implements OnInit {

  @Input()
  mode: CustomerEditMode;
  @Input()
  moduleSource: string;

  isEdit = false;
  loading = false;

  title: string;
  customerType: CustomerType = CustomerType.INDIVIDUAL;
  customer: Customer = new Customer(); // 个人客户 - 投保人/持有人
  customerCorporate: CustomerCorporate = new CustomerCorporate(); // 企业客户 - 投保人/受保人
  relationship: Relationship; // 与投保人关系
  contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
  dataDesensitization: DataDesensitization = new DataDesensitization();
  modeType = DataMode.OWN;
  birthdayDate: number;
  sourceType: string;
  instance: Instance;
  @Input()
  underwriting: Underwriting;
  @Input()
  underwritingDetail: UnderwritingDetailResp;
  @Output()
  underwritingChange: EventEmitter<UnderwritingDetailResp> = new EventEmitter<UnderwritingDetailResp>();
  // 快捷地址和电话其他数据源
  otherCustomer: Customer;
  otherCustomerCorporate: CustomerCorporate;

  @Output()
  customerSaved: EventEmitter<CustomerEntity> = new EventEmitter<CustomerEntity>();
  workbench: Workbench;

  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private underwritingService: UnderwritingService,
              private workbenchService: WorkbenchService,
              private productService: ProductService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.metadataService.mockInstance;
    this.getAge();
  }

  onAddress(): void {
    this.translate.get('ResidentialAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customer, this.otherCustomer]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customer.address,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.address = data;
            }
          );
        });
      });
  }

  onResidenceAddress(): void {
    this.translate.get('LegalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customer, this.otherCustomer]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customer.residenceAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.residenceAddress = data;
            }
          );
        });
      });
  }

  onPostalAddress(): void {
    this.translate.get('PostalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customer, this.otherCustomer]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customer.postalAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.postalAddress = data;
            }
          );
        });
      });
  }

  onMobileNumber(): void {
    this.translate.get('MobileNumber').subscribe(
      (title: any) => {

        const quickPhones = Phones.getIndividualQuickPhones([this.customer, this.otherCustomer]);

        const phone = new Phone();
        phone.areaCode = this.customer.areaCode;
        phone.phone = this.customer.phone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.areaCode = data?.areaCode;
              this.customer.phone = data?.phone;
            }
          );
        });
      });
  }

  onCompanyAddress(): void {
    this.translate.get('CompanyAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customer, this.otherCustomer]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customer.companyAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.companyAddress = data;
            }
          );
        });
      });
  }

  onOfficePhoneNumber(): void {
    this.translate.get('OfficePhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getIndividualQuickPhones([this.customer, this.otherCustomer]);

        const phone = new Phone();
        phone.areaCode = this.customer.companyAreaCode;
        phone.phone = this.customer.companyTelephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.companyAreaCode = data?.areaCode;
              this.customer.companyTelephone = data?.phone;
            }
          );
        });
      });
  }

  onResidentialPhoneNumber(): void {
    this.translate.get('ResidentialPhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getIndividualQuickPhones([this.customer, this.otherCustomer]);

        const phone = new Phone();
        phone.areaCode = this.customer.residenceAreaCode;
        phone.phone = this.customer.residenceTelephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.residenceAreaCode = data?.areaCode;
              this.customer.residenceTelephone = data?.phone;
            }
          );
        });
      });

  }

  onCorporateOfficePhoneNumber(): void {
    this.translate.get('OfficePhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getCorporateQuickPhones([this.customerCorporate, this.otherCustomerCorporate]);

        const phone = new Phone();
        phone.areaCode = this.customerCorporate.areaCode;
        phone.phone = this.customerCorporate.telephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerCorporate.areaCode = data?.areaCode;
              this.customerCorporate.telephone = data?.phone;
            }
          );
        });
      });
  }

  onCorporateResidentialAddress(): void {
    this.translate.get('RegisteredAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getCorporateQuickAddresses([this.customerCorporate, this.otherCustomerCorporate]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customerCorporate.registeredAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerCorporate.registeredAddress = data;
            }
          );
        });
      });
  }

  onCorporateContactAddress(): void {
    this.translate.get('PostalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getCorporateQuickAddresses([this.customerCorporate, this.otherCustomerCorporate]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customerCorporate.contactAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerCorporate.contactAddress = data;
            }
          );
        });
      });
  }

  onCorporateLegalPersonPhoneNumber(): void {
    this.translate.get('LegalPersonPhone').subscribe(
      (title: any) => {
        const quickPhones = Phones.getCorporateQuickPhones([this.customerCorporate, this.otherCustomerCorporate]);

        const phone = new Phone();
        phone.areaCode = this.customerCorporate.legalPersonAreaCode;
        phone.phone = this.customerCorporate.legalPersonPhone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerCorporate.legalPersonAreaCode = data?.areaCode;
              this.customerCorporate.legalPersonPhone = data?.phone;
            }
          );
        });
      });
  }

  onReset(): void {
    this.customer = new Customer();
    this.customerCorporate = new CustomerCorporate();
  }

  onSave(): void {
    // const customerEntity = new CustomerEntity();
    // customerEntity.customerType = this.customerType;
    // customerEntity.customer = this.customer;
    // customerEntity.corporateCustomer = this.customerCorporate;
    // customerEntity.relationship = this.relationship;
    // this.customerSaved.emit(customerEntity);
    console.log(this.moduleSource);
    console.log(this.mode);
    if (this.moduleSource && this.isEdit) {
        const drawerRef = this.drawerService.create<CustomerEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: CustomerEditComponent,
          nzContentParams: {
            title: this.title,
            mode: this.mode,
            modeType: 'underwriting',
            customerType: this.underwriting.ownerType,
            customer: this.underwritingDetail.owner ?? new Customer(),
            customerCorporate: this.underwritingDetail.companyOwner ?? new CustomerCorporate(),
            otherCustomer: this.underwritingDetail.insured,
            otherCustomerCorporate: this.underwritingDetail.companyInsured,
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.customerSaved
            .subscribe(
              customerEntity => {
                this.underwriting.ownerType = customerEntity.customerType;
                switch (customerEntity.customerType) {
                  case CustomerType.INDIVIDUAL:
                    if (this.mode === 'insured') {
                      this.underwriting.insured = customerEntity.customer;
                      this.saveInsured(this.underwriting, drawerRef);
                    } else {
                      this.underwriting.owner = customerEntity.customer;
                      this.saveOwner(this.underwriting, drawerRef);
                    }
                    break;
                  case CustomerType.CORPORATE:
                    if (this.mode === 'insured') {
                      this.underwriting.companyInsured = customerEntity.corporateCustomer;
                      this.saveInsured(this.underwriting, drawerRef);
                    } else {
                      this.underwriting.companyOwner = customerEntity.corporateCustomer;
                      this.saveOwner(this.underwriting, drawerRef);
                    }
                    break;
                }
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });

    }

  }

  saveOwner(underwriting: Underwriting, drawerRef: NzDrawerRef<CustomerEditComponent, string>): void {
    const underwritingInsuredReq = plainToClass(UnderwritingDetailResp, underwriting);
    this.loading = true;
    this.underwritingService.Owner(underwritingInsuredReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadUnderwritingDetail(underwriting.underwritingNo);
        },
        error => {
          this.loading = false;
        });
  }

  saveInsured(underwriting: Underwriting, drawerRef: NzDrawerRef<CustomerEditComponent, string>): void {
    const underwritingInsuredReq = plainToClass(UnderwritingDetailResp, underwriting, {excludeExtraneousValues: true});
    this.loading = true;
    this.underwritingService.insured(underwritingInsuredReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadUnderwritingDetail(underwriting.underwritingNo);
        },
        error => {
          this.loading = false;
        });
  }

  loadUnderwritingDetail(underwritingNo: string): void {
    this.loading = true;
    this.underwritingService.detail(underwritingNo)
      .subscribe(
        underwritingDetail => {
          this.loading = false;
          const underwriting = underwritingDetail.toUnderwriting();
          this.underwritingDetail = underwritingDetail;
          this.underwritingChange.emit(underwritingDetail);
          this.underwriting = underwriting;
          if (this.mode === 'holder') {
            this.customerType = underwritingDetail.ownerType;
            this.customer = underwritingDetail.owner;
            this.customerCorporate = underwritingDetail.companyOwner;
            this.relationship = underwritingDetail.relationship;
            this.otherCustomer = underwritingDetail.insured;
            this.otherCustomerCorporate = underwritingDetail.companyInsured;
          } else {
            this.customerType = underwriting.insuredType;
            this.customer = underwritingDetail.insured;
            this.customerCorporate = underwritingDetail.companyInsured;
            this.relationship = underwritingDetail.relationship;
            this.otherCustomer = underwritingDetail.owner;
            this.otherCustomerCorporate = underwritingDetail.companyOwner;
          }
        },
        error => {
          this.loading = false;
        });
  }

  get isShowItem(): boolean {
    const instance = this.metadataService.instance;
    return !(instance && instance.key === 'fone' && this.sourceType === 'policy');
  }


  dynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.modeType) {
      return isShow;
    }
    if (this.modeType === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }

  getAge(): void {
    if (!this.customer.birthdate) {
      return;
    }
    const timestamp = new Date(this.customer.birthdate).getTime();
    if (this.underwriting?.companyCode) {
      this.productService.age(timestamp, this.underwriting.companyCode)
        .subscribe(
          age => {
            this.birthdayDate = age;
          },
          error => {
          }
        );
    } else {
      if (timestamp > new Date().getTime()) {
        this.birthdayDate = 0;
        return ;
      }
      // 出生时间 毫秒
      const birthDayTime = new Date(parseInt(String(timestamp), 0)).getTime();
      // 当前时间 毫秒
      const nowTime = new Date().getTime();
      // 一年毫秒数(365 * 86400000 = 31536000000)
      this.birthdayDate = Math.floor((nowTime - birthDayTime) / 31536000000);
    }
  }

  isVIP(val): string {
    let str = '';
    if (!val) {
      return str;
    }
    if (val) {
      this.translate.get('Yes').subscribe(
        (res) => {
          str = res;
        }
      );
    } else {
      this.translate.get('No').subscribe(
        (res) => {
          str = res;
        }
      );
    }
    return str;
  }

  get isInstance(): boolean {
    const instance = this.metadataService.instance;
    return instance.key === 'fone' || instance.key === 'dev';
  }
}
