<div style="padding: 20px 20px 0">
  <div class="common-title" style="margin-bottom: 30px">
    {{'UnderwritingImport' | translate}}
  </div>

  <nz-spin [nzSpinning]="spinning">
    <div *ngIf="underwritings" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzFrontPagination]="false"
                [nzPageSizeOptions]="search.pageSizeOptions"
                [nzData]="underwritings.list"
                [nzPageIndex]="underwritings.pageNum"
                [nzPageSize]="underwritings.pageSize"
                [nzTotal]="underwritings.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1515'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'UnderwritingFollowUp' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'UnderwritingSearch' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onPolicySearch()"
                >
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ underwritings.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="165px">
            <span nz-tooltip
                  [nzTooltipTitle]="'UnderwritingNumber' | translate">{{'UnderwritingNumber' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'answer39' | translate">{{'answer39' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Referee' | translate">{{'Referee' | translate}}</span>
          </th>
          <th *ngIf="isShowBusiness" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'BusinessNumber' | translate">{{'BusinessNumber' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span>
          </th>

          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'Holder' | translate">{{'Holder' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span>{{'Insured' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'PaymentTerm' | translate">{{'PaymentTerm' | translate}}</span>
          </th>
          <th nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'SignDate' | translate">{{'SignDate' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let underwriting of underwritings.list; let i = index"
            appThrottleClick (throttleClick)="onPolicyDetail(underwriting.underwritingNo)"
        >
          <td nzLeft>
            <span style="font-weight: bold; font-size: 12px">
              #{{ underwriting.underwritingNo }}
            </span>
          </td>
          <td>
            <span style="font-weight: bold; font-size: 12px">
              {{ underwriting.policyNo }}
            </span>
          </td>
          <td>
            <div>
              <span>{{ dynamicEncryption('salesName', underwriting.salesName) }}</span>
              <span>{{ dynamicEncryption('salesName', underwriting.accountName) }}</span>
            </div>
            <div *ngIf="workbench.secondAccountName">
              <span>{{ dynamicEncryption('salesName', underwriting.secondSalesName) }}</span>
              <span>{{ dynamicEncryption('salesName', underwriting.secondAccountName) }}</span>
            </div>
          </td>
          <td *ngIf="isShowBusiness"><span>{{ underwriting.newBusinessNumber || 'N/A' }}</span></td>
          <td>
            <div style="display: flex; align-items: center;">
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="underwriting.companyLogo" alt=""/>
              <span>{{ underwriting.productName || 'N/A' }}<span
                *ngIf="underwriting.productYear">&nbsp; ({{ underwriting.custom ? (underwriting.productYear | yearConversion) : (underwriting.productYear | yearsOrMonthOrAge)}})</span></span>
            </div>
          </td>
          <td>
            <span>{{(dynamicEncryption('name', underwriting.name))}}</span>
            <br>
            <span>{{(dynamicEncryption('name', underwriting.pinyin))}}</span>
          </td>
          <td>{{ dynamicEncryption('insuredName', underwriting.insuredName) }}<br>{{dynamicEncryption('insuredName', underwriting.insuredPinyin)}}</td>
          <td>{{ underwriting.productYear }}</td>
          <td>
            <div [ngSwitch]="underwriting.status">
              <div>
                <span *ngSwitchCase="'CHECKING'" class="text-warning">●</span>
                <span *ngSwitchCase="'UNDERWRITING'" class="text-warning">●</span>
                <span *ngSwitchCase="'UNDERWRITE'" class="text-warning">●</span>
                <span *ngSwitchCase="'SENDING'" class="text-warning">●</span>
                <span *ngSwitchCase="'PENDING_PREMIUM'" class="text-warning">●</span>
                <span *ngSwitchCase="'FINISHED'" class="text-success">●</span>
                <span *ngSwitchCase="'FINISHED_PENDING_PREMIUM'" class="text-warning">●</span>
                <span *ngSwitchCase="'EFFECTIVE'" class="text-success">●</span>
                <span *ngSwitchCase="'DECLINED'" class="text-cancel">●</span>
                <span *ngSwitchCase="'DELETED'" class="text-warning">●</span>
                <span> {{ underwriting.status | metadataTranslate: 'underwritingStatus' }}</span>
              </div>
            </div>
          </td>
          <td>
            {{ (underwriting.signDate | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>

  <nz-modal
    [(nzVisible)]="importVisible"
    [nzTitle]="importModalTit"
    [nzContent]="importModalContent"
    [nzFooter]="null"
    nzCentered
    [nzWidth]="'400px'"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="importVisible = false"
  >
    <ng-template #importModalTit style="text-align: center">{{'Import' | translate}}</ng-template>
    <ng-template #importModalContent>
      <div style="display: flex; justify-content: center; padding-bottom: 20px;">
        <nz-radio-group [(ngModel)]="importInfoValue">
          <label nz-radio [nzValue]="'holder'"><span>{{'OwnerImport' | translate}}</span></label>
          <label nz-radio [nzValue]="'insured'"><span>{{'InsuredImport' | translate}}</span></label>
        </nz-radio-group>
      </div>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default"
                appThrottleClick (throttleClick)="importVisible = false"
        >{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary"
                appThrottleClick (throttleClick)="openImportDrawer()"
        >{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>
</div>
