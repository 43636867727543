<div *ngIf="workbench" class="sales-container" #salesRoot>

  <div class="sales-menu">
    <div class="sales-menu-logo">
      <img width="50" [src]="instanceLogo" alt="" appThrottleClick (throttleClick)="onNavigateToWorkbench()"/>
      <span class="company-name-text">
        {{ workbench.appCompanyName }}
      </span>
      <!--      <span class="env">-->
      <!--        <nz-tag>v{{version}}</nz-tag>-->
      <!--      </span>-->
    </div>
    <div class="page-header-title">
      {{ getPageTitle() | translate }}
      <!--      {{ pageTitle === 'ToDoItem' ? instance.isFone() ? 'ToDoItem' : 'Pending' : pageTitle ? (pageTitle | translate) : ''}}-->
      <span *ngIf="pageTitle === 'IntendedInvitation'" class="title-tag">{{ 'Trial' | translate }}</span>
    </div>
    <div *ngIf="realInstance.key === 'dev'">
      <input nz-input nzSize="small" [(ngModel)]="mockInstanceKey"
             (keyup.enter)="changeMockInstance()"/>
    </div>

    <button *ngIf="swUpdateAvailable" nz-button nzType="link" class="sales-menu-button"
            nz-tooltip [nzTooltipTitle]="'WebsiteUpdatedReloadThePage' | translate" appThrottleClick
            (throttleClick)="onReloadPage()">
      <img alt="" width="24" src="assets/images/ic_upgrade.svg" style="font-size: 16px;">
    </button>
    <button *ngIf="showAppQrCode()" nz-button nzType="link" nz-tooltip
            class="sales-app-qr"
            nz-popover [nzPopoverContent]="qrCodeTemplate"
            [(nzPopoverVisible)]="qrCodePopoverVisible"
            nzPopoverTrigger="hover"
            nzPopoverPlacement="bottom" style=" margin-left: 20px;">
      <div>
        <img alt="" width="24" src="assets/images/u3.svg" style="font-size: 16px;vertical-align: top;">
        <span style="margin-left:4px;font-size: 16px">{{ 'App' | translate }}</span>
      </div>
    </button>
    <!--全局搜索-->
    <button *ngIf="isShowSearch()" nz-button nzType="link" class="sales-menu-button" appThrottleClick
            (throttleClick)="onAllSearch()">
      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="24"
           src="assets/images/all_search.svg" style="font-size: 16px;">
    </button>
    <!--待办事项-->
    <button *ngIf="!instance.isFone()" nz-button nzType="link" class="sales-menu-button" appThrottleClick
            (throttleClick)="onToDo()">
      <nz-badge [nzCount]="workbench.unFinishCount">
        <img nz-tooltip nzTooltipPlacement="bottom"
             [nzTooltipTitle]="instance.isFone() ? ('ToDoItem' | translate) : 'Pending'" alt="" width="24"
             src="assets/images/ic_to_do.svg" style="font-size: 16px;">
      </nz-badge>
    </button>

    <!--日程-->
    <button *ngIf="workbench.schedule" nz-button nzType="link" class="sales-menu-button" appThrottleClick
            (throttleClick)="onSchedule()">
      <img nz-tooltip nzTooltipPlacement="bottom"
           [nzTooltipTitle]="'Schedule' | translate" alt="" width="24"
           src="assets/images/ic_schedule.svg" style="font-size: 16px;">
    </button>
    <button *ngIf="workbench.relation" nz-button nzType="link" class="sales-menu-button" appThrottleClick
            (throttleClick)="onContact()">
      <img nz-tooltip nzTooltipPlacement="bottom"
           [nzTooltipTitle]="'RelationshipModule' | translate" alt="" width="24"
           src="assets/images/ic_contact.svg" style="font-size: 16px;">
    </button>
    <!--计划书账号入口-->
    <button *ngIf="workbench.proposalAccount" nz-button nzType="link" class="sales-menu-button" appThrottleClick
            (throttleClick)="onProposalAccount()">
      <img nz-tooltip nzTooltipPlacement="bottom"
           [nzTooltipTitle]="'ProposalAccount' | translate" alt="" width="24"
           src="assets/images/ic_proposal.svg" style="font-size: 16px;">
    </button>
    <button nz-button nz-popover nzType="link" class="sales-menu-button"
            [nzPopoverContent]="localeTemplate"
            [(nzPopoverVisible)]="localePopoverVisible"
            nzPopoverTrigger="click"
            nzPopoverPlacement="bottom">
      <img nz-tooltip nzTooltipPlacement="bottom"
           [nzTooltipTitle]="(localeService.locale | locale)" alt="" width="24"
           src="assets/images/ic_language.svg" style="font-size: 16px;">
      <!--      <span class="sales-menu-locale-icon">-->
      <!--        {{localeService.locale | locale}}-->
      <!--      </span>-->
    </button>
    <button nz-button nzType="link" class="sales-menu-button" appThrottleClick (throttleClick)="onMessage()">
      <nz-badge [nzCount]="showDot ? null : totalUnreadMessage" [nzDot]="showDot">
        <img alt="" width="24" src="assets/images/ic_messages.svg" style="font-size: 16px;">
      </nz-badge>
    </button>

    <a nz-popover class="sales-menu-button"
       [nzPopoverContent]="profileTemplate"
       [(nzPopoverVisible)]="profilePopoverVisible"
       nzPopoverTrigger="click"
       nzPopoverPlacement="bottomRight">
      <nz-avatar style="margin-left: 20px" [nzSize]="40" nzIcon="user" [nzSrc]="workbench.avatar"></nz-avatar>
      <span class="sales-user-name" style="margin-left: 8px; margin-right: 8px">{{ instance.isFone() ? workbench.userName : showName() }}</span>
    </a>
  </div>

  <!-- 多语言气泡Template -->
  <ng-template #localeTemplate>
    <nz-list nzSize="small">
      <nz-list-item *ngFor="let value of localeService.locales" appThrottleClick (throttleClick)="switchLocale(value)">
        <div style="text-align: left"
             [ngClass]="value === this.localeService.locale ? 'sales-menu-locale sales-menu-locale-disable' : 'sales-menu-locale'">
          <span class="sales-menu-locale-icon">{{ value | locale }}</span>
          <span style="margin-left: 5px; font-size: 12px;">{{ value | locale | translate }}</span>
        </div>
      </nz-list-item>
    </nz-list>
  </ng-template>

  <!-- 用户气泡Template -->
  <ng-template #profileTemplate>
    <nz-list nzSize="small">
      <nz-list-item *ngIf="!instance.isBIBDomain()">
        <a nz-button nzType="link" nzBlock nzSize="small" appThrottleClick (throttleClick)="onSwitchAccount()">
          <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
          <span>{{ 'SwitchAccount' | translate }}</span>
        </a>
      </nz-list-item>
      <nz-list-item *ngIf="!instance.isFoneOrDev()">
        <a nz-button nzType="link" nzBlock nzSize="small" appThrottleClick (throttleClick)="onEditPwd()">
          <span>{{ 'PasswordUpdate' | translate }}</span>
        </a>
      </nz-list-item>
      <nz-list-item *ngIf="workbench.pinCodeEnable">
        <a nz-button nzType="link" nzBlock nzSize="small" appThrottleClick (throttleClick)="onEditPin()">
          <span>{{ 'PinCodeSetting' | translate }}</span>
        </a>
      </nz-list-item>
      <nz-list-item>
        <a nz-button nzType="link" nzBlock nzDanger nzSize="small" appThrottleClick (throttleClick)="onLogout()">
          <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
          <span>{{ 'Logout' | translate }}</span>
        </a>
      </nz-list-item>
      <nz-list-item *ngIf="!instance.isBIBDomain()">
        <a nz-button nzType="link" nzBlock nzDanger nzSize="small" appThrottleClick
           (throttleClick)="onLogoutAndResetOrgCode()">
          <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
          <span>{{ 'LogoutAndResetOrgCode' | translate }}</span>
        </a>
      </nz-list-item>
      <nz-list-item>
        <div style="width: 100%; text-align: center; font-size: 10px; color: #A6A6A6">v{{ version }}</div>
      </nz-list-item>
    </nz-list>
  </ng-template>

  <!-- 路由渲染结点 -->
  <div class="sales-sub-container" *ngIf="initialized">
    <router-outlet></router-outlet>
  </div>

  <nz-affix [nzOffsetBottom]="30" [nzTarget]="salesRoot" style="width: 100px;background-color: transparent;">
    <button class="sales-workbench-button" nz-button nzType="primary"
            nz-popover [nzPopoverContent]="workbenchTemplate"
            [(nzPopoverVisible)]="workbenchPopoverVisible"
            nzPopoverTrigger="click"
            nzPopoverPlacement="topLeft"
            nzSize="large" nzShape="circle">
      <img alt="" width="24" src="assets/images/ic_studio.svg">
    </button>
  </nz-affix>

  <!-- 工作台Template -->
  <ng-template #workbenchTemplate>
    <div *ngIf="workbench && workbench.features" class="sales-workbench-container">
      <div *ngFor="let feature of workbench.features;let i = index">
        <div *ngIf="feature.squares && feature.squares.length > 0">
          <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="24">
              <div class="sales-square-group">
                <span style="margin-left: 16px">{{ feature.group }}</span>
              </div>
            </div>
          </div>
          <div nz-row  *ngIf="(instance.isDev()|| instance.isWanson()) && i == 1; else falseBlock">
            <div nz-col [nzSpan]=" index < 2 ? 12 : 8" *ngFor="let module of wansonList;let index = index"
                 class="sales-row-1">
              <div [class]="moduleEnabled(module)? 'wanson-sales-square' : 'wanson-sales-square wanson-sales-square-disable'"
                   appThrottleClick (throttleClick)="onModule(module)">
                <span [class]="index < 2 ? 'sales-square-1' : 'sales-square-2'">{{ module.view }}</span>

                <img [src]="module.icon" alt="" style="width: 100%" />
                <span *ngIf="module.name === 'INVITATION'" class="title-tag">{{ 'Trial' | translate }}</span>
                <span *ngIf="module.name === 'PROPOSAL_CUSTOM' || module.name === 'PROPOSAL_COMPARE' || module.name === 'PREMIUM_FINANCING'"
                      class="title-tag">Beta</span>
              </div>
            </div>
          </div>
          <ng-template #falseBlock>
            <div nz-row [nzGutter]="16">
              <div nz-col [nzSpan]="12" *ngFor="let module of feature.squares">
                <div [class]="moduleEnabled(module) ? 'sales-square' : 'sales-square sales-square-disable'"
                     appThrottleClick (throttleClick)="onModule(module)">
                  <nz-badge [nzDot]="module.reminder">
                    <img [src]="module.icon" alt="" width="24"/>
                  </nz-badge>
                  <span style="margin-left: 16px">{{ module.view }}</span>
                  <span *ngIf="module.name === 'INVITATION'" class="title-tag">{{ 'Trial' | translate }}</span>
                  <span *ngIf="module.name === 'PROPOSAL_CUSTOM' || module.name === 'PROPOSAL_COMPARE' || module.name === 'PREMIUM_FINANCING'"
                        class="title-tag">Beta</span>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>

  <app-verify-pin *ngIf="isShowPin"
                  [mode]="pinMode"
                  [isPhone]="settingPin"
                  (verifyNotification)="showPin($event)"></app-verify-pin>

  <!-- 版本内容説明 -->
  <app-version-description [versionContent]="UpdateContent"></app-version-description>

  <nz-modal [(nzVisible)]="isVisible" [nzTitle]="'VerificationCode' | translate"
            [nzOkText]="'Confirm' | translate"
            [nzCancelText]="'Cancel' | translate"
            [nzWidth]="'380px'"
            (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
    <ng-container *nzModalContent>
      <p>{{ messageContent.content }}</p>
      <input nz-input [placeholder]="('PleaseEnter' | translate) + ('VerificationCode' | translate)"
             [(ngModel)]="proposalCode"/>
    </ng-container>
  </nz-modal>

  <app-common-search [(value)]="searchVisible"
                     (valueChange)="searchVisibleChange($event)"></app-common-search>

</div>
<ng-template #qrCodeTemplate>
  <div style="padding: 5px;">
    <p style="text-align: center">{{ 'QrApp' | translate }}</p>
    <nz-divider></nz-divider>
    <qr-code [value]="codeImgUrl" [level]="'M'" [size]="180"></qr-code>
  </div>
</ng-template>
