import { Injectable } from '@angular/core';
import { Versions } from '../api/types';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  localVersion = require('../../../package.json').version;
  versionsText: Versions = new Versions();

  versions = {
    '1.2.5': ['1. 產品選擇功能優化。', '2. 續保提醒新增業務編號搜索。'],
    '1.2.6': ['1. Dashboard新增新單數量统計。', '2. 新增管理員賬號數量上限顯示和提醒。'],
    '1.2.7': ['1. 佣金模塊新增withheld金額記錄和提醒。', '2. 預約記錄功能優化，支持批量操作(預約、簽署、回復)。', '3. 保單新增轉保中和待續費(Renewal Premium)狀態。'],
    '1.2.8': ['1. 用戶詳情簽約主體支持多選。', '2. 優化了功能菜單的自適應問題。'],
    '1.2.9': ['1. 資料雲盤權限功能優化。', '2. 佣金模塊功能體驗優化。'],
    '1.3.0': ['1. 新增介绍费计算模块', '2. 介绍费模块：保单列表样式调整', '3. PreSales模块新建编辑联系人信息字段优化调整', '4. 会面记录多语言调整', '5. 产品资料文件支持标签筛选'],
    '1.3.1': ['1. 服務咨詢關聯保單', '2. 内部文件支持標簽篩選', '3. 預約、新單、保單附件上傳查看權限', '4. 保單投保人、受保人、受益人信息字段脫敏'],
    '1.3.2': ['修復文件名中含有%號時無法下載文件的問題'],
    '1.3.3': ['1. 雲盤添加文件大小及空間使用情況', '2. 計劃書列表添加歷史狀態說明', '3. 計劃書對比製作添加封面選擇'],
    '1.3.4': ['1. 雲盤文件添加備註', '2. 資訊支持產品搜索', '3. 投保人受保人頁面與App同步', '4. 已知問題修復'],
    '1.3.5': ['1. 資訊列表置頂展示', '2. 部分問題優化調整'],
    '1.3.6': ['1. 預約列表支持分組展示，新建預約新增分組選擇', '2. 新單跟進事項優化', '3. 保費到期添加導出excel支持', '4. 公司客戶添加「是否愿意接受电邮推广宣传」字段', '5. 已知問題修復和功能優化'],
    '1.3.7': ['1. 計劃書對比險種限制：重疾和儲蓄', '2. FNA國籍選擇優化', '3. 保單模板同步修改', '4. 保費試算必填項根據不同產品動態限制', '5. 已知問題修復和功能優化'],
    '1.3.8': ['1. 保費到期添加我團隊的和我跟進的', '2. 預約詳情添加健康問卷', '3. 預約詳情添加關聯FNA功能'],
    '1.3.9': ['1. 保費試算展示數據版本信息', '2. 客戶記錄添加產品選擇', '3. 預約詳情VIP服務提供更多字段填寫', '4. 智能合約添加備註', '5. 新增消息中心設置功能', '6. 預售客戶添加更多信息填寫', '7. FNA功能優化'],
    '1.4.0': ['1. 產品選擇頁面添加公司列', '2. 資訊列表添加產品列，不再與標籤列混合', '3. 提交預約後添加引導彈窗', '4. 產品對比頁面調整', '5. 跟進事項添加備註', '6. 消息中心角標樣式修復', '7. 預約開戶行信息添加開戶行支行'],
    '1.4.1': ['1. 预约智能合并提示', '2. 预约导入问题修复', '3. 知識庫添加熱門產品頁面', '4. 新單保單事項優化調整', '5. 已知問題修復和功能優化'],
    '1.4.2': ['1. 新增產品對比歷史', '2. 預約新單保單留言添加確認功能', '3. 分組預約支持批量回覆和批量上傳附件', '4. 保費試算功能優化', '5. 已知問題修復和功能優化'],
    '1.4.3': ['1. 產品對比歷史添加编辑功能', '2. 资料云盘问题修复', '3. 預約、新單、保單紅點提示優化', '4. 已知問題修復調整和功能優化'],
    '1.4.4': ['1. 新增账号切换功能', '2. 新增App二维码下载', '3. 新增预约导出功能', '4. 已知問題修復調整和功能優化'],
    '1.4.5': ['1. 消息已讀優化', '2. 健康問卷支持是否必填', '3. 服務諮詢支持富文本，新增內容搜索', '4. 預約添加轉保聲明', '5. 多語言完善'],
    '1.4.6': ['1. 關係模塊我的下線增加字段展示', '2. FNA創建支持選擇用戶', '3. 預約創建支持選擇銷售渠道', '4. 手機號碼增加檢驗', '5. 轉保聲明升級', '6. 已知問題修復和功能優化'],
    '1.4.7': ['1. 上級支持修改下級FNA用戶', '2. 預約開戶時間格式問題修復', '3. 新單跟進事項截止日期展示問題修復', '4. 已知問題修復和功能優化'],
    '1.4.8': ['1. 提取计划默认允许将减少基本金额', '2. 預約新單保單附件支持按類型分組', '3. 保單搜索項調整', '4. 已知問題修復和功能優化'],
    '1.4.9': ['1. 預約開戶時間變更為時間段', '2. 添加計劃書製作驗證碼提交流程', '3. 服務諮詢新增問題編號搜索', '4. 已知問題修復和功能優化'],
    '1.5.0': ['1. 產品對比保費試算', '2. 計劃書提取功能優化', '3. 已知問題修復和功能優化'],
    '1.5.1': ['1. 添加帳號密碼功能', '2. 新建聯繫人支持從預約保單中導入', '3. 保費試算佈局優化', '4. 已知問題修復和功能優化'],
    '1.5.2': ['1. 添加佣金估算模塊', '2. 新单跟进、保单服务支持显示头像', '3. 智能合約模塊優化', '4. 已知問題修復和功能優化'],
    '1.5.3': ['1. 客戶信息添加吸煙量', '2. 添加留言代辦', '3. 佣金估計添加與我分佣页面', '4. 介紹費附件優化', '5. 资讯模块筛选新增「保险公司地区」筛选项', '6. 已知問題修復和功能優化'],
    '1.5.4': ['1. 跟进事项、待办事项支持富文本', '2. 保单详情Policy Delivery新增保单寄送状态', '3. FNA文档查看权限', '4. 已知問題修復和功能優化'],
    '1.5.5': ['1. 登錄頁面記住用戶', '2. 計劃書保費扣除', '3. 智能合約優化', '4. FNA關聯新單保單', '5，设备信息优化'],
    '1.5.6': ['1. 手機驗證碼登錄', '2. 計劃書產品選擇優化', '3. 預約刪除提示優化', '4. 其他已知問題修復和功能優化'],
    '1.5.7': ['已知問題修復和功能優化'],
    '1.5.8': ['1. 新單、保單狀態列表調整', '2. 計劃書申請添加預繳字段', '3. 預約產品搜索優化', '4. 其他已知問題修復和功能優化'],
    '1.5.9': ['1. 新增PIN碼驗證', '2. 建議書模板支持公司及產品名稱加星號選項', '3. 介紹費計算優化', '4. 其他已知問題修復和功能優化'],
    '1.6.0': ['已知問題修復和功能優化'],
    '1.6.1': ['1. 关系模块我的人脉&我的下属支持搜索', '2. 计划书对比选择计划书列表时展示所有支持对比的计划书（包括异常的计划书）', '3. 介绍费模块优化', '4. 其他已知問題修復和功能優化'],
    '1.6.2': ['1. 介紹費模塊優化', '2. 預約同FNA信息聯動修改', '3. 新增韓文翻譯', '4. 其他已知問題修復和功能優化'],
    '1.6.3': ['已知問題修復和功能優化'],
    '1.6.4': ['已知問題修復和功能優化'],
    '1.6.5': ['已知問題修復和功能優化'],
    '1.6.6': ['已知問題修復和功能優化'],
    '1.6.7': ['已知問題修復和功能優化'],
    '1.6.8': ['已知問題修復和功能優化'],
    '1.6.9': ['已知問題修復和功能優化'],
    '1.7.0': ['已知問題修復和功能優化'],
    '1.7.1': ['已知問題修復和功能優化'],
    '1.7.2': [ '已知問題修復和功能優化'],
    '1.7.3': [ '已知問題修復和功能優化'],
    '1.7.4': [ '已知問題修復和功能優化'],
    '1.7.5': [ '已知問題修復和功能優化'],
    '1.7.6': [ '已知問題修復和功能優化'],
    '1.7.7': [ '已知問題修復和功能優化'],
    '1.7.8': [ '已知問題修復和功能優化'],
    '1.7.9': [ '已知問題修復和功能優化'],
    '1.8.0': [ '已知問題修復和功能優化'],
    '1.8.1': [ '已知問題修復和功能優化'],
    '1.8.2': [ '已知問題修復和功能優化'],
    '1.8.3': [ '已知問題修復和功能優化'],
    '1.8.4': [ '已知問題修復和功能優化'],
    '1.8.5': [ '已知問題修復和功能優化'],
  };

  constructor() {
  }

  VersionComparison(): any {
    let storeVersion: string;
    storeVersion = localStorage.getItem('LIFEBEE-SALES-VERSION');
    this.versionsText.version = this.localVersion;
    if (!storeVersion) {
      console.log('當前使用版本', `記錄版本號${storeVersion}`);
      this.versionsText.flag = true;
      this.versionsText.text = this.versions[this.localVersion];
      return this.versionsText;
    }
    if (this.localVersion === storeVersion) {
      console.log('版本無差異', `記錄版本號${storeVersion}`);
      this.versionsText.flag = false;
      this.versionsText.text = this.versions[this.localVersion];
      return this.versionsText;
    }
    if (this.localVersion > storeVersion) {
      console.log('已更新最新版本', `記錄版本號${storeVersion}`);
      this.versionsText.flag = true;
      this.versionsText.text = this.versions[this.localVersion];
      return this.versionsText;
    }
  }
}
