import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import {
  CoverClass, CoverQuery,
  CreateProposalCompare,
  ProposalList,
  SelectProposalList
} from '../proposal-compare';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { ProductService } from '../../product/product.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ProposalCompareSelectedComponent } from '../proposal-compare-selected/proposal-compare-selected.component';
import { ProposalCompareService } from '../proposal-compare.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ProposalCoverComponent } from '../proposal-cover/proposal-cover.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Instance } from '../../api/types';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-proposal-compare-detail',
  templateUrl: './proposal-compare-select-detail.component.html',
  styleUrls: ['./proposal-compare-select-detail.component.less']
})
export class ProposalCompareSelectDetailComponent implements OnInit {
  loading = false;
  createProposalCompare: CreateProposalCompare = new CreateProposalCompare();
  categoryOptions: PropertySelectOption[] = [];
  paymentTermOptions: PropertySelectOption[] = [];
  listArr: ProposalList[] = [];
  coverClass: CoverClass = new CoverClass();
  valueDemonstrationYear = [0, 100];
  templateText: string;
  @Output()
  proposalCompareSave: EventEmitter<any> = new EventEmitter<any>();
  instance: Instance;

  constructor(private productService: ProductService,
              private proposalCompareService: ProposalCompareService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private workbenchService: WorkbenchService,
              private modalService: NzModalService,
  ) {
  }

  get canInspect(): boolean {
    if (this.loading) {
      return false;
    }

    if (!this.createProposalCompare.categoryCode) {
      return false;
    }
    if (!this.createProposalCompare.name) {
      return false;
    }
    if (!this.createProposalCompare.proposalIds) {
      return false;
    }
    if (!this.createProposalCompare.proposalIds.length) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.instance = this.workbenchService.mockInstance;
    this.loading = true;
    this.proposalCompareService.categories()
      .subscribe(
        data => {
          this.loading = false;
          data.forEach(category => {
            this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
          });
        },
        error => {
          this.loading = false;
        });
    this.paymentTermOptions.push(new PropertySelectOption(this.translate.instant('SinglePayment'), true));
    this.paymentTermOptions.push(new PropertySelectOption(this.translate.instant('MultiPayment'), false));
  }

  onSelect(): void {
    const search: SelectProposalList = new SelectProposalList();
    search.categoryCode = this.createProposalCompare.categoryCode;
    search.singlePayment = this.createProposalCompare.singlePayment;
    this.proposalCompareService.selectList(search).subscribe(listArr => {
      const drawerRef = this.drawerService.create<ProposalCompareSelectedComponent, {}, string>({
        nzWidth: 800,
        nzContent: ProposalCompareSelectedComponent,
        nzContentParams: {
          search,
          listArr,
          createProposalCompare: this.createProposalCompare,
        }
      });

      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
        component.proposals.subscribe(data => {
          console.log(data);
          const proposalIds = [];
          data.forEach(e => {
            proposalIds.push(e);
          });
          this.createProposalCompare.proposalIds = proposalIds;
          console.log(this.createProposalCompare.proposalIds);
        });
        component.proposalCompareSaved.subscribe(
          data => {
            drawerRef.close();
            if (data && data.length) {
              this.listArr = data;
            }
          }
        );
      });

      drawerRef.afterClose.subscribe(data => {
        const component = drawerRef.getContentComponent();
        component.onClose();
      });
    }, error => {
      console.log(error);
    });

  }

  onSave(tplContent: TemplateRef<{}>): void {
    if (!this.canInspect) {
      return;
    }
    if (this.listArr.some(item => typeof item.extracted === 'boolean' && !item.extracted)) {
      const resArr = [];
      this.listArr.map(item => {
        if (typeof item.extracted === 'boolean' && !item.extracted) {
          resArr.push(`#${item.proposalId}`);
        }
      });
      this.templateText = resArr.join('、');
      this.translate.get(['ReuseTips', 'Cancel', 'Confirm']).subscribe(
        (res: any) => {
          // this.toastr.warning(res.PleaseEnter + res.Cancel + res.Confirm  );
          this.modalService.create({
            nzCentered: true,
            nzTitle: null,
            nzContent: tplContent,
            nzClosable: false,
            nzWidth: 400,
            nzOkText: res.Confirm,
            nzOkType: 'primary',
            nzIconType: '',
            nzOnOk: () => this.onConfirm(),
            nzCancelText: null,
            nzOnCancel: () => console.log('Cancel')
          });
        });
    } else {
      this.onConfirm();
    }
  }

  onConfirm(): void {
    this.createProposalCompare.valueDemonstrationYear.from = this.valueDemonstrationYear[0];
    this.createProposalCompare.valueDemonstrationYear.to = this.valueDemonstrationYear[1];
    this.createProposalCompare.asterisk = this.workbenchService.workbench.proposalCompareMask;
    this.proposalCompareService.add(this.createProposalCompare).subscribe(data => {
      console.log(data);
      this.proposalCompareSave.emit();
    }, error => {
    });
  }

  onDelete(data: ProposalList, i: number): void {
    this.listArr.splice(i, 1);
    this.createProposalCompare.proposalIds.forEach((e, index) => {
      if (e === data.proposalId) {
        this.createProposalCompare.proposalIds.splice(index, 1);
      }
    });
  }

  onCoverSelection(): void {
    if (!this.createProposalCompare.categoryCode) {
      this.translate.get('CategoryCodeRequired').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
    } else {
      const drawerRef = this.drawerService.create<ProposalCoverComponent, { value: string }, string>({
        nzWidth: 600,
        nzContent: ProposalCoverComponent,
        nzContentParams: {
          categoryCode: this.createProposalCompare.categoryCode
        }
      });
      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
        component.confirmItem.subscribe(
          data => {
            this.createProposalCompare.cover = data.url;
            this.coverClass = data;
            drawerRef.close();
          });
      });
    }
  }
}
