import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { PageHeaderComponent } from './component/page-header/page-header.component';
import { LocalePipe } from './pipes/locale.pipe';
import { ProposalStatusPipe } from './pipes/proposal-status.pipe';
import { ProductSelectionComponent } from './component/product-selection/product-selection.component';
import { PropertySelectComponent } from './component/property-select/property-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { PropertyPickComponent } from './component/property-pick/property-pick.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { PropertyInputComponent } from './component/property-input/property-input.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PropertyDatetimeComponent } from './component/property-datetime/property-datetime.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { PropertyInputNumberComponent } from './component/property-input-number/property-input-number.component';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { PropertyInputAreaComponent } from './component/property-input-area/property-input-area.component';
import { CustomerEditComponent } from './component/customer-edit/customer-edit.component';
import { CustomerInfoComponent } from './component/customer-info/customer-info.component';
import { BeneficiariesEditComponent } from './component/beneficiaries-edit/beneficiaries-edit.component';
import { BeneficiariesInfoComponent } from './component/beneficiaries-info/beneficiaries-info.component';
import { PropertyPhoneComponent } from './component/property-phone/property-phone.component';
import { AddressEditComponent } from './component/address-edit/address-edit.component';
import { PhoneEditComponent } from './component/phone-edit/phone-edit.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ContactSelectionComponent } from './component/contact-selection/contact-selection.component';
import { BeneficiaryEditComponent } from './component/beneficiary-edit/beneficiary-edit.component';
import { BeneficiaryInfoComponent } from './component/beneficiary-info/beneficiary-info.component';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { PropertyTextComponent } from './component/property-text/property-text.component';
import { PropertyTextsComponent } from './component/property-texts/property-texts.component';
import { PropertySwitchComponent } from './component/property-switch/property-switch.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { PropertyYesOrNotComponent } from './component/property-yes-or-not/property-yes-or-not.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { MetadataTranslatePipe } from './pipes/metadata-translate.pipe';
import { AttachmentsComponent } from './component/attachments/attachments.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { ProgressBarComponent } from './component/progress-bar/progress-bar.component';
import { ReadMoreComponent } from './component/read-more/read-more.component';
import { PropertyTagsComponent } from './component/property-tags/property-tags.component';
import { PropertyDatetimeRangeComponent } from './component/property-datetime-range/property-datetime-range.component';
import { TicketStatusPipe } from './pipes/ticket-status.pipe';
import { HtmlPipe } from './pipes/html.pipe';
import { TimeLineComponent } from './component/time-line/time-line.component';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { CustomerReadComponent } from './component/customer-read/customer-read.component';
import { BeneficiariesReadComponent } from './component/beneficiaries-read/beneficiaries-read.component';
import { BankDetailComponent } from './component/bank-detail/bank-detail.component';
import { HealthInfoComponent } from './component/health-info/health-info.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ProductRidersEditComponent } from './component/product-riders-edit/product-riders-edit.component';
import { PropertyInputCurrencyComponent } from './component/property-input-currency/property-input-currency.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { MessageDialogComponent } from './component/message-dialog/message-dialog.component';
import { ProductRiderEditComponent } from './component/product-rider-edit/product-rider-edit.component';
import { SignaturePadComponent } from './component/signature-pad/signature-pad.component';
import { YearsOrMonthOrAgePipe } from './pipes/years-or-month-or-age.pipe';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { ThrottleClickDirective } from './directive/throttle-click.directive';
import { LinkComponent } from './component/link/link.component';
import { BottomSheetModule } from './modules/bottom-sheet/bottom-sheet.module';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ProductMultiSelectionComponent } from './component/product-multi-selection/product-multi-selection.component';
import { ContactsSelectionComponent } from './component/contacts-selection/contacts-selection.component';
import { EmptyComponent } from './component/empty/empty.component';
import { PropertyEditorComponent } from './component/property-editor/property-editor.component';
import { PhonesEditComponent } from './component/phones-edit/phones-edit.component';
import { YearsConversionPipe } from './pipes/years-conversion.pipe';
import { QrcodeDrawerComponent } from './component/qrcode-drawer/qrcode-drawer.component';
import { QRCodeModule } from 'angular2-qrcode';
import { ContactsListComponent } from './component/contacts-list/contacts-list.component';
import { DomainMetadataReadComponent } from './component/domain-metadata-read/domain-metadata-read.component';
import { DomainMetadataEditComponent } from './component/domain-metadata-edit/domain-metadata-edit.component';
import { VersionDescriptionComponent } from './component/version-description/version-description.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { PolicySelectionComponent } from './component/policy-selection/policy-selection.component';
import { SecondHolderEditComponent } from './component/second-holder-edit/second-holder-edit.component';
import { SecondHolderReadComponent } from './component/second-holder-read/second-holder-read.component';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { RegionSelectionComponent } from './component/region-selection/region-selection.component';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { RouterModule } from '@angular/router';
import { BusinessFormReadComponent } from './component/business-form-read/business-form-read.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormSignatureComponent } from './component/form-signature/form-signature.component';
import { PropertyDatetimeStringComponent } from './component/property-datetime-string/property-datetime-string.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { ProductListItemComponent } from './component/product-list-item/product-list-item.component';
import { CommonInputEditComponent } from './component/common-input-edit/common-input-edit.component';
import { QuillModule } from 'ngx-quill';
import { BookingBatchComponent } from './component/booking-batch/booking-batch.component';
import { UpdateAvailableComponent } from './component/update-available/update-available.component';
import { ReinsuranceComponent } from './component/reinsurance/reinsurance.component';
import { SubSalesChannelComponent } from './component/sub-sales-channel/sub-sales-channel.component';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ImportBookingInfoComponent } from './component/import-booking-info/import-booking-info.component';
import { ImportPolicyInfoComponent } from './component/import-policy-info/import-policy-info.component';
import { WaterMarkComponent } from './component/water-mark/water-mark.component';
import { VerifyPinComponent } from './component/verify-pin/verify-pin.component';
import { I18nPipe } from './pipes/i18n.pipe';
import { BookingCustomerReadComponent } from './component/booking-customer-read/booking-customer-read.component';
import { PropertyGenderComponent } from './component/property-gender/property-gender.component';
import { CommonSearchComponent } from './component/common-search/common-search.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ImportUnderwritingInfoComponent } from './component/import-underwriting-info/import-underwriting-info.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    PageHeaderComponent,
    LocalePipe,
    ProposalStatusPipe,
    MetadataTranslatePipe,
    ProductSelectionComponent,
    PropertySelectComponent,
    PropertyPickComponent,
    PropertyInputComponent,
    PropertyDatetimeComponent,
    PropertyDatetimeRangeComponent,
    PropertyInputNumberComponent,
    PropertyInputAreaComponent,
    CustomerEditComponent,
    CustomerInfoComponent,
    BeneficiariesEditComponent,
    BeneficiariesInfoComponent,
    PropertyPhoneComponent,
    AddressEditComponent,
    PhoneEditComponent,
    ContactSelectionComponent,
    BeneficiaryEditComponent,
    BeneficiaryInfoComponent,
    PropertyTextComponent,
    PropertyTextsComponent,
    PropertySwitchComponent,
    PropertyYesOrNotComponent,
    AttachmentsComponent,
    ProgressBarComponent,
    ReadMoreComponent,
    PropertyTagsComponent,
    TicketStatusPipe,
    HtmlPipe,
    ProductRidersEditComponent,
    PropertyInputCurrencyComponent,
    TimeLineComponent,
    CustomerReadComponent,
    BeneficiariesReadComponent,
    BankDetailComponent,
    HealthInfoComponent,
    MessageDialogComponent,
    ProductRiderEditComponent,
    YearsOrMonthOrAgePipe,
    SignaturePadComponent,
    ThrottleClickDirective,
    LinkComponent,
    EllipsisPipe,
    ProductMultiSelectionComponent,
    ContactsSelectionComponent,
    EmptyComponent,
    PropertyEditorComponent,
    PhonesEditComponent,
    YearsConversionPipe,
    QrcodeDrawerComponent,
    ContactsListComponent,
    DomainMetadataReadComponent,
    DomainMetadataEditComponent,
    VersionDescriptionComponent,
    PolicySelectionComponent,
    SecondHolderEditComponent,
    SecondHolderReadComponent,
    RegionSelectionComponent,
    BusinessFormReadComponent,
    FormSignatureComponent,
    PropertyDatetimeStringComponent,
    ProductListItemComponent,
    CommonInputEditComponent,
    BookingBatchComponent,
    UpdateAvailableComponent,
    ReinsuranceComponent,
    SubSalesChannelComponent,
    ImportBookingInfoComponent,
    ImportPolicyInfoComponent,
    WaterMarkComponent,
    VerifyPinComponent,
    I18nPipe,
    BookingCustomerReadComponent,
    PropertyGenderComponent,
    CommonSearchComponent,
    ImportUnderwritingInfoComponent
  ],
  exports: [
    PageNotFoundComponent,
    PageHeaderComponent,
    LocalePipe,
    ProposalStatusPipe,
    MetadataTranslatePipe,
    ProductSelectionComponent,
    PropertySelectComponent,
    PropertyPickComponent,
    PropertyInputComponent,
    PropertyInputNumberComponent,
    PropertyDatetimeComponent,
    PropertyDatetimeRangeComponent,
    PropertyInputAreaComponent,
    PropertyPhoneComponent,
    PhoneEditComponent,
    PropertyTextComponent,
    PropertyTextsComponent,
    PropertySwitchComponent,
    PropertyYesOrNotComponent,
    AttachmentsComponent,
    ProgressBarComponent,
    ReadMoreComponent,
    PropertyTagsComponent,
    TicketStatusPipe,
    HtmlPipe,
    PropertyInputCurrencyComponent,
    TimeLineComponent,
    ProductRidersEditComponent,
    YearsOrMonthOrAgePipe,
    SignaturePadComponent,
    ThrottleClickDirective,
    LinkComponent,
    EllipsisPipe,
    EmptyComponent,
    MessageDialogComponent,
    YearsConversionPipe,
    VersionDescriptionComponent,
    PropertyDatetimeStringComponent,
    ProductListItemComponent,
    PropertyEditorComponent,
    WaterMarkComponent,
    VerifyPinComponent,
    I18nPipe,
    PropertyGenderComponent,
    CommonSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzSelectModule,
    NzSpinModule,
    NzIconModule,
    NzToolTipModule,
    NzTableModule,
    NzButtonModule,
    NzSkeletonModule,
    NzGridModule,
    NzInputModule,
    NzDatePickerModule,
    NzInputNumberModule,
    NzTagModule,
    NzTimePickerModule,
    NzSwitchModule,
    NzRadioModule,
    FileUploadModule,
    NzEmptyModule,
    NzProgressModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    NzSelectModule,
    NzSpinModule,
    NzIconModule,
    NzToolTipModule,
    NzTableModule,
    NzButtonModule,
    TranslateModule,
    NzSkeletonModule,
    NzGridModule,
    NzInputModule,
    NzDatePickerModule,
    NzInputNumberModule,
    NzTagModule,
    NzTimePickerModule,
    NzSwitchModule,
    NzRadioModule,
    FileUploadModule,
    NzEmptyModule,
    NzProgressModule,
    NzTimelineModule,
    NzDividerModule,
    NzDrawerModule,
    NzFormModule,
    NzAvatarModule,
    NzAlertModule,
    BottomSheetModule,
    QRCodeModule,
    NzModalModule,
    NzAffixModule,
    NzAnchorModule,
    RouterModule,
    NzCheckboxModule,
    NzPopoverModule,
    NzBadgeModule,
    QuillModule,
    NzCascaderModule,
    NzCollapseModule,
    NzTabsModule,
  ],
})
export class SharedModule {
}
