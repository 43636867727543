import {Component, OnInit} from '@angular/core';
import {ContractDetails, ContractList, ContractSearch} from '../../contract/contract-type';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {ContractService} from '../../contract/contract.service';
import {MetadataService} from '../../shared/service/metadata.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {formatDate} from '@angular/common';
import {ContractDetailComponent} from '../../contract/contract-detail/contract-detail.component';
import {ToDoService} from '../to-do.service';
import { UnderwritingListRes, UnderwritingToDoRes } from '../to-do-types';
import {EventRecordComponent} from '../components/event-record/event-record.component';
import {UnderwritingService} from '../../underwriting/underwriting.service';
import { PendingReply, PendingDetail, Instance, DataMode } from '../../api/types';
import { WorkbenchService } from '../../workbench/workbench.service';
import { ListRes } from '../../proposal-custom/proposal-custom-types';

@Component({
  selector: 'app-underwriting-to-do',
  templateUrl: './underwriting-to-do.component.html',
  styleUrls: ['./underwriting-to-do.component.less']
})
export class UnderwritingToDoComponent implements OnInit {
  spinning = false;
  loading = false;
  toDoList: UnderwritingListRes[] = [];
  currentPageData: UnderwritingListRes[] = [];
  currentPage = 1;
  mode: DataMode.OWN = DataMode.OWN;

  pendingDetail: PendingDetail = new PendingDetail();
  pendingReply: PendingReply[] = [];
  instance: Instance = new Instance();

  constructor(private contractService: ContractService,
              private metadataService: MetadataService,
              private workbenchService: WorkbenchService,
              private toDoService: ToDoService,
              private drawerService: NzDrawerService,
              private underwritingService: UnderwritingService) {
  }

  ngOnInit(): void {
    this.onReload();
    this.instance = this.workbenchService.mockInstance;
  }

  onReload(): void {
    this.loading = true;
    this.currentPage = 1;
    this.toDoService.underwritingPendingV4(this.mode)
      .subscribe(data => {
          this.toDoList = data;
          this.onSearch(this.currentPage);
          this.loading = false;
        }, error => {
          this.loading = false;
        }
      );
  }


  onDetail(list): void {
    this.onPendingDetail(list.id).then( res => {
      this.underwritingService.pendingReply(list.id)
        .subscribe(
          pendReply => {
            this.pendingReply = pendReply;
            if (this.pendingReply && this.pendingReply.length) {
              this.pendingReply.map(item => item.content = item.content ? item.content : '');
            }
            const drawerRef = this.drawerService.create<EventRecordComponent, { value: ContractDetails }, string>({
              nzWidth: 800,
              nzContent: EventRecordComponent,
              nzContentParams: {
                pendingDetail: res,
                basicInfo: list,
                pendingReply: this.pendingReply,
                type: 'underwriting'
              }
            });
            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
              // component.underwritingSearch
              //   .subscribe(
              //     search => {
              //       this.search = search;
              //       this.onSearch(this.search.pageNum);
              //       drawerRef.close();
              //     }
              //   );
            });
            drawerRef.afterClose.subscribe(data => {
              if (typeof data === 'string') {
              }
            });
          });
    }).catch(err => {
      console.log(err);
    });
  }

  onPendingDetail(id: number): Promise<PendingDetail> {
    return new Promise((resolve, reject) => {
      this.underwritingService.pendingDetail(id)
        .subscribe(
          pendDetail => {
            resolve(pendDetail);
          },
          error => {
            reject(error);
          });
    });
  }

  onShowExpand(val: UnderwritingListRes): boolean {
    return val.expand = !val.expand;
  }

  onSearch(page: number): void {
    console.log(this.currentPage);
    this.currentPage = page;
    const startIndex = (this.currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    this.currentPageData = this.toDoList.slice(startIndex, endIndex);
  }
}
