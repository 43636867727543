import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import {AmlRecordType, BankInfo, Beneficiary} from '../../../api/types';

@Component({
  selector: 'app-bank-detail',
  templateUrl: './bank-detail.component.html',
  styleUrls: ['./bank-detail.component.less']
})
export class BankDetailComponent implements OnInit {
  @Input()
  bankInfo: BankInfo;
  edit: boolean;
  @Output()
  bankSaved: EventEmitter<BankInfo> = new EventEmitter<BankInfo>();

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.bankInfo);
    // if (!this.bankInfo) {
    //   this.bankInfo = new BankInfo();
    // }
  }

// 表单验证
  get canInspect(): boolean {
    if (!this.bankInfo.name) {
      return false;
    }
    if (!this.bankInfo.code) {
      return false;
    }
    if (!this.bankInfo.ownerIdCard) {
      return false;
    }
    if (!this.bankInfo.cardNo) {
      return false;
    }
    if (!this.bankInfo.ownerName) {
      return false;
    }
    if (!this.bankInfo.remark) {
      return false;
    }

    return true;
  }

  onSave(): void {
    if (this.canInspect) {
      this.bankSaved.emit(this.bankInfo);
    }
  }
}
