import { Component, OnInit } from '@angular/core';
import { PagedResp } from '../../api/types';
import { Product, ProductCategory, ProductCompany, ProductListResp, ProductSearch } from '../product-types';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../product.service';
import { ToastrService } from 'ngx-toastr';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.less']
})
export class ProductsComponent implements OnInit {

  spinning = false;
  loading = false;
  products: PagedResp<ProductListResp>;
  search: ProductSearch = new ProductSearch();
  filterPopoverVisible = false;

  categories: ProductCategory[];
  companies: ProductCompany[];

  regionOptions: PropertySelectOption[] = [];
  categoryOptions: PropertySelectOption[] = [];
  companyOptions: PropertySelectOption[] = [];
  typeOptions: PropertySelectOption[] = [];

  constructor(
    private productService: ProductService,
    private router: Router,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    private workbenchService: WorkbenchService,
    private metadataService: MetadataService,
    private translate: TranslateService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {

    const regions = this.metadataService.values('productCompanyRegion');
    for (const region of regions) {
      this.regionOptions.push(new PropertySelectOption(region.value, region.key));
    }

    this.productService.categories()
      .subscribe(
        data => {
          this.categories = data;
          data.forEach(category => {
            this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
          });
        },
        error => {
        });

    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });

        },
        error => {
        });

    const types = this.metadataService.values('productType');
    for (const type of types) {
      this.typeOptions.push(new PropertySelectOption(type.value, type.key));
    }

    this.loading = true;
    this.productService.page(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.products = data;
        },
        error => {
          this.loading = false;
        });

    this.workbenchService.switchLocaleReload
      .subscribe(locale => {
        this.load();
      });

  }

  load(): void {
    this.loading = true;
    this.productService.page(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.products = data;
        },
        error => {
          this.loading = false;
        });
  }


  onReload(resetPageNum = true): void {
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.load();
  }

  onSearch(pageNum: number): void {
    this.filterPopoverVisible = false;
    this.search.pageNum = pageNum;
    this.load();
  }

  onResetSearch(): void {
    this.filterPopoverVisible = false;
    this.search = new ProductSearch();
    this.onReload();
  }

  onProductDetail(productCode: string): void {
    this.spinning = true;
    this.productService.detail(productCode)
      .subscribe(
        product => {

          this.spinning = false;
          console.log(product, 'product');

          const drawerRef = this.drawerService.create<ProductDetailComponent, { value: Product }, string>({
            nzWidth: 800,
            nzContent: ProductDetailComponent,
            nzContentParams: {
              product
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.spinning = false;
        });
  }


}
