import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerEditMode } from '../../shared';
import {
  CustomerCorporate,
  Customer,
  CustomerType,
  Phone,
  SmokeCondition,
  Relationship, Instance, MarriageStatus
} from '../../../api/types';
import { MetadataService } from '../../service/metadata.service';
import { PropertySelectOption } from '../property-select/property-select.component';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { AddressEditComponent } from '../address-edit/address-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { Addresses } from '../../utils/addresses';
import { Phones } from '../../utils/phones';
import { PhoneEditComponent } from '../phone-edit/phone-edit.component';
import { PhonesEditComponent } from '../phones-edit/phones-edit.component';
import { ContactsListComponent } from '../contacts-list/contacts-list.component';
import { plainToInstance } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { RegionSelectionComponent } from '../region-selection/region-selection.component';
import { ContactService } from '../../../contact/contact.service';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { formatDate } from '@angular/common';
import { ProductService } from '../../../product/product.service';
import { Booking } from '../../../booking/booking-types';

export class CustomerEntity {
  customerType: CustomerType; // 投保人/持有人类型:INDIVIDUAL-个人;CORPORATE-公司
  customer: Customer; // 个人客户 - 投保人/持有人
  corporateCustomer: CustomerCorporate; // 企业客户 - 投保人/受保人
  relationship: Relationship; // 与投保人关系
}

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.less']
})
export class CustomerEditComponent implements OnInit, AfterViewInit {
  drawerRef: NzDrawerRef<CustomerEditComponent, string>;
  @Input()
  booking: Booking;

  @Input()
  mode: CustomerEditMode;
  @Input()
  modeType: string;
  @Input()
  isCreate: boolean; // 是否创建
  title: string;
  customerType: CustomerType = CustomerType.INDIVIDUAL;
  customer: Customer = new Customer(); // 个人客户 - 投保人/持有人
  customerCorporate: CustomerCorporate = new CustomerCorporate(); // 企业客户 - 投保人/受保人
  relationship: Relationship; // 与投保人关系
  isOpenBankAccountRequired = false; // 是否开户必填字段确认

  // 快捷地址和电话其他数据源
  otherCustomer: Customer;
  otherCustomerCorporate: CustomerCorporate;
  birthdayDate: number;
  workbench: Workbench;
  showBtn = false;

  businessSourceOptions: PropertySelectOption[] = [];
  customerTypeOptions: PropertySelectOption[] = [];
  relationshipOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];
  jobOptions: PropertySelectOption[] = [];
  riskAppetiteOptions: PropertySelectOption[] = [];
  sportsOptions: PropertySelectOption[] = [];
  gamesOptions: PropertySelectOption[] = [];
  titleOptions: PropertySelectOption[] = [];
  movieOptions: PropertySelectOption[] = [];
  cuisineOptions: PropertySelectOption[] = [];
  smokeOptions: PropertySelectOption[] = [];
  permanentResidentHKOptions: PropertySelectOption[] = [];
  regionOptions: PropertySelectOption[] = [];
  marriageOptions: PropertySelectOption[] = [];
  educationOptions: PropertySelectOption[] = [];
  longtimeAbroadOptions: PropertySelectOption[] = [];
  optOutRequestOptions: PropertySelectOption[] = [];
  registrationCountryOptions: PropertySelectOption[] = [];
  instance: Instance;
  isInputName = false;

  @Output()
  customerSaved: EventEmitter<CustomerEntity> = new EventEmitter<CustomerEntity>();

  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private toastr: ToastrService,
              private productService: ProductService,
              private workbenchService: WorkbenchService,
              private contactService: ContactService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;

    this.instance = this.metadataService.mockInstance;
    this.isDefaultFill();
    if (this.instance.isFone()) {
      const businessSource = this.metadataService.values('businessSource');
      for (const source of businessSource) {
        this.businessSourceOptions.push(new PropertySelectOption(source.value, source.key));
      }
    } else {
      this.getBusinessSourceList();
    }

    const customerTypes = this.metadataService.values('ownerType');
    for (const customerType of customerTypes) {
      this.customerTypeOptions.push(new PropertySelectOption(customerType.value, customerType.key));
    }
    if (this.modeType === 'booking') {
      const relationships = this.metadataService.values('bookingRelationship');
      for (const relationship of relationships) {
        this.relationshipOptions.push(new PropertySelectOption(relationship.value, relationship.key));
      }
    } else {
      const relationships = this.metadataService.values('relationship');
      for (const relationship of relationships) {
        this.relationshipOptions.push(new PropertySelectOption(relationship.value, relationship.key));
      }
    }

    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }

    const jobs = this.metadataService.values('careerInformation');
    for (const job of jobs) {
      this.jobOptions.push(new PropertySelectOption(job.value, job.key));
    }

    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'S'), SmokeCondition.S));
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'NS'), SmokeCondition.NS));

    const riskAppetites = this.metadataService.values('riskAppetite');
    for (const riskAppetite of riskAppetites) {
      this.riskAppetiteOptions.push(new PropertySelectOption(riskAppetite.value, riskAppetite.key));
    }

    const sports = this.metadataService.values('sports');
    for (const sport of sports) {
      this.sportsOptions.push(new PropertySelectOption(sport.value, sport.key));
    }

    const games = this.metadataService.values('games');
    for (const game of games) {
      this.gamesOptions.push(new PropertySelectOption(game.value, game.key));
    }

    const titles = this.metadataService.values('title');
    for (const title of titles) {
      this.titleOptions.push(new PropertySelectOption(title.value, title.key));
    }

    const movies = this.metadataService.values('movie');
    for (const movie of movies) {
      this.movieOptions.push(new PropertySelectOption(movie.value, movie.key));
    }

    const cuisines = this.metadataService.values('cuisine');
    for (const cuisine of cuisines) {
      this.cuisineOptions.push(new PropertySelectOption(cuisine.value, cuisine.key));
    }

    const regions = this.metadataService.values('residenceRegion');
    for (const region of regions) {
      this.regionOptions.push(new PropertySelectOption(region.value, region.key));
    }

    const marriageStatus = this.metadataService.values('marriageStatus');
    for (const marriage of marriageStatus) {
      this.marriageOptions.push(new PropertySelectOption(marriage.value, marriage.key));
    }

    const registrationCountrySelect = this.metadataService.values('nationality');
    for (const registrationCountrys of registrationCountrySelect) {
      this.registrationCountryOptions.push(new PropertySelectOption(registrationCountrys.value, registrationCountrys.key));
    }

    const educations = this.metadataService.values('education');
    for (const education of educations) {
      this.educationOptions.push(new PropertySelectOption(education.value, education.key));
    }

    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.longtimeAbroadOptions.push(new PropertySelectOption(res.Yes, true));
        this.longtimeAbroadOptions.push(new PropertySelectOption(res.No, false));
      });

    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.optOutRequestOptions.push(new PropertySelectOption(res.Yes, true));
        this.optOutRequestOptions.push(new PropertySelectOption(res.No, false));
      });

    this.translate.get(['Permanent', 'NonPermanent']).subscribe(
      (res: any) => {
        console.log(res);
        this.permanentResidentHKOptions.push(new PropertySelectOption(res.Permanent, true));
        this.permanentResidentHKOptions.push(new PropertySelectOption(res.NonPermanent, false));
        console.log(this.permanentResidentHKOptions);
      });

    this.customer.birthdate ? this.customer.birthday = new Date(this.customer.birthdate).valueOf() : this.customer.birthday = null;

  }

  isDefaultFill(): void {
    if (this.instance.isFoneOrDev() || this.instance.isWDF()) {
      this.isInputName = true;
      if (!this.customer.lastName) {
        this.customer.lastName = '-';
      }
      if (!this.customer.lastNamePinyin) {
        this.customer.lastNamePinyin = '-';
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
        if (this.isFone) {
          if (this.customer) {
            this.customer.lastName = this.customer?.lastName ?? '-';
            this.customer.lastNamePinyin = this.customer?.lastNamePinyin ?? '-';
          }
          if (this.customerCorporate) {
            this.customerCorporate.legalPersonLastName = this.customerCorporate?.legalPersonLastName ?? '-';
            this.customerCorporate.legalPersonLastNameEn = this.customerCorporate?.legalPersonLastNameEn ?? '-';
          }
        }
      },
      100);
  }

  onAddress(): void {
    this.translate.get('ResidentialAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customer, this.otherCustomer]);
        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customer.address,
            quickAddresses,
            instance: this.instance,
            modeType: this.modeType
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.address = data;
            }
          );
        });
      });
  }

  onResidenceAddress(): void {
    this.translate.get('LegalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customer, this.otherCustomer]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customer.residenceAddress,
            quickAddresses,
            instance: this.instance,
            modeType: this.modeType
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.residenceAddress = data;
            }
          );
        });
      });
  }

  onPostalAddress(): void {
    this.translate.get('PostalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customer, this.otherCustomer]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customer.postalAddress,
            quickAddresses,
            instance: this.instance,
            modeType: this.modeType
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.postalAddress = data;
            }
          );
        });
      });
  }

  onMobileNumber(): void {
    this.translate.get('MobileNumber').subscribe(
      (title: any) => {

        const quickPhones = Phones.getIndividualQuickPhones([this.customer, this.otherCustomer]);

        const phone = new Phone();
        phone.areaCode = this.customer.areaCode;
        phone.phone = this.customer.phone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones,
            requiredPhone: true
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.areaCode = data?.areaCode;
              this.customer.phone = data?.phone;
            }
          );
        });
      });
  }

  onCompanyAddress(): void {
    this.translate.get('CompanyAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customer, this.otherCustomer]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customer.companyAddress,
            quickAddresses,
            modeType: this.modeType
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.companyAddress = data;
            }
          );
        });
      });
  }

  onOfficePhoneNumber(): void {
    this.translate.get('OfficePhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getIndividualQuickPhones([this.customer, this.otherCustomer]);

        const phone = new Phone();
        phone.areaCode = this.customer.companyAreaCode;
        phone.phone = this.customer.companyTelephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.companyAreaCode = data?.areaCode;
              this.customer.companyTelephone = data?.phone;
            }
          );
        });
      });
  }

  onResidentialPhoneNumber(): void {
    this.translate.get('ResidentialPhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getIndividualQuickPhones([this.customer, this.otherCustomer]);

        const phone = new Phone();
        phone.areaCode = this.customer.residenceAreaCode;
        phone.phone = this.customer.residenceTelephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customer.residenceAreaCode = data?.areaCode;
              this.customer.residenceTelephone = data?.phone;
            }
          );
        });
      });

  }

  onOtherPhones(): void {
    this.translate.get('OtherPhones').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<PhonesEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: PhonesEditComponent,
          nzContentParams: {
            title,
            phones: this.customerType === 'INDIVIDUAL' ? this.customer.phones : this.customerCorporate.phones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.drawerRef = drawerRef;
          component.phonesSaved.subscribe(
            phones => {
              if (this.customerType === 'INDIVIDUAL') {
                this.customer.phones = phones;
              } else {
                this.customerCorporate.phones = phones;
              }
            }
          );
        });
      });

  }

  onCorporateOfficePhoneNumber(): void {
    this.translate.get('OfficePhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getCorporateQuickPhones([this.customerCorporate, this.otherCustomerCorporate]);

        const phone = new Phone();
        phone.areaCode = this.customerCorporate.areaCode;
        phone.phone = this.customerCorporate.telephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerCorporate.areaCode = data?.areaCode;
              this.customerCorporate.telephone = data?.phone;
            }
          );
        });
      });
  }

  onCorporateResidentialAddress(): void {
    this.translate.get('RegisteredAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getCorporateQuickAddresses([this.customerCorporate, this.otherCustomerCorporate]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customerCorporate.registeredAddress,
            quickAddresses,
            modeType: this.modeType
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerCorporate.registeredAddress = data;
            }
          );
        });
      });
  }

  onCorporateContactAddress(): void {
    this.translate.get('PostalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getCorporateQuickAddresses([this.customerCorporate, this.otherCustomerCorporate]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customerCorporate.contactAddress,
            quickAddresses,
            modeType: this.modeType
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerCorporate.contactAddress = data;
            }
          );
        });
      });
  }

  onCorporateLegalPersonPhoneNumber(): void {
    this.translate.get('LegalPersonPhone').subscribe(
      (title: any) => {
        const quickPhones = Phones.getCorporateQuickPhones([this.customerCorporate, this.otherCustomerCorporate]);

        const phone = new Phone();
        phone.areaCode = this.customerCorporate.legalPersonAreaCode;
        phone.phone = this.customerCorporate.legalPersonPhone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerCorporate.legalPersonAreaCode = data?.areaCode;
              this.customerCorporate.legalPersonPhone = data?.phone;
            }
          );
        });
      });
  }

  onReset(): void {
    this.customer = new Customer();
    this.customerCorporate = new CustomerCorporate();
    this.birthdayDate = 0;
    this.isDefaultFill();
  }

  onSave(): void {
    this.isOther();
    const customerEntity = new CustomerEntity();
    customerEntity.customerType = this.customerType;
    customerEntity.customer = this.customer;
    customerEntity.corporateCustomer = this.customerCorporate;
    customerEntity.relationship = this.relationship;
    this.customerSaved.emit(customerEntity);
    this.drawerRef?.close();
  }

  containsOther(options: string[]): boolean {
    if (options) {
      return options.includes('OTHERS');
    }
  }

  isOther(): void {
    if (this.customer.sports && !this.customer.sports.includes('OTHERS')) {
      this.customer.sportsOther = null;
    }
    if (this.customer.games && !this.customer.games.includes('OTHERS')) {
      this.customer.gamesOther = null;
    }
    if (this.customer.movie && !this.customer.movie.includes('OTHERS')) {
      this.customer.movieOther = null;
    }
    if (this.customer.cuisine && !this.customer.cuisine.includes('OTHERS')) {
      this.customer.cuisineOther = null;
    }
  }

  onContacts(): void {
    const drawerRef = this.drawerService.create<ContactsListComponent, { value: any }, string>(
      {
        nzWidth: 600,
        nzMaskClosable: true,
        nzContent: ContactsListComponent,
        nzContentParams: {}
      },
    );
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.detailSelected.subscribe(data => {
        console.log(data);
        this.customer = plainToInstance(Customer, data);
        drawerRef.close();
        console.log(this.customer);
      });
    });
  }

  get isFone(): boolean {
    const instance = this.metadataService.instance;
    return instance.key === 'fone' || instance.key === 'dev';
  }

  getIdCardChange(event): void {
    if (event && event.length === 18) {
      console.log(event);
      const idCardNumber = event; // 替换为实际的身份证号

      const birthYear = idCardNumber.substring(6, 10);
      const birthMonth = idCardNumber.substring(10, 12);
      const birthDay = idCardNumber.substring(12, 14);

      const birthDate = `${birthYear}-${birthMonth}-${birthDay}`;

      console.log(birthDate); // 打印出生日期
      // this.customer.birthdate = birthDate;
      this.customer.birthday = new Date(birthDate).valueOf();
    }
  }

  // 根据日期获取年龄
  getAge(date): any {
    // 根据日期获取年龄
    if (!date) {
      return;
    }
    if (date > new Date().getTime()) {
      this.translate.get('minAgeTip').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
      this.birthdayDate = 0;
      return ;
    }
    const delayYear = new Date().getFullYear() - new Date(date).getFullYear();
    const delayMonth = (new Date().getMonth() + 1) - (new Date(date).getMonth() + 1);
    const delayDate = (new Date().getDate()) - (new Date(date).getDate());
    let age = delayYear;
    if (delayMonth < 0) {
      age -= 1;
    } else if (delayMonth === 0) {
      if (delayDate < 0) {
        age -= 1;
      }
    }
    if (this.booking?.companyCode) {
      this.productService.age(date, this.booking.companyCode)
        .subscribe(
          resAge => {
            // this.applyReq.owner.age = resAge;
            this.birthdayDate = resAge;
          },
          error => {
          }
        );
    } else {
      this.birthdayDate = age;
    }

    if (this.modeType === 'booking' && this.mode === 'insured' && this.birthdayDate < 18) {
      this.customer.smoke = SmokeCondition.NS;
      this.customer.marriage = MarriageStatus.SINGLE;
    }
    this.customer.birthdate = formatDate(date, 'yyyy-MM-dd', 'en-US');
    return ;
  }

  get countryString(): string {
    const country = this.metadataService.translate('countries', this.customer.country);
    return country;
  }

  onCountry(): void {
    const drawerRef = this.drawerService.create<RegionSelectionComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: RegionSelectionComponent,
      nzContentParams: {
      },
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.regionSelected.subscribe(
        data => {
          this.customer.country = data.countryCode;
          drawerRef.close();
        }
      );
    });
  }

  getBusinessSourceList(): void {
    this.contactService.businessSourceList()
      .subscribe(
        res => {
          if (res.length) {
            for (const source of res) {
              this.businessSourceOptions.push(new PropertySelectOption(source.businessSource, source.businessSource));
            }
          } else {
            this.businessSourceOptions = [];
          }
        }
      );
  }

  get isBetterOrWDF(): boolean {
    let flag = true;
    if (this.instance.key === 'better') {
      flag =  false;
    }
    if (!this.isWDFShow()) {
      flag =  false;
    }
    return flag;
  }

  get isBetter(): boolean {
    let flag = true;
    if (this.instance.key === 'better') {
      flag = this.mode !== 'insured';
    }
    return flag;
  }

  isEmail(): boolean {
    if (this.instance.key === 'better') {
      return this.mode !== 'insured';
    }
    if (this.instance.isAWM()) {
      return this.mode !== 'insured';
    }
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    if (this.instance.isAI() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isYuFung() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return this.birthdayDate >= 18;
      }
    }
    if (this.instance.isAsa() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    if (this.instance.isTekFong() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return false;
  }

  isCompanyEmail(): boolean {
    if (this.instance.isAWM()) {
      return this.mode !== 'insured';
    }
    return false;
  }

  isLastName(): boolean {
    if (this.instance.isSESG() && this.modeType === 'booking') {
      return false;
    }
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.key === 'better') {
      return this.mode !== 'insured';
    }
    return true;
  }

  isEnglishName(): boolean {
    if (this.instance.isSESG() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.key === 'better') {
      return this.mode !== 'insured';
    }
    return true;
  }

  isGender(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.key === 'better') {
      return this.mode !== 'insured';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      return true;
      // if (this.mode === 'holder') {
      //   return true;
      // } else {
      //   return this.birthdayDate >= 18;
      // }
    }
    return true;
  }

  get isBirthday(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return true;
    }
    return !(this.instance.isFoneOrDev() || this.instance.isBetterOrDev());
  }

  get isCorporateName(): boolean {
    if (this.instance.isSESG() && this.modeType === 'booking') {
      return false;
    }
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    return true;
  }

  get IdCard(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.key === 'better') {
      return this.mode !== 'insured';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      // if (this.mode === 'holder') {
      //   return true;
      // } else {
      //   return this.birthdayDate < 18;
      // }
      return true;
    }
    return !this.instance.isFoneOrDev();
  }

  isHkIdCard(): boolean {
    if (this.instance.isIAM() && this.modeType === 'booking') {
      // return true;
      // if (this.mode === 'holder') {
      //   return false;
      // } else {
      //   return this.birthdayDate < 18;
      // }
    }
    return false;
  }

  isPostalAddress(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    if (this.instance.key === 'better') {
      return this.mode !== 'insured';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return false;
      }
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return !this.instance.isFoneOrDev();
  }

  isResidentialAddress(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return false;
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return this.birthdayDate >= 18;
      }
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return false;
    }
    return !(this.instance.isFoneOrDev() || this.instance.isBetterOrDev());
  }

  isLegalAddress(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    return false;
  }

  isCountry(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      return true;
      // if (this.mode === 'holder') {
      //   return true;
      // } else {
      //   return this.birthdayDate >= 18;
      // }
    }
    return true;
  }

  MobileNumber(): boolean {
    if (this.instance.key === 'better') {
      return this.mode !== 'insured';
    }
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return this.birthdayDate >= 18;
      }
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return !this.instance.isBailu() && this.modeType === 'booking';
  }

  isCompanyName(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      if (this.mode === 'insured') {
        return false;
      } else {
        return this.customer.careerInformation === 'ON_THE_JOB';
      }
    }
    if (this.instance.isSESG() && this.modeType === 'booking') {
      return false;
    }
    if (this.instance.isAI() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isYuFung() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return this.birthdayDate >= 18;
      }
    }
    if (this.instance.isAsa() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    if (this.instance.isWanson() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    if (this.instance.isTekFong() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return false;
  }

  isAverageMonthlyIncome(): boolean {
    if (this.instance.isWanson()) {
      return true;
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return false;
      }
    }
    if (this.instance.isYuFung() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return false;
      }
    }
    if (this.instance.isTekFong() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return false;
  }

  isLongtimeAbroad(): boolean {
    if (this.instance.isTekFong() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return false;
  }

  isOccupation(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      if (this.mode === 'insured') {
        return false;
      } else {
        return this.customer.careerInformation === 'ON_THE_JOB';
      }
    }
    if (this.instance.isAI() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isSESG() && this.modeType === 'booking') {
      return false;
    }
    if (this.instance.isYuFung() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isAsa() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    if (this.instance.isWanson() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return this.birthdayDate >= 18;
      }
    }
    if (this.instance.isTekFong() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return false;
  }

  relationFlag(): boolean {
    return this.workbench.relation ? !(this.instance.isWDF() && this.modeType === 'underwriting') : false;
  }

  bayLightOr999(): boolean {
    if (this.instance.isBaylightOrQuanBao()) {
      return this.mode === 'insured';
    }
    return false;
  }

  isBirthplace(): boolean {
    if (this.instance.isBaylightOrQuanBao()) {
      return this.mode === 'insured';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isTekFong() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return true;
    }
    return false;
  }

  isOtherNationalID(): boolean {
    if (this.instance.isWanson()) {
      return true;
    }
    if (this.instance.isBaylightOrQuanBao()) {
      return this.mode === 'insured';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isTekFong() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return false;
  }

  isEepCode(): boolean {
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return false;
      }
    }
    if (this.instance.isYuFung() && this.modeType === 'booking') {
      return true;
    }
    return false;
  }

  isNoOfDependents(): boolean {
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  isSalary(): boolean {
    if (this.instance.isWanson()) {
      return true;
    }
    if (this.instance.isYuFung()) {
      return true;
    }
    if (this.instance.isIAM()) {
      if (this.mode === 'holder') {
        return false;
      } else {
        return this.birthdayDate >= 18;
      }
    }
    if (this.instance.isTekFong() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return false;
  }

  isMarriage(): boolean {
    if (this.instance.isBaylightOrQuanBao()) {
      return this.mode === 'insured';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      return true;
      // if (this.mode === 'holder') {
      //   return true;
      // } else {
      //   return this.birthdayDate >= 18;
      // }
    }
    if (this.instance.isYuFung() && this.modeType === 'booking') {
      return true;
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return this.instance.isHarvest() && this.modeType === 'booking';
  }

  isEducation(): boolean {
    if (this.instance.isBaylightOrQuanBao()) {
      return this.mode === 'insured';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return false;
      }
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    return this.instance.isHarvest() && this.modeType === 'booking';
  }

  isSmokeRequired(): boolean {
    if (this.instance.isBaylightOrQuanBao()) {
      return this.mode === 'insured';
    }
    if (this.instance.isWanson()) {
      return this.instance.isWanson();
    }
    if (this.instance.isYuFung() && this.modeType === 'booking') {
      return this.instance.isYuFung() && this.modeType === 'booking';
    }
    if (this.instance.isDB() && this.modeType === 'booking') {
      return this.mode === 'holder';
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      return true;
      // if (this.mode === 'holder') {
      //   return true;
      // } else {
      //   return this.birthdayDate >= 18;
      // }
    }
    return false;
  }

  isOfficePhoneNumber(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      if (this.mode === 'insured') {
        return false;
      } else {
        return this.customer.careerInformation === 'ON_THE_JOB';
      }
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      if (this.mode === 'holder') {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  isCorporatePhone(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking') {
      return this.mode !== 'insured';
    }
    return true;
  }

  isPostCode(): boolean {
    if (this.instance.isHarvest() && this.modeType === 'booking' && this.mode === 'holder') {
      return true;
    }
    if (this.instance.isIAM() && this.modeType === 'booking') {
      return false;
    }
    return false;
  }

  isWDFShow(): boolean {
    if (this.instance.isWDF() && this.modeType === 'underwriting') {
      return this.workbench.groupDesensitization;
    } else {
      return true;
    }
  }


  isShowRemark(): boolean {
    if (this.instance.isFone()) {
      return false;
    } else if (this.instance.isWDF() && this.modeType === 'underwriting') {
      return this.workbench.groupDesensitization;
    } else {
      return true;
    }
  }

  isNumTip(): boolean {
    if (this.instance.isWanson()) {
      return this.customer.areaCode ? this.customer.areaCode === '86' : true;
    } else {
      return false;
    }
  }
}
