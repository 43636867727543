<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <app-water-mark [xSpace]="60" [ySpace]="90" ></app-water-mark>

  <!--  <nz-alert nzBanner nzType="warning" [nzMessage]="'PremiumsTips' | translate" nzShowIcon></nz-alert>-->
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div class="quotations-box" style="margin-top: 50px">
      <div class="box-header">
        <div class="header-text">
          <div>
            <div style="font-size: 20px;color: #1D64C4;font-weight: 600;">{{'FirstYearCommission' | translate}}</div>
            <div style="color: #999999; margin-top: 5px">{{'CommissionTip' | translate}}</div>
          </div>
          <div style="font-size: 32px;color: #1D64C4;font-weight: 600;">
            <span style="font-size: 28px">$</span>
            {{calculationRes || '0.00'}}
            <span *ngIf="currency" style="font-size: 15px;">{{currency}}</span>
          </div>
        </div>

        <button nz-button nzType="default"
                nz-tooltip [nzTooltipTitle]="'Reset' | translate"
                appThrottleClick (throttleClick)="onReload()"
                nzSize="large" nzShape="circle" style="align-self: center; margin-left: 50px;">
          <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
        </button>
      </div>

      <div class="box-center">
        <div class="section-title" style="margin-top: 20px">
          <span style="margin-left: 8px">{{'BasicInformation' | translate}}</span>
        </div>
        <nz-row nzJustify="start" [nzGutter]="24">
          <nz-col [nzSpan]="8">
            <!-- 产品 -->
            <app-property-pick [title]="'InsuranceProduct' | translate"
                               [displayMode]="'vertical'"
                               [required]="true"
                               appThrottleClick (throttleClick)="onProductSelection()"
                               [(value)]="product.productName"></app-property-pick>
          </nz-col>
          <nz-col [nzSpan]="8">
            <!-- 供款年期 -->
            <app-property-select [title]="'PaymentTerm' | translate"
                                 [displayMode]="'vertical'"
                                 [tipVisible]="product.companyCode === undefined"
                                 [disabled]="product.companyCode === undefined"
                                 [placeholder]="('PleaseSelect' | translate) + ('PaymentTerm' | translate)"
                                 [required]="product.categoryCode !== 'MEDICAL'"
                                 [options]="paymentTermOptions"
                                 [(value)]="premium.paymentTerm"
                                 (valueChange)="onCalculate()"></app-property-select>
          </nz-col>
          <nz-col [nzSpan]="8">
            <!--保费-->
            <app-property-input-number [title]="'Premium' | translate"
                                       [displayMode]="'vertical'"
                                       [placeholder]="'Premium' | translate"
                                       [required]="true"
                                       [(value)]="premium.premium"
                                       (valueChange)="premiumChange()"></app-property-input-number>
<!--            <div *ngIf="product.companyCode !== undefined">-->
<!--              <span>{{'MinimumSumAssured' | translate}}</span> {{sumInsuredMin}},-->
<!--              <span>{{'MaximumSumAssured' | translate}}</span> {{sumInsuredMax}}-->
<!--            </div>-->
          </nz-col>
          <nz-col [nzSpan]="8">
            <!-- 年龄 -->
            <app-property-input-number [displayMode]="'vertical'"
                                       [title]="'Age' | translate"
                                       [tipVisible]="product.companyCode === undefined"
                                       [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                                       [disabled]="product.companyCode === undefined"
                                       [(value)]="premium.age"
                                       (valueChange)="premiumChange()"></app-property-input-number>
            <div *ngIf="product.companyCode !== undefined">
              <span>{{'MinimumAge' | translate}}</span> {{ageMin}},
              <span>{{'MaximumAge' | translate}}</span> {{ageMax}}
            </div>
          </nz-col>
          <nz-col [nzSpan]="8">
            <!-- 货币 -->
            <app-property-select [title]="'Currency' | translate"
                                 [displayMode]="'vertical'"
                                 [tipVisible]="product.companyCode === undefined"
                                 [disabled]="product.companyCode === undefined"
                                 [placeholder]="('PleaseSelect' | translate) + ('Currency' | translate)"
                                 [options]="currencyOptions"
                                 [(value)]="premium.currency"
                                 (valueChange)="onCalculate()"></app-property-select>
          </nz-col>
        </nz-row>
        <nz-row *ngIf="contractData && contractData.length" nzJustify="start" [nzGutter]="24">
          <nz-col [nzSpan]="8">
          <div class="section-title" style="margin-top: 20px;display: flex;justify-content: space-between;align-items: center;">
            <span style="margin-left: 8px">{{'ContractMatching' | translate}}</span>
            <a nz-button nzType="link"
               appThrottleClick (throttleClick)="onContractSelection()">{{'SwitchingContract' | translate}}</a>
          </div>
          </nz-col>
        </nz-row>
        <nz-row *ngIf="contractData && contractData.length" nzJustify="start" [nzGutter]="24">
          <nz-col [nzSpan]="8">
            <!-- 首年介绍费比例 -->
            <app-property-pick [title]="'FirstYearCommissionProportion' | translate"
                               [displayMode]="'vertical'"
                               appThrottleClick (throttleClick)="onContractDetail()"
                               [(value)]="proportionRes"></app-property-pick>
          </nz-col>
        </nz-row>
      </div>
    </div>
  </nz-spin>

</div>
