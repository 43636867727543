import {Injectable} from '@angular/core';
import {HttpService} from '../core/http.service';
import {BaseService} from '../base/base.service';
import {ApiResponse, PagedReq, PagedResp} from '../api/types';
import {Observable} from 'rxjs/internal/Observable';
import {HttpParams} from '@angular/common/http';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {
  AttributeScore,
  CompanyHistoryDetailRes,
  CompanyHistoryRes,
  CompanySearch, CreateProductCompare,
  Product, ProductAttributeGroup, ProductAttributeGroupVO,
  ProductCategory,
  ProductCompany,
  ProductCompareReq,
  ProductCompareRes,
  ProductListResp,
  ProductRestriction,
  ProductRestrictionReq,
  ProductSearch
} from './product-types';
import {plainToClass, plainToInstance} from 'class-transformer';
import {ProductSelection} from '../quotation/quotation-types';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 产品分页列表
   * @param search 筛选条件
   */
  page(search: ProductSearch): Observable<PagedResp<ProductListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.region != null) {
      httpParams = httpParams.set('region', search.region.toString());
    }
    if (search.categoryCode != null) {
      httpParams = httpParams.set('categoryCode', search.categoryCode.toString());
    }
    if (search.companyCode != null) {
      httpParams = httpParams.set('companyCode', search.companyCode.toString());
    }
    if (search.hot != null) {
      httpParams = httpParams.set('hot', search.hot.toString());
    }
    if (search.productType != null) {
      httpParams = httpParams.set('productType', search.productType.toString());
    }
    if (search.productName != null) {
      httpParams = httpParams.set('productName', search.productName.toString());
    }
    if (search.sellStatus != null) {
      httpParams = httpParams.set('sellStatus', search.sellStatus.toString());
    }
    if (search.quotable != null) {
      httpParams = httpParams.set('quotable', search.quotable.toString());
    }
    if (search.comparable != null) {
      httpParams = httpParams.set('comparable', search.comparable.toString());
    }
    if (search.mainProductCode != null) {
      httpParams = httpParams.set('mainProductCode', search.mainProductCode.toString());
    }
    return this.http.get<ApiResponse<PagedResp<ProductListResp>>>(API.productV3 + '/page', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 计划书产品分页列表
   * @param search 筛选条件
   */
  pageForProposal(search: ProductSearch): Observable<PagedResp<ProductListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.region != null) {
      httpParams = httpParams.set('region', search.region.toString());
    }
    if (search.categoryCode != null) {
      httpParams = httpParams.set('categoryCode', search.categoryCode.toString());
    }
    if (search.companyCode != null) {
      httpParams = httpParams.set('companyCode', search.companyCode.toString());
    }
    if (search.hot != null) {
      httpParams = httpParams.set('hot', search.hot.toString());
    }
    if (search.productType != null) {
      httpParams = httpParams.set('productType', search.productType.toString());
    }
    if (search.sellStatus != null) {
      httpParams = httpParams.set('sellStatus', search.sellStatus.toString());
    }
    if (search.productName != null) {
      httpParams = httpParams.set('productName', search.productName.toString());
    }
    if (search.quotable != null) {
      httpParams = httpParams.set('quotable', search.quotable.toString());
    }
    if (search.comparable != null) {
      httpParams = httpParams.set('comparable', search.comparable.toString());
    }
    return this.http.get<ApiResponse<PagedResp<ProductListResp>>>(API.productV3 + '/page-for-proposal', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  productsByCodes(productCodes: string[]): Observable<Product[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('productCodes', productCodes.join(','));
    return this.http.get<ApiResponse<Product[]>>(API.productV3 + '/list-by-codes', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  categories(): Observable<ProductCategory[]> {
    return this.http.get<ApiResponse<ProductCategory[]>>(API.commonV3 + '/category/all', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  companies(): Observable<ProductCompany[]> {
    return this.http.get<ApiResponse<ProductCompany[]>>(API.companyV3 + '', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  // 获取保险公司地区
  companiesRegion(): Observable<ProductAttributeGroup[]> {
    return this.http.get<ApiResponse<ProductAttributeGroup[]>>(API.commonV3 + '/company/region', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  detail(productCode: string): Observable<Product> {
    return this.http.get<ApiResponse<Product>>(API.productV3 + '/detail/' + productCode, {
      observe: 'response'
    }).pipe(map(data => {
      console.log(data.body.data);
      return plainToInstance(Product, data.body.data);
    }));
  }

  age(birthday: number, companyCode: string): Observable<number> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('birthday', birthday.toString());
    httpParams = httpParams.set('companyCode', companyCode.toString());
    return this.http.get<ApiResponse<number>>(API.commonV3 + '/age', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  ageByProduct(birthday: string, productCode: string): Observable<number> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('birthday', birthday.toString());
    httpParams = httpParams.set('productCode', productCode.toString());
    return this.http.get<ApiResponse<number>>(API.commonV3 + '/product-age', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  companyPage(search: CompanySearch): Observable<PagedResp<ProductCompany>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.region != null) {
      httpParams = httpParams.set('region', search.region.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    return this.http.get<ApiResponse<PagedResp<ProductCompany>>>(API.companyV3 + '/page', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  companyDetail(code: string): Observable<ProductCompany> {
    return this.http.get<ApiResponse<ProductCompany>>(API.companyV3 + '/' + code, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(ProductCompany, data.body.data);
    }));
  }

  restriction(req: ProductRestrictionReq): Observable<ProductRestriction> {
    let httpParams = new HttpParams();
    if (req.code != null) {
      httpParams = httpParams.set('code', req.code.toString());
    }
    if (req.premiumTerm != null) {
      httpParams = httpParams.set('premiumTerm', req.premiumTerm.toString());
    }
    if (req.frequency != null) {
      httpParams = httpParams.set('frequency', req.frequency.toString());
    }
    if (req.type != null) {
      httpParams = httpParams.set('type', req.type.toString());
    }
    if (req.currency != null) {
      httpParams = httpParams.set('currency', req.currency.toString());
    }
    return this.http.get<ApiResponse<ProductRestriction>>(API.productV3 + '/restriction', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(ProductRestriction, data.body.data);
    }));
  }

  compare(req: ProductCompareReq): Observable<ProductCompareRes> {
    return this.http.post<ApiResponse<ProductCompareRes>>(API.compareV3, req, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(ProductCompareRes, data.body.data);
    }));
  }

  /**
   * 产品对比历史
   * @param search pageNum一个参数
   */
  companyHistory(search: PagedReq): Observable<PagedResp<CompanyHistoryRes>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    return this.http.get<ApiResponse<PagedResp<CompanyHistoryRes>>>(API.compareV4 + '/history', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除对比历史
   * @param id 编号
   */
  delete(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.compareV3 + '/delete/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 产品对比历史详情
   * @param id 一个参数
   */
  companyHistoryDetail(id): Observable<CompanyHistoryDetailRes> {
    return this.http.get<ApiResponse<CompanyHistoryDetailRes>>(API.compareV4 + '/history/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      console.log(plainToInstance(CompanyHistoryDetailRes, data.body.data));
      return plainToInstance(CompanyHistoryDetailRes, data.body.data);
    }));
  }

  /**
   * .产品对比主页
   */
  companyView(): Observable<ProductSelection> {
    return this.http.get<ApiResponse<ProductSelection>>(API.compareV3 + '/view', {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(ProductSelection, data.body.data, {excludeExtraneousValues: true});
    }));
  }

  /**
   * 编辑产品对比
   * @params params
   */
  editCompany(params: CreateProductCompare): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.compareV4, params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 对比项评分
   * /app/v4/compare/attribute-score
   */
  compareAttributeScore(params: AttributeScore): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.compareV4 + '/attribute-score', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 重试产品对比
   * @param id 编号
   */
  retry(id: number): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.compareV4 + '/retry/' + id, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
