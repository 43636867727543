<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!toDoList">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="toDoList" style="margin-top: 30px; ">
      <nz-table #nestedTable
                [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzHideOnSinglePage]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzData]="toDoList"
                [nzTotal]="toDoList.length"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                [nzPageIndex]="currentPage"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'PendingList' | translate}}
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div class="col-radio-solid" style="width: 100%; display: flex; justify-content: flex-end;">
                <nz-radio-group [(ngModel)]="mode" nzButtonStyle="solid"
                                [nzSize]="'small'"
                                (ngModelChange)="onReload()">
                  <label nz-radio-button nzValue="OWN">{{'My' | translate}}</label>
                  <label nz-radio-button nzValue="SUBORDINATE">{{'MySubordinates' | translate}}</label>
                </nz-radio-group>


                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'Refresh' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ toDoList.length }}</span>
          </div>
        </ng-template>

        <thead>
          <tr>
            <th nzWidth="40px"></th>
            <th nzWidth="340px"><span>{{'UnderwritingNumber' | translate}}</span></th>
            <th nzWidth=""><span>{{'PolicyNo' | translate}}</span></th>
<!--            <th><span>{{'AgentQuantity' | translate}}</span></th>-->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of currentPageData; let i = index">
            <tr (click)="onShowExpand(item)" style="cursor: pointer;">
              <td>
                <i nz-icon [nzType]="item.expand ? 'caret-down' : 'caret-right'" nzTheme="outline"></i>
              </td>
              <td>{{ item.underwritingNo }}</td>
              <td>{{ item.policyNo }}</td>
<!--              <td class="text-danger">{{ item.count }}</td>-->
            </tr>
            <tr [nzExpand]="item.expand">
              <div class="inner-table" style="background-color: #FAFBFD;color: #8EA3C6;border-bottom: 1px solid #d4dded">
                <span>{{'PendingNo' | translate}}</span>
                <span>{{'ClientName' | translate}}</span>
                <span>{{'PendingName' | translate}}</span>
                <span>{{'DeadlineDate' | translate}}</span>
                <span>{{'PendingDate' | translate}}</span>
                <span>{{'State' | translate}}</span>
                <span>{{'Function' | translate}}</span>
              </div>
              <div class="inner-table" *ngFor="let info of item.underwritingPendingDTOS; let j = index"
                   style="background-color: #FFFFFF;" [style]="j != item.underwritingPendingDTOS.length - 1 ? 'border-bottom: 1px solid #d4dded' : '' ">
                <span>{{info.pendingNo}}</span>
                <span>{{info.clientName}}</span>
                <span [title]="info.project">{{info.project}}</span>
                <span>{{info.deadlines | date: 'YYYY-MM-dd'}}</span>
                <span>{{info.applyTime | date: 'YYYY-MM-dd'}}</span>
                <span><span class="text-danger">●</span> {{ info.status}}</span>
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                   appThrottleClick (throttleClick)="onDetail(info); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_details.svg">
                </a>
              </div>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>
