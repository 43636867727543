import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToDoComponent } from './to-do.component';
import { ToDoMainComponent } from './to-do-main/to-do-main.component';
import { UnderwritingToDoComponent } from './underwriting-to-do/underwriting-to-do.component';
import { PolicyToDoComponent } from './policy-to-do/policy-to-do.component';
import {RouterModule} from '@angular/router';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import { EventRecordComponent } from './components/event-record/event-record.component';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import { MessageToDoComponent } from './message-to-do/message-to-do.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ToDoComponent,
    ToDoMainComponent,
    UnderwritingToDoComponent,
    PolicyToDoComponent,
    EventRecordComponent,
    MessageToDoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NzLayoutModule,
    TranslateModule,
    SharedModule,
    NzTabsModule,
    NzTableModule,
    NzSpinModule,
    NzSkeletonModule,
    NzButtonModule,
    NzIconModule,
    NzBadgeModule,
    NzGridModule,
    NzToolTipModule,
    NzAvatarModule,
    NzDrawerModule,
    NzRadioModule,
    FormsModule
  ]
})
export class ToDoModule { }
