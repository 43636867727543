import { Component, OnInit } from '@angular/core';
import { WorkbenchService } from '../workbench.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  DataBoard,
  DataBoardRes, DataDesensitization, DataMode, Instance,
  PagedResp,
  PolicyDataBoard, Underwriting,
  UnderwritingDataBoard,
  UnderWritingListResp
} from '../../api/types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../shared/service/metadata.service';
import { EChartsOption } from 'echarts/types/dist/shared';
import { formatDate } from '@angular/common';
import { ProductCompany } from '../../product/product-types';
import { ProductService } from '../../product/product.service';
import { Workbench } from '../workbench-types';
import { Tool } from '../../shared/utils/tool';
import { AccountService } from '../../account/account.service';
import { UnderwritingDetailComponent } from '../../underwriting/underwriting-detail/underwriting-detail.component';
import { UnderwritingService } from '../../underwriting/underwriting.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { plainToClass } from 'class-transformer';
import { Policy, PolicyListResp } from '../../policy/policy-types';
import { PolicyDetailComponent } from '../../policy/policy-detail/policy-detail.component';
import { PolicyService } from '../../policy/policy.service';

@Component({
  selector: 'app-data-board',
  templateUrl: './data-board.component.html',
  styleUrls: ['./data-board.component.less']
})
export class DataBoardComponent implements OnInit {

  searchParams: DataBoard = new DataBoard();
  underwritingSearchParams: UnderwritingDataBoard = new UnderwritingDataBoard();
  policySearchParams: PolicyDataBoard = new PolicyDataBoard();
  loading = false;
  filterPopoverVisible = false;
  underwritingVisible = false;
  policyVisible = false;
  dates: any;
  underwritingDates: any;
  policyDates: any;
  resData: DataBoardRes = new DataBoardRes();
  companies: ProductCompany[];
  underwritings: PagedResp<UnderWritingListResp>;
  policys: PagedResp<PolicyListResp>;

  dataDesensitization: DataDesensitization = new DataDesensitization();

  detailType: string;
  instance: Instance;
  workbench: Workbench;

  pivotOptions: PropertySelectOption[] = [];
  statusOptions: PropertySelectOption[] = [];
  companyOptions: PropertySelectOption[] = [];
  underwritingOptions: PropertySelectOption[] = [];
  policyOptions: PropertySelectOption[] = [];


  valueChartsOption: EChartsOption;

  constructor(public workbenchService: WorkbenchService,
              private translate: TranslateService,
              private metadataService: MetadataService,
              private productService: ProductService,
              private underwritingService: UnderwritingService,
              private drawerService: NzDrawerService,
              private accountService: AccountService,
              private policyService: PolicyService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.instance = this.workbenchService.mockInstance;
    this.workbench = this.workbenchService.workbench;
    this.searchParams.module = 'UNDERWRITING';
    this.searchParams.dateFilter = 'YEAR';
    this.searchParams.chartsType = 'countDate';
    this.searchParams.status = [];
    const pivotList = this.metadataService.values('pivot');
    this.dataDesensitization = this.accountService.dataDesensitization;

    // this.pivotOptions.push(new PropertySelectOption(this.translate.instant('All'), ''));
    for (const status of pivotList) {
      this.pivotOptions.push(new PropertySelectOption(status.value, status.key));
    }

    this.underwritingOptions.push(new PropertySelectOption('签单日期', 'signDate'), new PropertySelectOption('递交日期', 'submissionDate'));
    this.policyOptions.push(new PropertySelectOption('签单日期', 'signDate'), new PropertySelectOption('保单日期', 'policyDate'));

    const statusList = this.metadataService.values('underwritingStatus');
    // this.statusOptions.push(new PropertySelectOption(this.translate.instant('All'), ''));
    for (const status of statusList) {
      if (status.key === 'CHECKING' || status.key === 'UNDERWRITING' || status.key === 'PENDING_PREMIUM') {
        this.statusOptions.push(new PropertySelectOption(status.value, status.key));
      }
    }
    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });

        },
        error => {
        });
    this.getData();
    this.getUnderwritingList();
  }

  getData(): void {
    this.loading = true;
    this.filterPopoverVisible = false;
    this.workbenchService.getDataBoardView(this.searchParams)
      .subscribe(res => {
          console.log(res);
          this.resData = res;
          this.loading = false;
          this.valueChartsOption = this.getChartsOption(this.searchParams.chartsType);
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  onChangeModule(type: string): void {
    if (type === this.searchParams.module) {
      return;
    }
    this.searchParams = new DataBoard();
    this.searchParams.module = type;
    this.searchParams.dateFilter = 'YEAR';
    this.searchParams.chartsType = 'countDate';
    this.getData();
  }

  onChangeDate(type: string): void {
    if (this.detailType === 'UNDERWRITING') {
      this.underwritingSearchParams.dateFilter = type;
      this.getUnderwritingList(true);
    } else if (this.detailType === 'POLICY') {
      this.policySearchParams.dateFilter = type;
      this.getPolicyList(true);
    } else {
      this.searchParams.dateFilter = type;
      this.getData();
    }
  }

  pivotChange(value: string): void {
    console.log(value, 'value');
    this.getData();
  }

  pivotUnderwritingChange(value: string): void {
    console.log(value, 'value');
    this.getUnderwritingList(true);
  }

  pivotPolicyChange(value: string): void {
    console.log(value, 'value');
    this.getPolicyList(true);
  }

  statusChange(value: string): void {
    this.getData();
  }

  statusUNChange(value: string): void {
    this.getUnderwritingList(true);
  }


  chartsChange(value: string): void {
    console.log(value, 'value');
    this.valueChartsOption = this.getChartsOption(value);
  }

  getChartsOption(key: string): EChartsOption {
    const series = [];
    const own = {
      name: '',
      showSymbol: false,
      type: 'line',
      itemStyle: {
        color: '#20CEA8'
      },
      data: [],
    };
    const subordinate = {
      name: '',
      showSymbol: false,
      type: 'line',
      itemStyle: {
        color: '#1D64C4'
      },
      data: [],
    };
    const allSubordinate = {
      name: '',
      showSymbol: false,
      type: 'line',
      itemStyle: {
        color: '#FFA815'
      },
      data: [],
    };
    let legendData = [];
    let xData = [];

    console.log(this.searchParams.pivot, 'this.searchParams.pivot');
    if (!this.searchParams.pivot) {
      legendData = this.metadataService.values('pivot').map(item => item.value);
      if (this.resData[key] && typeof this.resData[key] === 'object') {
        xData = Object.keys(this.resData[key]).sort();
        this.metadataService.values('pivot').forEach(item => {
          if (item.key === 'OWN') {
            own.name = item.value;
            own.data = xData.map(ite => this.resData[key][ite].own || 0);
          }
          if (item.key === 'DIRECT_SUBORDINATE') {
            subordinate.name = item.value;
            subordinate.data = xData.map(ite => this.resData[key][ite].directSubordinate || 0);
          }
          if (item.key === 'ALL_SUBORDINATE') {
            allSubordinate.name = item.value;
            allSubordinate.data = xData.map(ite => this.resData[key][ite].allSubordinate || 0);
          }
        });
        series.push(own, subordinate, allSubordinate);
      } else {
        console.log(`resData[${key}] is not an object or is undefined`);
      }
    } else {
      this.metadataService.values('pivot').map(item => {
        if (item.key === this.searchParams.pivot) {
          legendData.push(item.value);
        }
      });
      if (this.resData && !!this.resData[key]) {
        xData = Object.keys(this.resData[key]).sort();
        this.metadataService.values('pivot').forEach(item => {
          if (item.key === 'OWN') {
            own.name = item.value;
            own.data = xData.map(ite => this.resData[key][ite].own || 0);
          }
          if (item.key === 'DIRECT_SUBORDINATE') {
            subordinate.name = item.value;
            subordinate.data = xData.map(ite => this.resData[key][ite].directSubordinate || 0);
          }
          if (item.key === 'ALL_SUBORDINATE') {
            allSubordinate.name = item.value;
            allSubordinate.data = xData.map(ite => this.resData[key][ite].allSubordinate || 0);
          }
        });
        switch (this.searchParams.pivot) {
          case 'OWN':
            series.push(own);
            break;
          case 'DIRECT_SUBORDINATE':
            series.push(subordinate);
            break;
          case 'ALL_SUBORDINATE':
            series.push(allSubordinate);
            break;
        }
      } else {
        console.log(`resData[${key}] is not an object or is undefined`);
      }
    }

    return {
      legend: {
        data: legendData,
        right: '20px'
      },
      grid: {
        show: false,
        left: '1%',
        right: '3%',
        bottom: '3%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xData
      },
      yAxis: {
        type: 'value',
        // splitLine: {
        //   show: false
        // },
        // axisLabel: {
        //   inside: true
        // }
      },
      series
    };
  }

  onDateChange(time: Date[]): void {
    if (time) {
      this.dates = time;
      this.searchParams.startDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.searchParams.endDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
      console.log(this.searchParams.startDate, this.searchParams.endDate);
    } else {
      this.dates = null;
      this.searchParams.startDate = '';
      this.searchParams.endDate = '';
    }
  }


  onUnDateChange(time: Date[]): void {
    if (time) {
      this.underwritingDates = time;
      this.underwritingSearchParams.startDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.underwritingSearchParams.endDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    } else {
      this.underwritingDates = null;
      this.underwritingSearchParams.startDate = '';
      this.underwritingSearchParams.endDate = '';
    }
  }

  onPoDateChange(time: Date[]): void {
    if (time) {
      this.policyDates = time;
      this.policySearchParams.startDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.policySearchParams.endDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    } else {
      this.policyDates = null;
      this.policySearchParams.startDate = '';
      this.policySearchParams.endDate = '';
    }
  }

  onResetSearch(): void {
    this.filterPopoverVisible = false;
    // this.searchParams = new DataBoard();
    this.searchParams.companyCode = '';
    this.searchParams.dateField = 'signDate';
    this.searchParams.startDate = '';
    this.searchParams.endDate = '';
    this.searchParams.chartsType = 'countDate';
    this.policyDates = null;
    this.getData();
  }

  disabledDate = (current: Date): boolean => {
    // console.log('disabledDate', current);
    return false;
  }

  onDetail(type: string): void {
    this.detailType = type;
    if (type === 'POLICY') {
      this.policySearchParams = plainToClass(PolicyDataBoard, this.searchParams);
      this.getPolicyList(true);
    } else {
      this.underwritingSearchParams = plainToClass(UnderwritingDataBoard, this.searchParams);
      this.getUnderwritingList(true);
    }
  }

  onBack(): void {
    this.detailType = '';
  }

  getUnderwritingList(resetPageNum = false): void {
    if (resetPageNum) {
      this.underwritingSearchParams.pageNum = 1;
    }
    this.underwritingVisible = false;
    this.loading = true;
    this.workbenchService.getUnderwriting(this.underwritingSearchParams)
      .subscribe(res => {
          console.log(res);
          this.underwritings = res;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  onSearch(pageNum: number): void {
    this.underwritingSearchParams.pageNum = pageNum;
    this.getUnderwritingList();
  }

  get isShowBusiness(): boolean {
    return !(this.instance.key === 'fone' || this.instance.isBaylightOrDev() || this.instance.key === 'quanbao');
  }

  dynamicEncryption(keyName, val): string {
    let isShow = val;
    if (this.underwritingSearchParams.pivot === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
    }
    return isShow;
  }

  onUnderwritingDetail(underWritingNo: string): void {
    this.loading = true;
    this.underwritingService.detail(underWritingNo)
      .subscribe(
        underwritingDetail => {

          this.loading = false;
          const underwriting = underwritingDetail.toUnderwriting();

          const drawerRef = this.drawerService.create<UnderwritingDetailComponent, { value: Underwriting }, string>({
            nzWidth: 800,
            nzContent: UnderwritingDetailComponent,
            nzContentParams: {
              underwritingDetail,
              underwriting,
              mode: DataMode.OWN,
              dataDesensitization: this.dataDesensitization
            }
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
            this.getUnderwritingList(false);
          });
        },
        error => {
          this.loading = false;
        });
  }

  onResetUnderwritingSearch(): void {
    this.underwritingVisible = false;
    this.underwritingSearchParams = new UnderwritingDataBoard();
    this.underwritingSearchParams.dateFilter = 'YEAR';
    this.underwritingDates = [];
    this.getUnderwritingList(true);
  }

  getPolicyList(resetPageNum = false): void {
    if (resetPageNum) {
      this.policySearchParams.pageNum = 1;
    }
    this.policyVisible = false;
    this.loading = true;
    this.workbenchService.getPolicy(this.policySearchParams)
      .subscribe(res => {
          console.log(res);
          this.policys = res;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  onPolicySearch(pageNum: number): void {
    this.policySearchParams.pageNum = pageNum;
    this.getPolicyList();
  }


  onResetPolicySearch(): void {
    this.policyVisible = false;
    this.policySearchParams = new PolicyDataBoard();
    this.policySearchParams.dateFilter = 'YEAR';
    this.policyDates = [];
    this.getPolicyList(true);
  }
  onPolicyDetail(policyId: string): void {
    this.loading = true;
    this.policyService.detail(policyId)
      .subscribe(
        policyDetail => {

          this.loading = false;
          const policy = policyDetail.toPolicy();

          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policyDetail,
              policy,
              mode: DataMode.OWN,
              dataDesensitization: this.dataDesensitization
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });
          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
            this.getPolicyList();
          });
        },
        error => {
          this.loading = false;
        });
  }

}
