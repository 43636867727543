import {Expose, plainToClass, plainToInstance, Transform, Type} from 'class-transformer';
import {PolicyStatus} from '../policy/policy-types';
import {plainToArray} from '../shared/utils/class-transform';

export class Error {
  err: string;
  code: number;
  msg: string;
}

export enum Locale {
  en = 'en-US',
  cn = 'zh-Hans-CN',
  hk = 'zh-Hant-HK',
  ko = 'ko-KR',
  jp = 'ja-JP',
}

export class RSASign {
  deviceId: string; // 设备id
  token: string; // token
  url: string; // api url
  timestamp: string; // 时间戳
  constructor(deviceId: string, token: string, url: string, timestamp: string) {
    this.deviceId = deviceId;
    this.token = token;
    this.url = url;
    this.timestamp = timestamp;
  }
}

export class ApiResponse<T> {
  code: number;
  message: string;
  path: string;
  status: number;
  timestamp: number;
  data: T;
}

export class PagedReq {
  pageNum = 1;
  pageSize = 10;
  pageSizeOptions = [10, 20, 30, 50, 100];
}

export class PagedResp<T> {
  pageNum: number;
  pageSize: number;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100];
  totalPage: number;
  list: T[];
  total: number;
}

export class I18n {
  zhHansCN: string; // 简体
  zhHantHK: string; // 繁体
  enUS: string; // 英文
  jaJP: string; // 日語
  koKR: string; // 韓語
}

export class Material {
  bucket: string;
  dir: string;
  fileName: string;
  objectName: string;
  contentType: string;
  uploadTime: number;
  filePath: string;
  author: string;
  fileType: string;
  tags: any[];
}

export class AccessToken {
  accessToken: string;
  md5: string;
  cache: boolean;
}

export enum UploadType {
  DEFAULT = 'DEFAULT',
  PROPOSAL = 'PROPOSAL',
  PRODUCT = 'PRODUCT',
  COMPANY = 'COMPANY',
  BOOKING = 'BOOKING',
  POLICY = 'POLICY',
  ACCOUNT = 'ACCOUNT',
  TICKET = 'TICKET',
  INFORMATION = 'INFORMATION',
  COMMISSION = 'COMMISSION',
  DOCUMENT = 'DOCUMENT',
  CONTRACT = 'CONTRACT',
  UNDERWRITING = 'UNDERWRITING',
  CUSTOM = 'CUSTOM',
  ARTICLE = 'ARTICLE',
  FNA = 'FNA',
  BILL = 'BILL',
  CONTACT = 'CONTACT',
  PUNCHIN = 'PUNCHIN',
}

export class UploadTokenReq {
  uploadType: UploadType;
  middleName: string;
  fileName: string;
}

export class UploadToken {
  signedUrl: string;
  filePath: string;
  contentType: string;
}

export class Metadata {
  accessToken: string;
  md5: string;
  cache: boolean;
}

export class Instance {
  key: string;
  code: string;
  groupKey: string;
  pushKey: string;
  logo: string;

  isDev(): boolean {
    return this.groupKey === 'dev';
  }

  isFone(): boolean {
    return this.groupKey === 'fone';
  }

  isFoneOrDev(): boolean {
    return this.groupKey === 'fone';
  }

  isAI(): boolean { // 维港
    return this.groupKey === 'victoria';
  }

  isIAM(): boolean { // IAM
    return this.groupKey === 'iam';
  }

  isWanson(): boolean {
    return this.groupKey === 'wanson';
  }
  // bw6
  isBetterOrDev(): boolean {
    return this.groupKey === 'better';
  }
  // '湾区之光Sales',
  isBaylightOrDev(): boolean {
    return this.groupKey === 'baylight';
  }
  // '湾区之光Sales域名',
  isBIBDomain(): boolean {
    return window.location.href.includes('sales.hkbaylight.com');
  }
  // '圈保',
  isQuanBao(): boolean {
    return this.groupKey === 'quanbao';
  }

  isBaylightOrQuanBao(): boolean {
    return this.groupKey === 'baylight' || this.groupKey === 'quanbao';
  }

  isWDF(): boolean {
    return this.groupKey === 'waterdrop';
  }

  // xicheng
  isBailu(): boolean {
    return this.groupKey === 'bailu';
  }

  isYuFung(): boolean {
    return this.groupKey === 'yufung';
  }

  isCMGHK(): boolean {
    return this.groupKey === 'cmghk';
  }

  isSESG(): boolean {
    return this.groupKey === 'sesg';
  }

  isEmpire(): boolean { // Empire
    return this.groupKey === 'empire';
  }

  // Harvest66
  isHarvest(): boolean {
    return this.groupKey === 'harvest';
  }

  isAWM(): boolean {
    return this.groupKey === 'awm';
  }

  // asa
  isAsa(): boolean {
    return this.groupKey === 'asa';
  }

  // asa
  isTekFong(): boolean {
    return this.groupKey === 'tekfong';
  }

  // zuu
  isZUU(): boolean {
    return this.groupKey === 'zuu';
  }
  isGrandnorm(): boolean {
    return this.groupKey === 'grandnorm';
  }
  // wrisefs
  isWrisefs(): boolean {
    return this.groupKey === 'wrise';
  }

  isMercer(): boolean {
    return this.groupKey === 'mercer';
  }

  isDB(): boolean {
    return this.groupKey === 'daybetter';
  }
}

export class Passport {
  id: number;
  accountName: string; // 账号名
  name: string; // 姓名
  nameEn: string; // 姓名-英文
  firstName: string; // 名
  lastName: string; // 姓
  firstNameEn: string; // 名-英文
  lastNameEn: string; // 姓-英文
  token: string; // token
  pivot: string; // 数据透视
  avatar: string; // 头像
  areaCode: string; // 国际区号
  phone: string; // 手机号码
  email: string; // 邮箱
  salesRank: string;
  registerNumber: string; // 理财顾问注册编号
  consultantNumber: string; // 理财顾问编码
  tr: boolean; // 是否签单员
}

export class Account {
  id: number;
  token: string;
  createTime: number;
  updateTime: number;
  nickname: string;
}

export class PasswordConfig {
  complexPasswordConfig: boolean; // 是否复杂密码
  passwordLengthConfig: number;
}

export class InstanceAccount {
  @Transform((value) => plainToInstance(Instance, Object.assign(new Instance(), value.value)))
  @Expose() instance: Instance;
  @Transform((value) => plainToInstance(Passport, Object.assign(new Passport(), value.value)))
  @Expose() passport: Passport;
}

export class GuideContent {
  title: string;
  content: string;
  cover: string;
}

export class LoginByAccountReq {
  accountName: string;
  password: string;
}

export class LoginByCodeReq {
  accountName: string;
  code: string;
}

export class ChangePassword {
  @Expose() type: string;
  @Expose() accountName: string;
  @Expose() newPassword: string;
  @Expose() code: string;
}

export class ChangePasswordRes {
  @Expose() phone: string;
  @Expose() email: string;
}

export enum ChangePwdMode {
  DEFAULT = 'DEFAULT',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export enum Pivot {
  OWN = 'OWN',
  DIRECT_SUBORDINATE = 'DIRECT_SUBORDINATE',
  ALL_SUBORDINATE = 'ALL_SUBORDINATE',
}

export enum DataMode {
  OWN = 'OWN',
  SUBORDINATE = 'SUBORDINATE',
  FOLLOWUP = 'FOLLOWUP',
  SALESGROUP = 'SALESGROUP'
}

export enum Gender {
  MALE = 'MALE', // 男
  FEMALE = 'FEMALE', // 女
  UNKNOWN = 'UNKNOWN' // 未知
}

export enum SmokeCondition {
  S = 'S',
  NS = 'NS',
  UNKNOWN = 'UNKNOWN'
}

export enum MarriageStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
  UNKNOWN = 'UNKNOWN'
}

export enum Currency {
  CNY = 'CNY',
  HKD = 'HKD',
  MOP = 'MOP',
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
  CAD = 'CAD',
  GBP = 'GBP',
  AUD = 'AUD',
  SGD = 'SGD',
  CHF = 'CHF',
  NZD = 'NZD',
}

export enum Region {
  CHINESE = 'CHINESE',
  HONGKONG = 'HONGKONG',
  TAIWAN = 'TAIWAN',
  MACAO = 'MACAO',
  USA = 'USA',
  CANADA = 'CANADA',
  KOREA = 'KOREA',
  FRANCE = 'FRANCE',
  GERMANY = 'GERMANY',
  UK = 'UK',
  ITALY = 'ITALY',
  SINGAPORE = 'SINGAPORE',
  PHILIPPINES = 'PHILIPPINES',
  AUSTRALIA = 'AUSTRALIA',
  MALAYSIA = 'MALAYSIA',
  THAILAND = 'THAILAND',
  JAPAN = 'JAPAN',
  INDONESIA = 'INDONESIA',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN'
}

export enum Frequency {
  SINGLE_PAYMENT = 'SINGLE_PAYMENT',
  ANNUALLY = 'ANNUALLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY'
}

export enum Relationship {
  OWN = 'OWN',
  PARENT = 'PARENT',
  CHILD = 'CHILD',
  SPOUSE = 'SPOUSE',
  BROTHER = 'BROTHER',
  SISTER = 'SISTER',
  EMPLOYEE = 'EMPLOYEE',
  GRANDCHILDREN = 'GRANDCHILDREN',
  LEGAL_GUARDIAN = 'LEGAL_GUARDIAN',
  CUSTODIAN = 'CUSTODIAN',
  TRUSTEE = 'TRUSTEE',
  BROTHER_SISTER = 'BROTHER_SISTER',
  SISTER_BROTHER = 'SISTER_BROTHER',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN'
}

export enum ProposalAmountType {
  BOTH = 'BOTH',
  PREMIUM = 'PREMIUM',
  SUM_ASSURED = 'SUM_ASSURED'
}

export enum SellStatus {
  DISCONTINUED = 'DISCONTINUED',
  SELLING = 'SELLING'
}

export enum ProductType {
  BASIC = 'BASIC',
  RIDER = 'RIDER'
}

export enum Title {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Miss = 'Miss',
  Ms = 'Ms',
  Dr = 'Dr'
}

export enum MedicalRegion {
  WORLDWIDE = 'WORLDWIDE',
  WORLDWIDE_EXCEPT_US = 'WORLDWIDE_EXCEPT_US',
  ASIA = 'ASIA',
  GREATER_CHINA = 'GREATER_CHINA'
}

export enum MedicalLevel {
  BASIC_WARD = 'BASIC_WARD',
  WARD = 'WARD',
  SEMI_PRIVATE = 'SEMI_PRIVATE',
  PRIVATE = 'PRIVATE',
  DELUXE = 'DELUXE',
  VIP = 'VIP',
  SUITE = 'SUITE'
}

export enum SupplementaryBenefit {
  OUTPATIENT = 'OUTPATIENT',
  MATERNITY = 'MATERNITY',
  DENTAL = 'DENTAL',
  SMM = 'SMM',
  SMMP = 'SMMP'
}

export class Deductible {
  currency: Currency; // 货币
  amounts: number[]; // 金额
}

export class MedicalOptions {
  @Expose() regions: MedicalRegion[]; // 医疗险保障区域
  @Expose() deductibles: Deductible[]; // 医疗险自付额
  @Expose() levels: MedicalLevel[]; // 医疗险等级
  @Expose() supplementaryBenefits: SupplementaryBenefit[]; // 附加保障
}

export class BankInformation {
  bankName: string; // 银行名称
  bankAddress: string; // 银行地址
  accountName: string; // 银行账号姓名
  accountNumber: string; // 银行账号
  swiftCode: string; // 银行代号/支行名称
}

export class BankInfo {
  code: string; // 银行编号
  ownerName: string; // 持卡人姓名
  name: string; // 银行名称
  ownerIdCard: string; // 持卡人身份证
  remark: string; // 备注
  cardNo: string; // 银行卡号
}

export class Honor {
  honorTime: number; // 时间
  honorTitle: string; // 标题
  honorContent: string; // 银行账号姓名
}

export class WorkExperience {
  companyName: string; // 公司名字
  companyPosition: string; // 公司名字
  startTime: number; // 入职时间
  endTime: number; // 离职时间（null为至今）
  description: string; // 描述
}

export class Labels {
  label: string; // 标签
  color: string; // 颜色
}

export class AccountInformation {
  id: number; // 编号
  accountName: string; // 账户名
  name: string; // 姓名（只读）
  nickName: string; // 姓名（只读）
  firstName: string; // 名
  lastName: string; // 姓
  firstNameEn: string; // 名(英文)
  lastNameEn: string; // 姓(英文)
  gender: Gender; // 性别: FEMALE-女;MALE-男;OTHER-其他
  birthday: number; // 出生日期
  wechat: string; // 微信号
  areaCode: string; // 区号
  contact: string; // 联系方式
  facebook: string; // facebook账号
  whatsapp: string; // whatsapp账号
  companyName: string; // 公司名
  companyAddress: string; // 公司地址
  companyPosition: string; // 职位
  lockScreenPassword: string; // 锁屏码(用于APP锁屏)
  thumbnailAvatar: string; // 用户缩略图头像
  avatar: string; // 用户头像
  avatarList: string[]; // 用户头像列表
  @Type(() => BankInformation)
  bankInfo: BankInformation; // 银行卡信息
  honors: Honor[]; // 个人荣誉
  @Type(() => WorkExperience)
  workExperiences: WorkExperience[]; // 工作经历
  @Type(() => Material)
  album: Material[]; // 个人相册
  profile: string; // 个人简介
  @Transform((value) => plainToInstance(AccountRemaining, Object.assign(new AccountRemaining(), value.value)))
  accountRemainingCondition: AccountRemaining = new AccountRemaining();
  salesChannel: string; // "CORPORATE"
  institutionCode: string;
  institutionName: string;
  bankName: string;
  salesRank: string;
  language: string; // 语言偏好
  hasPinCode: boolean; // 是否设置PIN
  labels: Labels[]; // 个人标签
  connectionsDynamicPush: boolean; // 人脉动态推送开关
}

export class AccountRemaining {
  accountName: string;
  currentRank: string; // 當前職級
  nextRank: string; // 目標職級
  promotionConditions: string; // 目標條件
  finalRank: string;
  remainingCondition: string; // 剩餘條件
}

export enum ModuleName {
  PRODUCT = 'PRODUCT',
  COMPANY = 'COMPANY',
  COMPARE = 'COMPARE',
  PROPOSAL = 'PROPOSAL',
  PROPOSAL_CUSTOM = 'PROPOSAL_CUSTOM',
  BOOKING = 'BOOKING',
  AML = 'AML',
  AML_MONITOR = 'AML_MONITOR',
  AML_SEARCH = 'AML_SEARCH',
  BUSINESS_VOLUME = 'BUSINESS_VOLUME',
  CENTRE_TICKET = 'CENTRE_TICKET',
  UNDERWRITING = 'UNDERWRITING',
  FNA = 'FNA',
  NEW_FNA = 'NEW_FNA',
  TICKET = 'TICKET',
  POLICY = 'POLICY',
  DOCUMENT = 'DOCUMENT',
  LEAVE_WORD = 'LEAVE_WORD',
  PRODUCT_PROMOTION = 'PRODUCT_PROMOTION',
  INFORMATION = 'INFORMATION',
  SALE_REPORT = 'SALE_REPORT',
  INTELLIGENT_CONTRACT = 'INTELLIGENT_CONTRACT',
  INVITATION = 'INVITATION',
  POLICY_REMINDER = 'POLICY_REMINDER',
  KNOWLEDGE = 'KNOWLEDGE',
  COMMISSION = 'COMMISSION',
  SHARE = 'SHARE',
  EXPANDING = 'EXPANDING',
  QUOTATION = 'QUOTATION',
  PRE_SALES = 'PRE_SALES',
  PROPOSAL_COMPARE = 'PROPOSAL_COMPARE',
  SALES_PACT = 'SALES_PACT',
  COMMISSION_FORECAST = 'COMMISSION_FORECAST',
  PREMIUM_FINANCING = 'PREMIUM_FINANCING',
  DASHBOARD = 'DASHBOARD',
}

export enum ModuleBehaviour {
  NATIVE = 'NATIVE',
  LINK = 'LINK',
  FILE = 'FILE'
}

export class Module {
  view: string; // 模块显示名称
  name: ModuleName; // 模块名称
  icon: string; // 模块icon
  enable: boolean; // 模块状态
  behaviour: ModuleBehaviour; // 跳转行为: NATIVE-本机;LINK-链接;FILE-文件
  link: string; // 外部链接地址
  visible: boolean; // 可视化的
  reminder: boolean; // 提醒标识
  needPinCode: boolean; // PIN密码
  squareRank: number; // 方格排序
  // comingSoon: boolean; // 本地字段：敬请期待
}


export class Feature {
  group: string; // 分组
  squares: Module[]; // 分组
}

export class SumRangeResp {
  @Expose() min: number; // 最小值
  @Expose() max: number; // 最大值
}

// 模板文件的md5值
export class TemplateFileMD5List {
  @Expose() name: string; // 模板名
  @Expose() md5: string; // md5值
}

export class DashboardTotal {
  premium: number; // 保费
  policyCounts: number; // 保单数量
  proposalDetail: number; // 计划书数量
  bookingDetail: number; // 预约数量
}
export class KeyValue {
  @Expose() key: string;
  @Expose() value: any;
}

export class DataBoard {
  @Expose() module: string; // 新单/保单 UNDERWRITING/POLICY
  @Expose() pivot: string;
  @Expose() status: string[];
  @Expose() companyCode: string;
  @Expose() dateField = 'signDate'; //  签单日期：signDate 递交日期：submissionDate 保单日期：policyDate
  @Expose() dateFilter: string; //  YEAR :YEAR  QUARTER :QUARTER  MONTH :MONTH
  @Expose() startDate: string;
  @Expose() endDate: string;
  @Expose() chartsType: string; // firstCommissionDate premiumDate countDate
}

export class UnderwritingDataBoard extends PagedReq {
  @Expose() module = 'UNDERWRITING';
  @Expose() pivot: string;
  @Expose() status: string[];
  @Expose() companyCode: string;
  @Expose() dateField = 'signDate'; //  签单日期：signDate 递交日期：submissionDate
  @Expose() dateFilter: string; //  YEAR :YEAR  QUARTER :QUARTER  MONTH :MONTH
  @Expose() startDate: string;
  @Expose() endDate: string;
  @Expose() name: string;
  @Expose() underwritingNo: string;
  @Expose() policyNo: string;
  @Expose() customCode: string;
}

export class PolicyDataBoard extends PagedReq {
  @Expose() module = 'POLICY';
  @Expose() pivot: string;
  @Expose() companyCode: string;
  @Expose() dateField = 'signDate' ; //  签单日期：signDate 递交日期：submissionDate
  @Expose() dateFilter: string; //  YEAR :YEAR  QUARTER :QUARTER  MONTH :MONTH
  @Expose() startDate: string;
  @Expose() endDate: string;
  @Expose() name: string;
  @Expose() policyNo: string;
  @Expose() customCode: string;
}

export class DataBoardRes {
  @Expose() underwriting: boolean; // 是否有权限
  @Expose() policy: boolean; // 是否有权限
  @Expose() count: number;
  @Expose() premium: number;
  @Expose() firstCommission: number;
  @Expose() countDate: BoardResKey; // 数量折线图
  @Expose() premiumDate: BoardResKey; // 保费折线图
  @Expose() firstCommissionDate: BoardResKey; // 介绍费折线图
}

export class BoardResKey {
  [key: string]: BoardResValue;
}

export class BoardResValue {
  @Expose() own: number;
  @Expose() directSubordinate: number;
  @Expose() allSubordinate: number;

}

export class OverviewRes {
  @Type(() => KeyValue)
  @Expose() premium: KeyValue[]; // 保费
  @Type(() => KeyValue)
  @Expose() policyCounts: KeyValue[]; // 保单数量
  @Type(() => KeyValue)
  @Expose() proposalCounts: KeyValue[]; // 计划书数量
  @Type(() => KeyValue)
  @Expose() bookingCounts: KeyValue[]; // 预约数量
  @Type(() => KeyValue)
  @Expose() underwritingList: KeyValue[];
  @Expose() underwritingCounts: any[];
}
// 消息类型主键
export enum MessageType {
  BOOKING = 'BOOKING', // 预约
  UNDERWRITING = 'UNDERWRITING', // 新单
  POLICY = 'POLICY', // 保单
  PROPOSAL = 'PROPOSAL', // 计划书
  TICKET = 'TICKET', // 服务咨询
  COMMISSION = 'COMMISSION', // 佣金
  SYSTEM = 'SYSTEM', // 系统
  NOTICE = 'NOTICE', // 公告
  INFORMATION = 'INFORMATION',
  OTHER = 'OTHER',
  SHARE = 'SHARE', // 分享
  FINANCIAL_PLAN = 'FINANCIAL_PLAN',
  SCHEDULE = 'SCHEDULE',
  KNOWLEDGE = 'KNOWLEDGE',
  LEAVE_WORD = 'LEAVE_WORD',
  INPUT_PROPOSAL_CODE = 'INPUT_PROPOSAL_CODE', // 计划书
  INTELLIGENT_CONTRACT = 'INTELLIGENT_CONTRACT', // 智能合約
  INVITATION = 'INVITATION',
}
// 消息类型--- 子类型
export enum MessageSubType {
  DIALOG = 'DIALOG', // 留言
  PENDING = 'PENDING', // 新单代办事项
  PENDING_REPLY = 'PENDING_REPLY', // 新单待办事项回复
  SERVICE = 'SERVICE', // 保单代办事项
  SERVICE_REPLY = 'SERVICE_REPLY', // 保单待办事项回复
  ATTACHMENT = 'ATTACHMENT', // 附件
  STATUS = 'STATUS', // 状态变更
  INFO = 'INFO', // 信息变更
  CREATE = 'CREATE' // 新建预约、新单、保单等
}

export enum MessageStatus {
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export class MessageListResp {
  @Expose() id: number; // 编号
  @Expose() type: MessageType; // 消息类型
  @Expose() subType: MessageSubType; // 消息类型
  @Expose() key: string; // 消息类型的主键
  @Expose() subKey: string; // 子消息类型的主键
  @Expose() addTime: number; // 接收时间
  @Expose() content: string; // 内容
  @Expose() unreadTotal: number; // 未读总数
  @Expose() logo: string; // 消息logo
}

export class MessageSearch extends PagedReq {
  type: MessageType; // 消息类型
  status: MessageStatus; // 是否已读:READ-已读，UNREAD-未读
}

export class Message {
  @Expose() id: number; // 编号
  @Expose() type: MessageType; // 消息类型
  @Expose() subType: MessageSubType; // 消息类型
  @Expose() key: string; // 消息类型的主键
  @Expose() subKey: string; // 子消息类型的主键
  @Expose() accountName: string; // 接收者
  @Expose() title: string; // 消息标题
  @Expose() content: string | any; // 消息内容
  @Expose() status: MessageStatus; // 是否已读:READ-已读，UNREAD-未读
  @Expose() addTime: number; // 创建时间
  @Expose() action: string; // 标志被删除
}

export class Link {
  @Expose() link: string;
  @Expose() description: string;
  @Expose() addTime: number;
}

export class ProductPlan {
  @Expose() productYear: string; // 年期规格
  @Expose() customPaymentTerm: string; // 年期规格
  @Expose() frequency: Frequency; // 缴费频率
  @Expose() currency: Currency; // 币种
  @Expose() premium: number; // 每期保费
  @Expose() targetPremium: number;
  @Expose() excessPremium: number;
  @Expose() sumAssured: number; // 保额
  @Expose() firstPaymentType: string; // 首期付款方式
  @Expose() restPaymentType: string; // 续期付款方式
  @Expose() dividends: string; // 红利分配方式
  // @Expose() backtrack: boolean; // 是否回溯
  // @Expose() policyDate: number; // 保单日期
}

export class MedicalPlan {
  @Expose() region: string; // 医疗险保障区域
  @Expose() deductible: number; // 自付额
  @Expose() medicalLevel: MedicalLevel;
  @Expose() supplementaryBenefits: SupplementaryBenefit[]; // 附加保障
}

// 附加险
export class Rider {
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() categoryCode: string; // 保险分类碼
  @Expose() premium: number; // 每期保费
  @Expose() sumAssured: number; // 保额
  @Type(() => MedicalPlan)
  @Transform((value) => plainToInstance(MedicalPlan, Object.assign(new MedicalPlan(), value.value)))
  @Expose() medicalPlan: MedicalPlan = new MedicalPlan(); // 医疗计划
  @Expose() totalPremium: number; // 总缴保费
  @Expose() policyPeriod: string; // 保障年限
  @Expose() productYear: string;
}

// 地址
export class Address {
  @Expose() region: string; // 国家地区
  @Expose() city: string; // 城市
  @Expose() street: string; // 街道
  @Expose() location: string; // 详细地址

  toAddressString(): string {
    return (this.region ?? '') + (this.city ?? '') + (this.street ?? '') + (this.location ?? '');
  }
}

// 手机
export class Phone {
  @Expose() type: string; // 类型
  @Expose() areaCode: string; // 区号
  @Expose() phone: string; // 手机号

  toPhoneString(): string {
    return (this.areaCode ? '+' + this.areaCode + ' ' : '') + (this.phone ?? '');
  }

}

export class TimeLine {
  @Expose() data: any; // 数据
  @Expose() time: number; // 数据
  @Expose() type: string; // 数据

}

export class Pendings {
  @Expose() id: number; // 主键
  @Expose() pendingNo: number; // Pending编号
  @Expose() status: string; // 状态
  @Expose() project: string; // Pending主题
  @Expose() remark: string; // 备注
  @Expose() applyTime: number; // 创建时间
  @Expose() organizer: string; // 发起人
  @Expose() deadlines: number; // 截止时间
  @Expose() messageCount: number; // 留言总数
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() finish: boolean; // 是否完成Pending标志
}

export class PendingDetail {
  @Expose() id: number; // 主键
  @Expose() pendingNo: number; // Pending编号
  @Expose() status: string; // Pending状态
  @Expose() underwritingStatus: UnderwritingStatus; // 新单状态：CHECKING-检查文件;UNDERWRITING-核保中;SENDING-寄出;FINISHED-完成;DECLINED-拒保;CANCELLED-取消;DELETED-删除
  @Expose() policyStatus: PolicyStatus; // 新单状态：CHECKING-检查文件;UNDERWRITING-核保中;SENDING-寄出;FINISHED-完成;DECLINED-拒保;CANCELLED-取消;DELETED-删除
  @Expose() project: string; // Pending主题
  @Expose() remark: string; // 备注
  @Expose() applyTime: number; // 创建时间
  @Expose() organizer: string; // 发起人
  @Expose() deadlines: number; // 截止时间
  @Expose() messageCount: number; // 留言总数
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() finish: boolean; // 是否完成Pending标志
  @Expose() attachments: Material[]; // 附件
  @Expose() receiver: string; // 接收人
  @Expose() companyName: string; // 接收人
  @Expose() operatingTime: number; // 操作人最后操作时间
  @Expose() underwritingNo: string; // 操作人最后操作时间
  @Expose() operator: string; // 操作人
  @Expose() ownerName: string; // 投保人姓名
  @Expose() archiveDate: number; // 建档日期
  @Expose() finishDate: number; // 建档日期
  @Expose() deliveryDate: number; // 送件日期
  @Expose() insuredName: number; // 受保人姓名
  @Expose() ultimate: boolean; // 终态
}


export enum CustomerType {
  INDIVIDUAL = 'INDIVIDUAL', // 个人
  CORPORATE = 'CORPORATE' // 公司
}

// 个人客户 - 投保人/受保人
export class Customer {
  @Expose() customCode: string; // 自定义编号
  @Expose() businessSource: string; // 业务来源
  @Expose() lastName; // 姓
  @Expose() firstName: string; // 名
  @Expose() name: string; // 姓名
  @Expose() lastNamePinyin; // 姓-拼音
  @Expose() firstNamePinyin: string; // 名-拼音
  @Expose() pinyin: string; // 英文姓名
  @Expose() careerInformation: string; // 职业信息
  @Expose() gender: Gender; // 性别
  @Expose() title: Title; // 称谓: Mr-先生;Mrs-夫人;Miss-小姐;Ms-女士;Dr-博士
  @Expose() smoke: SmokeCondition; // 吸烟: S-吸烟;NS-不吸烟
  @Expose() smokeQuantity: string; // 吸烟量
  @Expose() marriage: MarriageStatus; // 婚姻状况: SINGLE-单身;MARRIED-已婚;DIVORCED-离婚;WIDOWED-丧偶
  @Expose() birthday: number; // 出生日期
  @Expose() birthplace: string;
  @Expose() birthdate: string; // 出生日期
  @Expose() country: string; // 国家地区
  @Expose() height: number; // 身高cm
  @Expose() weight: number; // 体重kg
  @Expose() idCard: string; // 身份证
  @Expose() hkIdCard: string; // 出生证
  @Expose() eepCode: string; // 港澳通行证
  @Expose() passport: string; // 护照
  @Expose() areaCode: string; // 区号
  @Expose() phone: string; // 手机号码
  @Expose() email: string; // 电子邮箱
  @Expose() postcode: string; // 受保人邮政编码
  @Expose() education: string; // 教育程度: PRIMARY-小学及以下;SECONDARY-中学;TERTIARY_OR_ABOVE-大专或以上
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() residenceAddress: Address = new Address(); // 户籍地址
  @Expose() residenceAreaCode: string; // 住宅电话区号
  @Expose() residenceTelephone: string; // 住宅电话
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() address: Address = new Address(); // 居住地址
  @Expose() companyName: string; // 公司名称
  @Expose() companyType: string; // 公司行业
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() companyAddress: Address = new Address(); // 公司地址
  @Expose() companyAreaCode: string; // 公司电话区号
  @Expose() companyTelephone: string; // 公司电话
  @Expose() workYear: number; // 公司电话
  @Expose() position: string; // 职位
  @Expose() salary: number; // 月薪
  @Expose() vulnerableCustomer = false; // 需要特别关顾客户
  @Expose() longtimeAbroad = false; // 是否长期在国外
  @Expose() permanentResidentHK: boolean; // 是否香港永居
  @Expose() haveHKIdCard: boolean; // 是否有香港身份证
  @Expose() citizenship = false; // 是否拥有其他国家公民身份
  @Type(() => Phone)
  @Transform((value) => plainToArray(Phone, value.value))
  @Expose() phones: Phone[]; // 联系方式
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() postalAddress: Address = new Address(); // 通讯地址
  @Expose() riskAppetite: string; // 风险偏好: High-高/Medium-中/Low-低
  @Expose() sports: string[]; // 体育: Football-足球/NBA-NBA/Tennis-网球/Badminton-羽毛球/Golf-高尔夫/Volleyball-排球/Swimming-游泳/Cycling-自行车/Gym-健身/Yoga-瑜伽/Others-其他
  @Expose() sportsOther: string; // 体育为其他时的描述
  @Expose() games: string[]; // 游戏: Action-动作/Adventure-冒险/Fighting-战争/Puzzle-拼图/Racing-赛车/Role-playing-角色扮演/Stimulation-刺激/Strategy-策略/Sports-体育/Others-其他
  @Expose() gamesOther: string; // 游戏为其他时的描述
  @Expose() movie: string[]; // 电影: Action-动作/Animation-动画/Horror-恐怖/Comedy-喜剧/Crime-犯罪/Drama-戏剧/Fantasy-幻想/Historical-历史/Mystery-神秘/Romance-浪漫/Science-Fiction-科幻/Others-其他
  @Expose() movieOther: string; // 电影为其他时的描述
  @Expose() cuisine: string[]; // 美食: Chinese-中国/Japanese-日本/Korean-韩国/Singaporean-新加坡/Taiwanese-台湾/Malaysian-马来西亚/European-欧洲/Others-其他
  @Expose() cuisineOther: string; // 美食为其他时的描述
  @Expose() travel: string; // 旅行
  @Expose() optOut = false; // 代表客户是否愿意接受电邮推广宣传 默认false不拒绝 true拒绝
  @Expose() averageMonthlyExpenses: number; // 平均每月支出
  @Expose() averageMonthlyIncome: number; // 平均每月收入
  @Expose() yearInCurrentJob: number; // 受僱於現職年期
  @Expose() totalLiquidAssets: number; // 總流動資產
  @Expose() totalLiabilities: number; // 總債務
  @Expose() noOfDependents: number; // 受供養人數目
  @Expose() remark: string; // 备注
  @Type(() => VIP)
  @Transform((value) => plainToInstance(VIP, Object.assign(new VIP(), value.value)))
  @Expose() vip: VIP = new VIP(); // vip

  phoneString(): string {
    return (this.areaCode ? '+' + this.areaCode + ' ' : '') + (this.phone ?? '');
  }

  officePhoneNumberString(): string {
    return (this.companyAreaCode ? '+' + this.companyAreaCode + ' ' : '') + (this.companyTelephone ?? '');
  }

  residentialPhoneNumberString(): string {
    return (this.residenceAreaCode ? '+' + this.residenceAreaCode + ' ' : '') + (this.residenceTelephone ?? '');
  }
}

export class VIP {
  vip = false; // 是否是VIP
  remark: string; // VIP备注，只有当vip为true才显示
}

// 企业客户 - 投保人/受保人
export class CustomerCorporate {
  @Expose() customCode: string; // 自定义编号
  @Expose() companyName: string; // 公司名稱
  @Expose() companyNameEn: string; // 公司名稱英文
  @Expose() businessRegistrationNumber: string; // 公司商業登記號碼
  @Expose() registrationDate: number; // 公司註冊日期
  @Expose() registrationCountry: string; // 公司註冊地区
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() registeredAddress: Address = new Address(); // 公司登記地址
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() contactAddress: Address = new Address(); // 公司通信地址
  @Expose() areaCode: string; // 公司電話號碼區號
  @Expose() telephone: string; // 公司電話號碼
  @Expose() email: string; // 公司郵箱
  @Expose() vulnerableCustomer: boolean; // 需要特别关顾客户
  @Expose() legalPersonFirstName: string; // 授權代表姓名中文-名
  @Expose() legalPersonLastName: string; // 授權代表姓名中文-姓
  @Expose() legalPersonName: string; // 授權代表姓名中文
  @Expose() legalPersonFirstNameEn: string; // 授權代表姓名英文-名
  @Expose() legalPersonLastNameEn: string; // 授權代表姓名英文-姓
  @Expose() legalPersonNameEn: string; // 授權代表姓名英文
  @Expose() legalPersonPosition: string; // 授權代表職稱
  @Expose() legalPersonAreaCode: string; // 授權代表電話區號
  @Expose() legalPersonPhone: string; // 授權代表電話號碼
  @Type(() => Phone)
  @Transform((value) => plainToArray(Phone, value.value))
  @Expose() phones: Phone[] = []; // 联系方式列表
  @Expose() optOut: boolean; // 代表客户是否愿意接受电邮推广宣传 默认false不拒绝 true拒绝

  officePhoneNumberString(): string {
    return (this.areaCode ? '+' + this.areaCode + ' ' : '') + (this.telephone ?? '');
  }

  legalPersonPhoneNumberString(): string {
    return (this.legalPersonAreaCode ? '+' + this.legalPersonAreaCode + ' ' : '') + (this.legalPersonPhone ?? '');
  }
}

// 受益人快照
export class Beneficiary {
  @Expose() customCode: string; // 自定义编号
  @Expose() email: string; // 邮箱
  @Expose() lastName; // 姓
  @Expose() firstName: string; // 名
  @Expose() lastNamePinyin; // 姓-拼音
  @Expose() firstNamePinyin: string; // 名-拼音
  @Expose() idCard: string; // 身份证
  @Expose() passport: string; // 护照
  @Expose() birthdate: string; // 出生日期
  @Expose() birthday: number; // 出生日期
  @Expose() gender: Gender; // 性别
  @Expose() relation: Relationship; // 与受保人关系
  @Expose() beneficiaryType: string; // 受益人类型
  @Expose() businessRegistrationNumber: string; // 公司商业登记号码
  @Expose() companyName: string; // 公司名称中文
  @Expose() companyNameEn: string; // 公司名称英文
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() contactAddress: Address = new Address(); // 公司通信地址
  @Expose() legalPersonAreaCode: string; // 公司电话区号
  @Expose() legalPersonFirstName: string; // 授权代表姓名中文-名
  @Expose() legalPersonFirstNameEn: string; // 授权代表姓名英文-名
  @Expose() legalPersonLastName: string; // 授权代表姓名中文-姓
  @Expose() legalPersonLastNameEn: string; // 授权代表姓名英文-姓
  @Expose() legalPersonName: string; // 授权代表姓名
  @Expose() legalPersonNameEn: string; // 授权代表姓名英文
  @Expose() legalPersonPhone: string; // 授权代表电话号码
  @Expose() legalPersonPosition: string; // 公司商业登记号码
  @Expose() name: string; // 名字
  @Expose() otherRelationship: string; // 其他关系
  @Expose() pinyin: string; //  拼音
  @Expose() areaCode: string; //  拼音
  @Expose() telephone: string; //  拼音
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() registeredAddress: Address = new Address(); // 公司登记地址
  @Expose() registrationCountry: string; // 公司注册地区
  @Expose() registrationDate: number; // 公司注册日期
  // @Type(() => Phone)
  // @Transform((value) => plainToInstance(Phone, Object.assign(new Phone(), value.value)))
  @Expose() phones: Phone[] = []; // 联系方式s

  @Expose() percentage: number; // 收益比例
  @Expose() remark: string; // 备注
  officePhoneNumberString(): string {
    return (this.areaCode ? '+' + this.areaCode + ' ' : '') + (this.telephone ?? '');
  }

  legalPersonPhoneNumberString(): string {
    return (this.legalPersonAreaCode ? '+' + this.legalPersonAreaCode + ' ' : '') + (this.legalPersonPhone ?? '');
  }


}


// 第二持有人
export class ContingentOwner {
  @Expose() lastName: string; // 姓
  @Expose() firstName: string; // 名
  @Expose() name: string; // 姓名
  @Expose() lastNamePinyin: string; // 姓-拼音
  @Expose() firstNamePinyin: string; // 名-拼音
  @Expose() pinyin: string; // 英文姓名
  @Expose() gender: Gender; // 性别
  @Expose() relation: Relationship; // 与受保人关系
  @Expose() birthday: number; // 出生日期
  @Expose() birthdate: string; // 出生日期
  @Expose() idCard: string; // 身份证
  @Expose() passport: string; // 护照
}

export enum UnderwritingStatus {
  CHECKING = 'CHECKING', // 检查文件
  UNDERWRITING = 'UNDERWRITING', // 核保中
  UNDERWRITE = 'UNDERWRITE', // 已批核
  SENDING = 'SENDING', // 寄出
  FINISHED = 'FINISHED', // 完成
  DECLINED = 'DECLINED', // 拒保
  CANCELLED = 'CANCELLED', // 取消
  DELETED = 'DELETED', // 删除
  PENDING_PREMIUM = 'PENDING_PREMIUM', // 待缴费
  EFFECTIVE = 'EFFECTIVE' // 已生效
}

export enum MeetingPlace {
  INSURANCE = 'INSURANCE',
  BROKER = 'BROKER',
  OTHER = 'OTHER'
}

export enum AirportTransfer {
  PICK_UP = 'PICK_UP',  // 接机
  SEE_OFF = 'SEE_OFF',  // 送机
  NO_NEED = 'NO_NEED', // 不需要
  BOTH = 'BOTH' // 接机&送机
}

export class UnderWritingListResp {
  @Expose() id: number; // 主键
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyNo: string;
  @Expose() newBusinessNumber: string; // 自定义业务编号
  @Expose() accountName: string; // 销售账户名
  @Expose() salesName: string; // 销售姓名
  @Expose() secondAccountName: string; // 销售账户名
  @Expose() secondSalesName: string; // 销售姓名
  @Expose() trAccountName: string; // TR
  @Expose() tr2AccountName: string; // TR2
  @Expose() tr3AccountName: string; // TR3
  @Expose() trName: string; // TR姓名
  @Expose() tr2Name: string; // TR2姓名
  @Expose() tr3Name: string; // TR3姓名
  @Expose() signDate: string; // 客戶签署日期
  @Expose() productCode: string; // 保险产品唯一标识
  @Expose() productName: string; // 产品名称
  @Expose() productYear: string; // 年期规格
  @Expose() companyLogo: string; // 保险公司LOGO
  @Expose() status: UnderwritingStatus; // 新单状态
  @Expose() name: string; // 姓名
  @Expose() pinyin: string; // 拼音
  @Expose() insuredName: string; // 受保人姓名
  @Expose() insuredPinyin: string; // 受保人姓名-拼音
  @Expose() unFinishPending: number; // 未完成的代办事项数
  @Expose() messageCount: number; // 留言数
  @Expose() create: boolean; // 新建提醒标识
  @Expose() reminder: boolean; // 其他消息提醒标识
  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() lastModifiedDate: number; // 最后修改时间
  @Expose() handler: string; // 处理人
  @Expose() handlerName: string; // 处理人昵称
  @Expose() nextRemindDate: number; // 下次跟進日期
  @Expose() custom: boolean;
  @Expose() isLeavingMessage: boolean; // 是否留言可以回复
  @Expose() unReadCount: number; // 留言数未读数
  @Expose() unConfirmCount: number; // 留言数未确认数

}

export class BookingVip {
  @Expose() airportTransfer: AirportTransfer; // 安排機場接送
  @Expose() transportDemand: string; // 接送需求
  @Expose() pickUpTime: number; // 送机时间
  @Expose() arriveFlightNumber: string; // 抵港航班编号
  @Expose() arriveBoardingLocation: string; // 接机上车地点
  @Expose() arriveGetOffLocation: string; // 接机下车地点
  @Expose() arriveContacts: string; // 行程1联系人
  @Expose() arriveContactDetails: string; // 行程1联系人电话
  @Expose() seeOFFTime: number; // 送机日期及时间
  @Expose() leaveFlightNumber: string; // 离港航班编号
  @Expose() leaveBoardingLocation: string; // 送机上车地点
  @Expose() leaveGetOffLocation: string; // 送机下车地点
  @Expose() leaveContacts: string; // 行程2联系人
  @Expose() leaveContactDetails: string; // 行程2联系人电话
  @Expose() oneToOne: boolean; // 专人服务
  @Expose() accompanyTime: number; // 专人服务时间
  @Expose() receivingDriverName: string; // 接机司机姓名
  @Expose() contactDetails: string; // 接机聯繫方式
  @Expose() receivingContactDetails2: string; // 接机聯繫方式2
  @Expose() receivingLicensePlateNumber: string; // 接机车牌号
  @Expose() receivingBodyColor: string; // 接机车身颜色
  @Expose() receivingRemark: string; // 接机备注
  @Expose() sendAccompanyTime: number; // 送机专人服务时间
  @Expose() sendDriverName: string; // 送机司机姓名
  @Expose() sendContactDetails: string; // 送机聯繫方式
  @Expose() sendContactDetails2: string; // 送机聯繫方式2
  @Expose() sendLicensePlateNumber: string; // 送机车牌号
  @Expose() sendBodyColor: string; // 送机车身颜色
  @Expose() sendRemark: string; // 送机备注
}

export class BookingProgress {
  @Expose() booking: number; // 预约信息
  @Expose() productPlan: number; // 产品计划
  @Expose() medicalPlan: number; // 医疗险计划
  @Expose() owner: number; // 投保人
  @Expose() insured: number; // 准受保人
  @Expose() beneficiaries: number; // 受益人
  @Expose() contingentOwner: number; // 第二持有人
  @Expose() healthQuestions: number; // 健康报告
  @Expose() financialNeedsAnalysis: number; // FNA
}

export class Delivery {
  @Expose() insurerToClient: boolean; // insurerToClient
  @Expose() receiveDate: number; // 接收日
  @Expose() sendOutDate: number; // 寄出日
  @Expose() receiptDate: number; // 签收日期
  @Expose() remark: boolean; // 备注
  @Expose() policyShippingStatus: string;
  @Expose() trackingNumber: string;
}

export class Forms {
  @Expose() formId: string; // 表单唯一标识
  @Expose() name: string; // 表单展示名称
}

export class OpenAccountInfo {
  @Expose() openAccountInstructions: string; // 开户須知
  @Expose() openAccountDate: string; // 开户时间
  @Expose() openAccountEndDate: string; // 开户时间段结束
  @Expose() openAccountBankName: string; // 开户银行名称
  @Expose() openAccountBranchBankName: string; // 支行（分行）名称
  @Expose() openAccountAddress: string; // 开户地址
}

export class Underwriting {
  @Expose() id: number; // 主键
  @Expose() underwritingNo: string; // 新单编号
  @Expose() newBusinessNumber: string; // 自定义业务编号
  @Expose() policyNo: string; // 保单号
  @Expose() accountName: string; // 销售账户名
  @Expose() ownerName: string; // 投保人姓名
  @Expose() ownerPinyin: string; // 投保人拼音
  @Expose() productYear: string; // 产品年期
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() approvalDate: number; // 保險公司批核日期(繕發日期)
  @Expose() signDate: number; // 客戶签署日期
  @Expose() submissionDate: number;
  @Expose() backtrack: boolean; // 保单日期回溯
  @Expose() policyDate: number; // 保单日期
  @Expose() policyDeadline: number; // 保单截止日期
  @Expose() paymentNumber: string; // 缴费编号
  @Expose() paymentDate: number; // 保费日期
  @Expose() effectiveDate: number; // 保单生效日
  @Expose() policyAnniversary: number; // 保单周年日
  @Expose() coolingOffDate: number; // 冷静期结束日
  @Expose() companyName: string; // 保险公司名称
  @Expose() region: string; // 地区
  @Expose() companyLogo: string; // 保险公司LOGO
  @Expose() companyCode: string; // 保险公司Code
  @Expose() autoTransfer: boolean; // DDA(自动转账): 1-是;0-否
  @Expose() categoryName: string; // 保险分类名称
  @Expose() branch: string; // branch
  @Expose() salesName: string; // 销售姓名
  @Expose() accompanyName: string; // 陪同姓名
  @Expose() accompanyAreaCode: string; // 陪同手机号区号
  @Expose() accompanyPhone: string; // 陪同手机号
  @Expose() accompanyEmail: string; // 陪同email
  @Expose() temporaryTime: number; // 暂定时间
  @Expose() appointmentTime: number; // 预约时间
  @Expose() appointmentAddress: string; // 预约地址
  @Expose() serviceAgent: string; // 服务代表
  @Expose() serviceAgentName: string; // 服务代表name
  @Expose() serviceAgent2: string; // 服务代表2
  @Expose() serviceAgent2Name: string; // 服务代表2name
  @Expose() prepay: boolean; // 预缴保费: 预缴-1;不预缴-0
  @Expose() prepayPremium: number; // 预付额
  @Expose() discountPrepayPremium: number; // 折扣后预付额
  @Expose() levy: number; // 征费
  @Expose() relationship: Relationship; // 投保人与被保人关系
  @Type(() => ProductPlan)
  @Transform((value) => plainToInstance(ProductPlan, Object.assign(new ProductPlan(), value.value)))

  @Expose() productPlan: ProductPlan = new ProductPlan(); // 产品计划(JSON串)
  @Type(() => MedicalPlan)
  @Transform((value) => plainToInstance(MedicalPlan, Object.assign(new MedicalPlan(), value.value)))
  @Expose() medicalPlan: MedicalPlan = new MedicalPlan();
  @Type(() => BankInfo)
  @Transform((value) => plainToInstance(BankInfo, Object.assign(new BankInfo(), value.value)))
  @Expose() bank: BankInfo; // 银行信息
  @Expose() riders: Rider[];
  @Expose() ownerType: CustomerType = CustomerType.INDIVIDUAL; // 投保人/持有人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Transform((value) => plainToInstance(Customer, Object.assign(new Customer(), value.value)))
  @Expose() owner: Customer = new Customer(); // 投保人/持有人
  @Type(() => CustomerCorporate)
  @Expose() companyOwner: CustomerCorporate = new CustomerCorporate();
  @Expose() insuredType: CustomerType = CustomerType.INDIVIDUAL; // 受保人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() insured: Customer = new Customer(); // 准受保人快照
  @Type(() => CustomerCorporate)
  @Expose() companyInsured: CustomerCorporate = new CustomerCorporate(); // 公司受保人快照
  @Type(() => Beneficiary)
  @Expose() beneficiaries: Beneficiary[]; // 受益人列表快照
  @Type(() => ContingentOwner)
  @Expose() contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
  @Expose() contingentInsured: ContingentOwner = new ContingentOwner(); // 第二受保人快照
  @Expose() applicationType: string; // 申请类型:ILAS_PRODUCT_OR_TOP_UP_APPLICATION-投資相連壽險計劃或額外供款申請;NON_ILAS_PRODUCT_APPLICATION-非投資相連壽險計劃申請;FUND_SWITCHING-基金轉換;MPF_APPLICATION-強積金計劃申請;GENERAL_INSURANCE_APPLICATION-一般保險申請;
  @Expose() fnaId: number; // 关联FNA
  @Expose() proposalId: string; // 关联FNA
  @Expose() status: UnderwritingStatus; //  新单状态：CHECKING-检查文件;UNDERWRITING-核保中;SENDING-寄出;FINISHED-完成;DECLINED-拒保;CANCELLED-取消;DELETED-删除
  @Expose() timeLineVOS: TimeLine[]; // 时间线
  @Expose() pendings: Pendings[]; // 新单事项
  @Expose() arriveTime: number; // 到港時間
  @Expose() leaveTime: number; // 離港時間
  @Expose() meetingPlace: string; // 會面地點
  @Type(() => Customer)
  @Expose() otherOwners: Customer[]; // 其他投保人快照
  @Type(() => Customer)
  @Expose() otherInsureds: Customer[]; // 其他受保人快照
  @Expose() hongkongContactNumberAreaCode: string;
  @Expose() hongkongContactNumber: string;
  @Expose() delivery: Delivery; // 保单支付信息
  @Expose() openBankAccount: boolean; // 是否开户
  @Transform((value) => plainToInstance(OpenAccountInfo, Object.assign(new OpenAccountInfo(), value.value)))
  @Expose() openAccountInfo: OpenAccountInfo; // 开户
  @Expose() healthCheck: boolean; // 是否体检
  @Expose() guarantee: boolean; // BPO
  @Expose() enjoyPolicyDiscount: boolean;
  @Expose() electronicPolicy: boolean;
  @Expose() discountEventsDeadlinesDate: string;
  @Expose() addTime: number; // 预约创建时间
  @Expose() forms: Forms[]; // 新单表单
  @Expose() financing: Forms[]; // 新单表单
  @Expose() attachments: Material[]; // 预约附件
  @Type(() => BookingVip)
  @Expose() vip: BookingVip; // VIP客戶

  @Expose() edit: boolean; // 是否可编辑
  @Expose() nextRemindDate: number; // 下次跟進日期
  @Expose() consultant: string; // consultant
  @Expose() relationshipManager: string; // relationshipManager
  @Expose() special: string; // relationshipManager

  @Expose() financingPremium: number; // 融资金额
  @Expose() financingCurrency: Currency; // 融资金额货币
  @Expose() financingBank: string; // 融资银行名称
  @Expose() financingBankPrincipal: string; // 融资银行负责人
  @Expose() financingBankContactPhone: string; // 融资银行联系电话

  @Expose() trAccountName: string; // TR
  @Expose() tr2AccountName: string; // TR
  @Expose() tr3AccountName: string; // TR
  @Expose() trName: string; // TR姓名
  @Expose() tr2Name: string; // TR2姓名
  @Expose() tr3Name: string; // TR2姓名
  @Expose() trAreaCode: string; // TR电话区号
  @Expose() tr2AreaCode: string; // TR2电话区号
  @Expose() tr3AreaCode: string; // TR3电话区号
  @Expose() trPhone: string; // TR2电话区号
  @Expose() tr2Phone: string; // TR2电话
  @Expose() tr3Phone: string; // TR3电话
  @Expose() trEmail: string; // TR邮箱
  @Expose() tr2Email: string; // TR2邮箱
  @Expose() tr3Email: string; // TR3邮箱

  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() lastModifiedDate: number; // 最后修改时间

  @Expose() handler: string; // 处理人
  @Expose() handlerName: string; // 处理人昵称

  @Type(() => BookingProgress)
  @Expose() bookingProgress: BookingProgress; // 预约进度
  @Expose() custom: boolean;
  @Type(() => DomainMetadataVOS)
  @Expose() domainMetadataVOS: DomainMetadataVOS[]; // 动态字段
  @Expose() hideTr: boolean; // 是否隐藏签单员\服务代表

  ownerNameString(): string {
    let result = '';
    switch (this.ownerType) {
      case CustomerType.INDIVIDUAL:
        result = (this.owner.lastName ?? '') + ' ' + (this.owner.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyOwner.companyName ?? '';
        break;
    }
    return result;
  }

  insuredNameString(): string {
    let result = '';
    switch (this.insuredType) {
      case CustomerType.INDIVIDUAL:
        result = (this.insured.lastName ?? '') + ' ' + (this.insured.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyInsured.companyName ?? '';
        break;
    }
    return result;
  }

  consultantPhoneString(): string {
    return (this.accompanyAreaCode ? '+' + this.accompanyAreaCode + ' ' : '') + (this.accompanyPhone ?? '');
  }

  clientContactNoInHongKong(): string {
    return (this.hongkongContactNumberAreaCode ? '+' + this.hongkongContactNumberAreaCode + ' ' : '') + (this.hongkongContactNumber ?? '');
  }
}

export class DomainMetadataVOS {
  @Expose() fieldId: string; // 字段ID
  @Expose() fieldType: string; // 字段类型
  @Expose() fieldName: string; // 选择值
  @Expose() values: Values[] | any[]; // 选择值
  @Expose() value: string | any; // 值
}

export class Values {
  @Expose() id: string; // ID
  @Expose() name: string; // 名称
}

export class UnderwritingDetailResp {

  @Expose() id: number; // 主键
  @Expose() underwritingNo: string; // 新单编号

  @Expose() newBusinessNumber: string; // 自定义业务编号
  @Expose() policyNo: string; // 保单号

  @Expose() accountName: string; // 销售账户名
  @Expose() secondAccountName: string; // 销售账户名
  @Expose() secondSalesChannel: string;
  @Expose() salesChannel: string;
  @Expose() ownerName: string; // 投保人姓名
  @Expose() ownerPinyin: string; // 投保人拼音

  @Expose() productYear: string; // 产品年期
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称

  @Expose() approvalDate: number; // 保險公司批核日期(繕發日期)
  @Expose() signDate: number; // 客戶签署日期

  @Expose() backtrack: boolean; // 保单日期回溯
  @Expose() authorizeCollect: boolean;
  @Expose() policyDate: number; // 保单日期
  @Expose() policyDeadline: number; // 保单截止日期
  @Expose() paymentDate: number; // 保费日期
  @Expose() effectiveDate: number; // 保单生效日
  @Expose() policyAnniversary: number; // 保单周年日
  @Expose() coolingOffDate: number; // 冷静期结束日
  @Expose() companyName: string; // 保险公司名称
  @Expose() companyLogo: string; // 保险公司LOGO
  @Expose() companyCode: string; // 保险公司Code
  @Expose() autoTransfer: boolean; // DDA(自动转账): 1-是;0-否
  @Expose() categoryName: string; // 保险分类名称
  @Expose() branch: string; // branch
  @Expose() salesName: string; // 销售姓名
  @Expose() accompanyName: string; // 陪同姓名
  @Expose() accompanyAreaCode: string; // 陪同手机号区号
  @Expose() accompanyPhone: string; // 陪同手机号
  @Expose() accompanyEmail: string; // 陪同email
  @Expose() temporaryTime: number; // 暂定时间
  @Expose() appointmentTime: number; // 预约时间
  @Expose() appointmentAddress: string; // 预约地址
  @Expose() prepay: boolean; // 预缴保费: 预缴-1;不预缴-0
  @Expose() prepayPremium: number; // 预付额
  @Expose() relationship: Relationship; // 投保人与被保人关系
  @Type(() => ProductPlan)
  @Expose() productPlan: ProductPlan = new ProductPlan(); // 产品计划(JSON串)
  @Type(() => MedicalPlan)
  @Expose() medicalPlan: MedicalPlan = new MedicalPlan();
  @Type(() => BankInfo)
  @Transform((value) => plainToInstance(BankInfo, Object.assign(new BankInfo(), value.value)))
  @Expose() bank: BankInfo; // 银行信息
  @Expose() rider: Rider[];
  @Expose() ownerType: CustomerType = CustomerType.INDIVIDUAL; // 投保人/持有人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() owner: Customer = new Customer(); // 投保人/持有人
  @Type(() => CustomerCorporate)
  @Expose() companyOwner: CustomerCorporate = new CustomerCorporate();
  @Expose() insuredType: CustomerType = CustomerType.INDIVIDUAL; // 受保人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() insured: Customer = new Customer(); // 准受保人快照
  @Type(() => CustomerCorporate)
  @Expose() companyInsured: CustomerCorporate = new CustomerCorporate(); // 公司受保人快照
  @Type(() => Beneficiary)
  @Expose() beneficiaries: Beneficiary[]; // 受益人列表快照
  @Type(() => ContingentOwner)
  @Expose() contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
  @Expose() applicationType: string; // 申请类型:ILAS_PRODUCT_OR_TOP_UP_APPLICATION-投資相連壽險計劃或額外供款申請;NON_ILAS_PRODUCT_APPLICATION-非投資相連壽險計劃申請;FUND_SWITCHING-基金轉換;MPF_APPLICATION-強積金計劃申請;GENERAL_INSURANCE_APPLICATION-一般保險申請;
  @Expose() fnaId: number; // 关联FNA
  @Expose() proposalId: string; // 关联FNA
  @Expose() status: UnderwritingStatus; //  新单状态：CHECKING-检查文件;UNDERWRITING-核保中;SENDING-寄出;FINISHED-完成;DECLINED-拒保;CANCELLED-取消;DELETED-删除
  @Expose() timeLineVOS: TimeLine[]; // 时间线
  @Expose() pendings: Pendings[]; // 新单事项
  @Expose() arriveTime: number; // 到港時間
  @Expose() leaveTime: number; // 離港時間
  @Expose() meetingPlace: string; // 會面地點
  @Type(() => Customer)
  @Expose() otherOwners: Customer[]; // 其他投保人快照
  @Type(() => Customer)
  @Expose() otherInsureds: Customer[]; // 其他受保人快照
  @Expose() hongkongContactNumberAreaCode: string;
  @Expose() hongkongContactNumber: string;
  @Expose() delivery: Delivery; // 保单支付信息
  @Expose() openBankAccount: boolean; // 是否开户
  @Transform((value) => plainToInstance(OpenAccountInfo, Object.assign(new OpenAccountInfo(), value.value)))
  @Expose() openAccountInfo: OpenAccountInfo; // 开户
  @Expose() healthCheck: boolean; // 是否体检
  @Expose() guarantee: boolean; // BPO
  @Expose() addTime: number; // 预约创建时间
  @Expose() forms: Forms[]; // 新单表单
  @Expose() financing: Forms[]; // 新单表单

  @Type(() => BookingVip)
  @Expose() vip: BookingVip; // VIP客戶

  @Expose() edit: boolean; // 是否可编辑
  @Expose() nextRemindDate: number; // 下次跟進日期
  @Expose() consultant: string; // consultant
  @Expose() relationshipManager: string; // relationshipManager
  @Expose() special: string; // relationshipManager

  @Expose() financingPremium: number; // 融资金额
  @Expose() financingCurrency: Currency; // 融资金额货币
  @Expose() financingBank: string; // 融资银行名称
  @Expose() financingBankPrincipal: string; // 融资银行负责人
  @Expose() financingBankContactPhone: string; // 融资银行联系电话

  @Expose() trAccountName: string; // TR
  @Expose() tr2AccountName: string; // TR
  @Expose() tr3AccountName: string; // TR
  @Expose() trName: string; // TR姓名
  @Expose() tr2Name: string; // TR2姓名
  @Expose() tr3Name: string; // TR2姓名
  @Expose() trAreaCode: string; // TR电话区号
  @Expose() tr2AreaCode: string; // TR2电话区号
  @Expose() tr3AreaCode: string; // TR2电话区号
  @Expose() trPhone: string; // TR2电话区号
  @Expose() tr2Phone: string; // TR2电话
  @Expose() tr3Phone: string; // TR2电话
  @Expose() trEmail: string; // TR邮箱
  @Expose() tr2Email: string; // TR2邮箱
  @Expose() tr3Email: string; // TR2邮箱

  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() lastModifiedDate: number; // 最后修改时间

  @Expose() handler: string; // 处理人
  @Expose() handlerName: string; // 处理人昵称

  @Type(() => BookingProgress)
  @Expose() bookingProgress: BookingProgress; // 预约进度
  @Expose() custom: boolean;

  ownerNameString(): string {
    let result = '';
    switch (this.ownerType) {
      case CustomerType.INDIVIDUAL:
        result = (this.owner.lastName ?? '') + ' ' + (this.owner.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyOwner.companyName ?? '';
        break;
    }
    return result;
  }

  insuredNameString(): string {
    let result = '';
    switch (this.insuredType) {
      case CustomerType.INDIVIDUAL:
        result = (this.insured.lastName ?? '') + ' ' + (this.insured.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyInsured.companyName ?? '';
        break;
    }
    return result;
  }

  consultantPhoneString(): string {
    return (this.accompanyAreaCode ? '+' + this.accompanyAreaCode + ' ' : '') + (this.accompanyPhone ?? '');
  }

  clientContactNoInHongKong(): string {
    return (this.hongkongContactNumberAreaCode ? '+' + this.hongkongContactNumberAreaCode + ' ' : '') + (this.hongkongContactNumber ?? '');
  }

  toUnderwriting(): Underwriting {
    return plainToClass(Underwriting, this, {excludeExtraneousValues: true});
  }
}

export class BookingInfoReq {
  @Expose() bookingNo: string; // 预约编号
  @Expose() temporaryTime: number; // 暂定时间
  @Expose() accompanyName: string; // 陪同姓名
  @Expose() accountName: string; // 销售账户名
  @Expose() accompanyAreaCode: string; // 陪同手机号区号
  @Expose() accompanyPhone: string; // 陪同手机号
  @Expose() accompanyEmail: string; // 陪同email
  @Expose() arriveTime: number; // 抵港时间
  @Expose() leaveTime: number; // 离港时间
  @Expose() healthCheck: boolean; // 是否体检
  @Expose() preUnderwriting: boolean; // 是否预核保
  @Expose() openBankAccount: boolean; // 是否需要开户
  @Transform((value) => plainToInstance(OpenAccountInfo, Object.assign(new OpenAccountInfo(), value.value)))
  @Expose() openAccountInfo: OpenAccountInfo; // 开户
  @Expose() meetingPlace: MeetingPlace; // 是否需要开户
  @Expose() hongkongContactNumberAreaCode: string; // 客户在港联系电话区号
  @Expose() hongkongContactNumber: string; // 客户在港联系电话
  @Expose() vip: BookingVip; // VIP信息
  @Expose() handler: string; // 处理人
  @Expose() applicationType: string; // 申请类型
  @Expose() appointmentAddress: string; // 预约地址
  @Expose() consultant: string; // consultant
  @Expose() branch: string; // branch
  @Expose() relationshipManager: string; // branch
  @Expose() guarantee: boolean; // 参加递增保障权益BPO
  @Expose() hongKongInsurance: boolean; // 是否购买过香港保险
  @Expose() serviceAgent: string; // 服务代表
  @Expose() serviceAgent2: string; // 服务代表
}

export class ProductPlanReq {
  @Expose() bookingNo: string; // 预约编号
  @Expose() productCode: string; // 保险产品唯一标识
  @Expose() backtrack: boolean; // 保单日期回溯
  @Expose() policyDate: number; // 保单日期
  @Expose() policyDeadline: number; // 保单截止日期
  @Expose() riders: Rider[]; // 附加险
  @Expose() guarantee: boolean; // BPO
  @Expose() prepay: boolean; // 预缴保费: 预缴-1;不预缴-0
  @Expose() prepayPremium: number; // 预付额

  @Type(() => ProductPlan)
  @Expose() productPlan: ProductPlan = new ProductPlan(); // 产品计划
  @Expose() financing: boolean; // 是否融资
  @Expose() financingPremium: number; // 融资金额
  @Expose() financingCurrency: Currency; // 融资金额货币
  @Expose() financingBank: string; // 融资银行名称
  @Expose() financingBankPrincipal: string; // 融资银行负责人
  @Expose() financingBankContactPhone: string; // 融资银行联系电话
  @Expose() discountEventsDeadlinesDate: string;
  @Expose() enjoyPolicyDiscount: boolean;
  @Expose() electronicPolicy: boolean;
}

export class BookingOwnerReq {
  @Expose() bookingNo: string; // 预约编号
  @Expose() ownerType: CustomerType; // 投保人/持有人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Transform((value) => plainToInstance(Customer, Object.assign(new Customer(), value.value)))
  @Expose() owner: Customer = new Customer(); // 投保人/持有人
  @Type(() => CustomerCorporate)
  @Expose() companyOwner: CustomerCorporate;
  @Type(() => ContingentOwner)
  @Expose() contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
}

export class BookingInsuredReq {
  @Expose() bookingNo: string; // 预约编号
  @Expose() relationship: Relationship; // 投保人与被保人关系
  @Expose() insuredType: CustomerType; // 投保人/持有人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() insured: Customer = new Customer(); // 投保人/持有人
  @Type(() => CustomerCorporate)
  @Expose() companyInsured: CustomerCorporate;
  @Expose() contingentInsured: ContingentOwner = new ContingentOwner(); // 第二受保人快照
}

export class BookingBeneficiaryReq {
  @Expose() bookingNo: string; // 预约编号
  @Expose() beneficiaries: Beneficiary[]; // 受益人
}

export class UnderwritingBeneficiaryReq {
  @Expose() underwritingNo: string; // 新单编号
  @Expose() beneficiaries: Beneficiary[]; // 受益人
}

export class BankInfoReq {
  @Expose() underwritingNo: string; // 新单编号
  @Type(() => BankInfo)
  @Expose() bank: BankInfo = new BankInfo(); // 银行信息
}

export class BookingAssociateProposalReq {
  @Expose() bookingNo: string; // 预约编号
  @Expose() proposalId: string; // 计划书
}

export class BookingAssociateFnaReq {
  @Expose() bookingNo: string; // 预约编号
  @Expose() fnaId: number; // 计划书
}

export class BookingAttachmentReq {
  @Expose() bookingNo: string; // 预约编号
  @Expose() attachments: Material[]; // 附件
}

export enum AmlRecordType {
  PERSON = 'PERSON',  // 个人
  BUSINESS = 'BUSINESS' // 公司
}

export enum AmlRecordStatus {
  NORMAL = 'NORMAL',
  SUSPICIOUS = 'SUSPICIOUS',
  DANGEROUS = 'DANGEROUS',
  WARNING = 'WARNING',
  FAILED = 'FAILED',
}

export enum AmlMonitorStatus {
  NORMAL = 'NORMAL',
  WARNING = 'WARNING',
}

export class AmlSearch extends PagedReq {
  @Expose() type: AmlRecordType; // 类型
  @Expose() name: string; // 客户姓名
  @Expose() status: AmlRecordStatus; // 检查结果
  @Expose() createTime: number; // 创建时间
  @Expose() gender: number; // 性别
  @Expose() beginTime: number; // 创建时间（开始）
  @Expose() endTime: number; // 创建时间（结束）
}

export class AmlMonitorSearch extends PagedReq {
  @Expose() type: AmlRecordType; // 类型
  @Expose() name: string;
  @Expose() surname: string; // 客户姓
  @Expose() forename: string; // 客户名
  @Expose() customCode: string; // 客户编号
  @Expose() monitorStatus: AmlMonitorStatus; // 状态
  @Expose() createTime: number; // 创建时间
  @Expose() beginTime: number; // 创建时间（开始）
  @Expose() endTime: number; // 创建时间（结束）
}

export class AmlMonitorSummary extends PagedReq {
  @Expose() type: AmlRecordType; // 类型
  @Expose() createTime: string; // 创建时间

}

export class AmlSummary extends PagedReq {
  @Expose() summaryId: number; // summary id
}

export class AmlInspectReq {
  @Expose() surname: string; // 姓氏
  @Expose() forename: string; // 名字
  @Expose() businessName: string; // 企業名字
  @Expose() name: string; // 公司名
  @Expose() englishName: string; // 公司名
  @Expose() country: string; // 国家/地区
  @Expose() gender: string;
  @Expose() englishForename: string; // 国家/地区
  @Expose() englishSurname: string; // 国家/地区
  @Expose() dateOfBirth: string; // 生日(格式：yyyy-MM-dd)
  @Expose() birthday: string;
  @Expose() idCard: string;
}


export class AmlListResp {
  @Expose() id: number; // 主键
  @Expose() name: string; // 名字
  @Expose() englishName: string; // 名字
  @Expose() englishForename: string; // 名字
  @Expose() englishSurname: string; // 名字
  @Expose() surname: string; // 名字
  @Expose() forename: string; // 名字
  @Expose() createTime: number; // 检查时间
  @Expose() type: AmlRecordType; // 类型
  @Expose() status: AmlRecordStatus; // 预约开始时间
  @Expose() customCode: string; // 客户自定义编号
  @Expose() startMonitorDate: number; // 开始监测时间
  @Expose() newestMonitorDate: number; // 最新监测时间
  @Expose() monitorStatus: any; // 监测状态

}

export class AmlSummaryList {
  @Expose() id: number; // 主键
  @Expose() date: string; // 日期
  @Expose() entryCounts: number; // 运行的 entry 数
  @Expose() openAlertCounts: number; // 匹配的 alert 数
}

export class AMLSummaryDetail {
  @Expose() id: number; // 主键
  @Expose() customerId: number; // 客户id
  @Expose() customCode: string; // 客户自定义编号
  @Expose() forename: string; // 名
  @Expose() surname: string; // 姓
  @Expose() name: string; // 姓名
  @Expose() startMonitorDate: number; // 开始监测时间
  @Expose() monitorStatus: string; // 监测状态
  @Expose() type: string; // 类型
  @Expose() newestMonitorDate: number; // 最新监测时间

}

export class AmlSearchCondition {
  @Expose() type: AmlRecordType; // 类型
  @Expose() dateOfBirth: string; // 生日
  @Expose() surname: string; // 姓
  @Expose() forename: string; // 名
  @Expose() englishForename: string; // 名
  @Expose() englishSurname: string; // 姓
  @Expose() country: string; // 国家/地区
  @Expose() gender: string;
  @Expose() businessName: string; // 预约开始时间
  @Expose() englishBusinessName: string;
}

export class AmlResultItem {
  @Expose() name: string; // 预约开始时间
  @Expose() id: number; // 第三方id
  @Expose() birthday: string; // 生日
  @Expose() gender: string; // 性别
  @Expose() score: number; // 得分
  @Expose() artificialStatus: string; // 人工确认状态
  @Expose() artificialRemark: string; // 人工确认备注
}

export class AmlInfo {
  @Expose() searchRecordId: number; // 主键
  @Expose() searchCondition: AmlSearchCondition; // 搜索条件
  @Expose() searchResult: AmlResultItem[]; // 搜索结果
}

export class AmlMonitorCondition {
  @Expose() birthday: string; // 生日
  @Expose() country: string; // 国家/地区
  @Expose() englishForename: string; // 名
  @Expose() englishSurname: string; // 名
  @Expose() forename: string; // 名
  @Expose() surname: string; // 姓
  @Expose() gender: string;
  @Expose() type: AmlRecordType; // 类型
  @Expose() businessName: number; // 公司
  @Expose() id: number; // id
}

export class AmlMonitorCommentItem {
  @Expose() accountName: string;
  @Expose() addTime: number;
  @Expose() comment: string;
  @Expose() matchId: number;
  @Expose() status: string;
}

export class AmlMonitorResultItem {
  @Expose() name: string; // 预约开始时间
  @Expose() matchId: number; // 第三方id
  @Expose() birthday: string; // 生日
  @Expose() matchScore: number; // 得分
  @Expose() qrCode: number;
  @Expose() matchComments: AmlMonitorCommentItem[];
}

export class AmlMonitorInfo {
  @Expose() entry: AmlMonitorCondition;
  @Expose() entryMatchList: AmlMonitorResultItem[]; // 搜索结果
}

export class AmlArtificialStatusUpdateReq {
  @Expose() id: number; // 记录id
  @Expose() thirdPartyId: number; // 第三方id
  @Expose() artificialStatus: string; // 人工状态
  @Expose() artificialRemark: string[]; // 人工状态备注
}

export class AmlMonitorArtificialStatusUpdateReq {
  @Expose() entryId: number; // 记录id
  @Expose() matchId: number; // 第三方id
  @Expose() status: string; // 人工状态
  @Expose() comment: string[]; // 人工状态备注
}


export class AmlThirdPartPDFReq {
  @Expose() type: AmlRecordType; // PERSON/BUSINESS
  @Expose() thirdPartId: number; // 第三方id
}

export class SurrenderValue {
  @Expose() age: number; // 保单年龄
  @Expose() year: number; // 保单年度
  @Expose() totalPremiumsPaid: number; // 繳付保費總額
  @Expose() guaranteed: number; // 保證現金價值
  @Expose() reversionaryBonus: number; // 非保證金額-累積歸原紅利
  @Expose() specialBonus: number; // 非保證金額-特別紅利
  @Expose() total: number; // 總退保價值
  @Expose() withdrawnAmount: number; // 提取金额
  @Expose() rateOfReturn: number; // 回报率
  @Expose() annualRateOfReturn: number; // 年回报率
  @Expose() internalRateOfReturn: number; // 内部回报率
}

export class DeathBenefit {
  @Expose() age: number; // 保单年龄
  @Expose() year: number; // 保单年度
  @Expose() totalPremiumsPaid: number; // 繳付保費總額
  @Expose() sumAssured: number; // 保證金額-基本計劃之保額
  @Expose() additionalBenefit: number; // 保證金額-額外身故賠償
  @Expose() totalOfEnsure: number; // 保證金額-總額
  @Expose() guaranteed: number; // 保證現金價值
  @Expose() additionalGuaranteed: number; // 額外保證現金價值
  @Expose() reversionaryBonus: number; // 非保證金額-累積歸原紅利
  @Expose() specialBonus: number; // 非保證金額-特別紅利
  @Expose() total: number; // 總退保價值
  @Expose() withdrawnAmount: number; // 提取金额
  @Expose() rateOfReturn: number; // 回报率
  @Expose() annualRateOfReturn: number; // 年回报率
  @Expose() internalRateOfReturn: number; // 内部回报率
}

export class ValueScenario<T> {
  currentAssumed: T[]; // 現時預計
  optimisticScenario: T[]; // 樂觀情景
  pessimisticScenario: T[]; // 悲觀情景
}

export class ChartData {
  name: string;
  series: ChartSeries[] = [];
}

export class ChartSeries {
  name: string;
  value: number;
}

export enum TicketStatus {
  PROCESSED = 'PROCESSED', // 已处理
  PROCESSING = 'PROCESSING', // 处理中
}

export class TicketSearch extends PagedReq {
  @Expose() accountName: string; // 销售渠道
  @Expose() title: string; // 工单标题
  @Expose() content: string; // 内容
  @Expose() status: TicketStatus; // 工单状态
  @Expose() id: string; // 問題編號
}

export class TicketListResp {
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() messageCount: number; // 留言数
  @Expose() addTime: number; // 提交时间
  @Expose() reminder: boolean; // 其他消息提醒标识
  @Expose() accountName: string; // 销售渠道
  @Expose() salesName: string; // 销售渠道
  @Expose() updateTime: number; // 跟新时间
  @Expose() avatar: string; // 账户头像
  @Expose() title: string; // 标题
  @Expose() content: string; // 回复内容
  @Expose() name: string; // 最近回复的账户名
  @Expose() create: boolean; // 新建提醒标识
  @Expose() id: number; // 工单编号
  @Expose() policyNos: string[] = []; // 保單號
  @Expose() bookingNos: string[] = [];
  @Expose() underwritingNos: string[] = [];
  @Expose() policyNoToPolicyIdMap: object = {}; // 保單號
  @Expose() status: TicketStatus; // 工单状态
}

export class TicketReply {
  @Expose() ticketId: number; // 工单编号
  @Expose() attachments: Material[]; // 文件
  @Expose() addTime: number; // 回复时间
  @Expose() accountName: string; // 当前登录回复账户
  @Expose() myReply: boolean; // 用于区别回复是不是本人
  @Expose() id: number; // 工单回复编号
  @Expose() avatar: string; // 账户头像
  @Expose() content: string; // 回复内容
}

export class TicketDetail {
  @Expose() replies: TicketReply[]; // 回复详情
  @Expose() id: number; // 工单编号
  @Expose() title: string; // 标题
  @Expose() status: TicketStatus; // 工单状态
}

export class TicketDetailReplyResp {
  @Expose() attachments: Material[]; // 文件
  @Expose() ticketId: number; // 工单编号
  @Expose() content: string; // 回复内容
}

export class TicketCreateResp {
  @Expose() attachments: Material[]; // 文件
  @Expose() title: string; // 标题
  @Expose() content: string; // 回复内容
  @Expose() policyNos: string[]; // 关联保单号
}

export class UnderwritingAttachmentReq {
  @Expose() underwritingNo: string; // 预约编号
  @Expose() attachments: Material[]; // 附件
}

export enum PendTypes {
  BOOKING = 'BOOKING',
  POLICY = 'POLICY',
  UNDERWRITING = 'UNDERWRITING'
}

class AttachmentHeaders {
  @Expose() attachmentId: string;
  @Expose() label: string;
  @Expose() type: string;
  @Expose() value: string;
  @Expose() endTime: any;
  @Expose() inputValue: any;
  @Expose() startTime: any;
}

class Attachment<T> {
  @Expose() headers: any;
  @Expose() name: string;
  @Expose() type: string;
  @Expose() values: any;
}

class Answer {
  @Expose() answerId: string;
  @Expose() label: string;
  @Expose() attachment: Attachment<AnswerItem>;
}

class AnswerItem {
  @Expose() attachmentId: string;
  @Expose() inputValue: string;
  @Expose() endTime: string;
  @Expose() startTime: string;
}

export class QuestionKey {
  @Expose() answer: Answer[];
  @Expose() qid: string;
  @Expose() question: string;
  @Expose() questionId: number;
  @Expose() value: string;
  @Expose() index: number;
}

export class QuestionInfo {
  @Expose() questionnaire: QuestionKey[];
  @Expose() title: string;
  @Expose() type: string;
}


export class AnswerInfo {
  @Expose() value: string;
  @Expose() questionId: number;
  @Expose() attachment: any;
}

export class HeathInfo {
  @Expose() answerOwnerId: number; // 申请人答案ID
  @Expose() answerInsuredId: number; // 受保人答案ID
  @Expose() id: number; // 主键
  @Expose() questionOwner: QuestionInfo[]; // 申请人问卷JSON
  @Expose() answerOwner: AnswerInfo[]; // 申请人答案JSON
  @Expose() answerInsured: AnswerInfo[]; // 受保人答案JSON
  @Expose() title: string; // 问卷标题
  @Expose() introduction: string; // 问卷简介
  @Expose() questionInsured: QuestionInfo[]; // 受保人问卷JSON
  @Expose() ownerTotal: number;
  @Expose() insuredTotal: number;
  @Transform((value) => plainToInstance(OtherAnswer, Object.assign(new OtherAnswer(), value.value)))
  @Expose() ownerOtherAnswer: OtherAnswer = new OtherAnswer();
  @Transform((value) => plainToInstance(OtherAnswer, Object.assign(new OtherAnswer(), value.value)))
  @Expose() insuredOtherAnswer: OtherAnswer = new OtherAnswer();
}

export class FoList {
  @Expose() id: number;
  @Expose() role: string;
  @Expose() answer: string;
  @Expose() otherAnswer: OtherAnswer;
}

export class OtherAnswer {
  @Expose() otherDiseases: string[] = [];
}

export class HealthInfoAnswer {
  @Expose() bookingNo: string;
  @Expose() policyNo: string;
  @Expose() policyId: string;
  @Expose() questionId: number;
  @Expose() underwritingNo: string;
  @Expose() foList: FoList[];
}

export class HealthType {
  @Expose() ownerType: CustomerType;
  @Expose() relationship: Relationship;
  @Expose() edit: boolean;
}

export class PendingReply {
  @Expose() replyTime: number; // 回复时间
  @Expose() lastName: number; // 姓
  @Expose() attachments: Material[]; // 附件
  @Expose() accountName: string; // 当前登录回复账户
  @Expose() accountType: string; // 账户类型: STAFF-职工;SALES-销售
  @Expose() myReply: boolean; // 用于区别回复是不是本人
  @Expose() avatar: string; // 头像
  @Expose() content: string; // 回复内容
  @Expose() time: number; // 对话时间：计划书留言列表
  @Expose() needConfirm: boolean; // 是否需要确认
  @Expose() confirm: boolean; // 是否已确认
  @Expose() id: number; // 编号
}

export class PendingDetailReply {
  @Expose() attachments: Material[]; // 附件
  @Expose() pendingId: number; // Pending主键
  @Expose() underwritingNo: number; // Pending主键
  @Expose() policyId: string; // 保单编号
  @Expose() serviceId: number; // service主键
  @Expose() content: string; // 回复内容
}

export class DialogReply {
  @Expose() proposalId: string; // 计划书新增留言编号
  @Expose() bookingNo: string;
  @Expose() underwritingNo: string;
  @Expose() policyId: string; // 保单编号
  @Expose() content: string; // 回复内容
  @Expose() attachments: Material[]; // 附件
  @Expose() needConfirm: string; // 是否需要确认
  @Expose() confirm: string; // 是否已确认
}


export enum signedStatus {
  SIGNED = 'SIGNED',
  UNSIGNED = 'UNSIGNED'
}

export class SignedData {
  @Expose() fileName: string;
  @Expose() filePath: string;
  @Expose() signedStatus: string;
  @Expose() uploadTime: string;
  @Expose() title: string;
  @Expose() signer: string;
}

export class Credentials {
  @Expose() fileName: string;
  @Expose() author: string;
  @Expose() filePath: string;
  @Expose() uploadTime: string;
  @Expose() tags: string[];
}

export class SignatureList {
  @Expose() bookingNo: string; // 預約編號
  @Expose() isSigned: number; // 是否需要签名
  @Expose() credentials: Material[]; // 凭证文件
  @Expose() policyNo: string; // 保单编号
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyId: string; // 保单编号
  @Expose() signedData: SignedData[]; // 签名数据
  @Expose() bookingFormName: string; // 表单唯一标识
  @Expose() signedDate: number; // 签名日期
  @Expose() id: number; // id
  @Expose() bookingFormId: string; // 表单唯一标识
  @Expose() status: string; // 表单状态 UNCOMPLETED-未完成;COMPLETED-已完成
}

export class CashQuestionData {
  @Expose() answerId: number; // 答案ID
  @Expose() question: any; // 问卷JSON
  @Expose() answer: any; // 答案JSON
  @Expose() id: number; // 主键
  @Expose() title: string; // 问卷标题
  @Expose() introduction: string; // 问卷标题
}

export class Versions {
  version: string;
  flag: boolean;
  text: any[];
}

export class DataDesensitization {
  @Expose() mines: Mines[]; // 我的脱敏字典集合
  @Expose() subordinates: Mines[]; // 下属脱敏字典集合
}

export class Mines {
  @Expose() id: number; // 我的脱敏字典集合
  @Expose() name: string; // 字段名
  @Expose() type: string; // 隐私类型：MINE-我的;SUBORDINATE-下属渠道
  @Expose() mask: boolean; // 加密；1-加，0-不加
}

/**
 * 国家地区
 */
export class Country {
  @Expose() name: string;
  @Expose() areaCode: string;
  @Expose() countryCode: string;
  @Expose() group: string;
}

/**
 * 国家地区分组
 */
export class CountryGroups {
  @Expose() hotCountries: Country[];
  @Expose() groupCountries: Country[];
}

/**
 * 附件类型
 */
export class MaterialType {
  @Expose() fileType: string;
  @Expose() name: string;
  @Expose() show: boolean;
  @Expose() permission: any[];
}

/**
 * 附件分组类
 */
export class MaterialGroupType {
  @Expose() type: string;
  @Expose() files: Material[];
  @Expose() active: boolean;
  @Expose() count: 0;
}

