import { Expose, plainToInstance, Transform, Type } from 'class-transformer';
import {
  Address,
  Currency,
  DeathBenefit,
  Frequency,
  Gender,
  Material,
  MedicalLevel,
  MedicalRegion,
  PagedReq,
  ProposalAmountType, Region,
  Relationship,
  SmokeCondition,
  SupplementaryBenefit,
  SurrenderValue,
  ValueScenario
} from '../api/types';
import { plainToArray } from '../shared/utils/class-transform';
import { DynamicField, Product, RiderDynamicField } from '../product/product-types';

export class Withdrawal {
  @Expose() name: string; // 提取计划名
  @Expose() from: number; // 起始周年
  @Expose() to: number; // 起始周年
  @Expose() amount: number; // 每年提取金额
  @Expose() max = false; // 最大提取

  str = '';
}

export class Proposal {
  @Expose() proposalId: string; // 计划书编号
  @Expose() productCode: string; // 產品碼
  @Expose() companyName: string; // 保險公司
  @Expose() companyLogo: string; // 保險公司logo
  @Expose() productName: string; // 保險產品
  @Expose() categoryCode: string;
  @Expose() categoryName: string;
  @Expose() companyCode: string; // 保險公司码
  @Expose() language: string; // 计划书语言
  @Expose() currency: Currency; // 货币
  @Expose() paymentTerm: string; // 供款年期
  @Expose() contentImage: Material; // 长图
  @Expose() frequency: Frequency; // 付款频率
  @Expose() prepay: boolean; // 是否预缴
  @Expose() lastName: string; // 姓氏
  @Expose() prepayYear: number;
  @Expose() pdfPremium: number;
  @Expose() firstDaySurrenderValue: number; // 首日退保價值
  @Expose() firstName: string; // 名字
  @Expose() birthday: number; // 生日
  @Expose() age: number; // 年龄
  @Expose() gender: Gender; // 性别
  @Expose() smoke: SmokeCondition; // 是否吸烟
  @Expose() smokeQuantity: string; // 吸烟量
  @Expose() residenceRegion: string; // 地区
  @Type(() => ProposalOwner)
  @Transform((value) => plainToInstance(ProposalOwner, Object.assign(new ProposalOwner(), value.value)))
  @Expose() owner: ProposalOwner = new ProposalOwner(); // 投保人
  @Expose() relationship: Relationship = Relationship.OWN; // 关系
  @Expose() proposalAmountType: ProposalAmountType; // 计划书金额类型: PREMIUM-保费, SUM_ASSURED-保额
  @Expose() sumAssured: number; // 保额
  @Expose() premium: number; // 保费
  @Expose() premiumTotal: number; // 总保费
  @Expose() reduceBasic: boolean; // 是否允許減少基本金額
  @Transform((value) => plainToArray(Riders, value.value))
  @Expose() riders: Riders[] = []; // 附加险
  @Expose() doHedge: boolean; // 是否制作对冲方案
  @Transform((value) => plainToInstance(HedgePlan, Object.assign(new HedgePlan(), value.value)))
  @Expose() hedgePlan: HedgePlan = new HedgePlan(); // 对冲方案

  @Expose() coverageRegion: MedicalRegion; // 医疗险特有条件:保障地区
  @Expose() medicalLevel: MedicalLevel; // 医疗险特有条件:等级
  @Expose() deductible: number; // 医疗险特有条件:自付额
  @Expose() supplementaryBenefits: SupplementaryBenefit[]; // 医疗险特有条件:附加保障(多选)

  @Expose() doPremiumOffset: boolean; // 是否保费扣除
  @Expose() premiumOffsetStartYear: number; // 保费扣除开始年度
  @Expose() premiumOffsetEndYear: number; // 保费扣除结束年度
  @Expose() doWithdrawals: boolean; // 是否制作提取计划
  @Expose() clean: boolean; // 是否清理
  @Expose() doFlexiPremium: boolean; // 是否弹性保费
  @Expose() flexiPremiumOption: number; // 弹性保费选项（年）
  @Expose() proposalRiders: string[]; // 制作计划书时可用附加险列表

  @Type(() => Withdrawal)
  @Transform((value) => plainToArray(Withdrawal, value.value))
  @Expose() withdrawals: Withdrawal[] = []; // 提取计划
  @Expose() isWithdrawal: boolean; // 是否有现金提取

  @Expose() special: string; // 特殊说明
  @Expose() status: ProposalStatus; // 状态
  @Expose() statusNote: string; // 状态说明
  @Expose() salesStatusNote: string; // 新状态说明
  @Expose() url: Material; // 文件信息
  @Expose() doable: boolean; // 可製作
  @Expose() failure: boolean; // 是否失敗

  @Expose() extracted: boolean; // 是否做了内容提取
  @Expose() dynamicFields: DynamicField[];
  @Transform((value) => plainToArray(RiderDynamicField, value.value))
  @Expose() riderDynamicFields: RiderDynamicField[];
  @Expose() surrenderValue: ValueScenario<SurrenderValue>; // 退保发还金额（无提取）
  @Expose() deathBenefit: ValueScenario<DeathBenefit>; // 身故赔偿（无提取）
  @Expose() surrenderValueWithdrawal: ValueScenario<SurrenderValue>; // 退保发还金额（做提取）
  @Expose() deathBenefitWithdrawal: ValueScenario<DeathBenefit>; // 身故赔偿（做提取）

  @Expose() fnaId: number; // fna编号
  @Expose() newFnaId: number; // 新fna编号
  @Expose() doPremiumFinancing: boolean; // 是否保费融资
  @Type(() => ProposalPremiumFinancing)
  @Transform((value) => plainToInstance(ProposalPremiumFinancing, Object.assign(new ProposalPremiumFinancing(), value.value)))
  @Expose() premiumFinancing: ProposalPremiumFinancing = new ProposalPremiumFinancing(); // 保费融资

  canRetry(): boolean {
    return this.doable && this.failure;
  }
}

export class ProposalSearch extends PagedReq {
  proposalId: string; // 计划书编号
  status: ProposalStatus; // 狀態
  productCode: string; // 產品碼
  companyCode: string; // 公司码
  categoryCode: string; // 险种码
  accountName: string; // 账户名
  age: number; // 年龄
  gender: Gender; // 性别
  smoke: SmokeCondition; // 是否吸烟
  applicationTime: number; // 申请时间
  startApplicationTime: number; // 开始时间
  endApplicationTime: number; // 结束时间

  @Type(() => Product)
  product: Product = new Product(); // 產品
}

export class ProposalOwner {
  lastName: string; // 姓氏
  firstName: string; // 名字
  birthday: number; // 生日
  age: number; // 年龄
  gender: Gender; // 性别
  smoke: SmokeCondition; // 是否吸烟
  smokeQuantity: string; // 吸烟量
  residenceRegion: string; // 地区
}

export class HedgePlan {
  beginAge: number; // 开始年龄
  endAge: number; // 结束年龄
  productCode: string; // 产品码
  productName: string; //
  coverageRegion: string; // 医疗险特有条件:保障地区: WORLDWIDE-全球;WORLDWIDE_EXCEPT_US-全球除美国;ASIA-亚洲
  deductible: number; // 医疗险特有条件:自付额
  medicalLevel: string; // 医疗险特有条件:等级
  supplementaryBenefits: string[] = []; // 医疗险特有条件:附加保障(多选): OUTPATIENT-门诊;MATERNITY-产科;DENTAL-牙科
}

export class Riders {
  sumAssured: string; // 保额
  productCode: string; // 产品码
  premium: string; // 每期保费
  @Transform((value) => plainToInstance(MedicalPlan, Object.assign(new MedicalPlan(), value.value)))
  medicalPlan: MedicalPlan = new MedicalPlan(); // 医疗计划
  productName: string;
  categoryCode: string;
  totalPremium: string;
  productYear: string;
  @Transform((value) => plainToArray(DynamicField, value.value))
  dynamicFields: DynamicField[] = [];
  sumAssuredIncrementalNumber: string;
  sumAssuredIncrementalRule: string;
  premiumIncrementalNumber: string;
  premiumIncrementalRule: string;
}

export class MedicalPlan {
  deductible: string; // 自付额
  currency: string; // 货币
  medicalLevel: string; // 医疗险等级
  region: string; // 医疗险保障区域
  supplementaryBenefits: string[]; // 附加保障
}

// .对冲方案预览
export class HedgeParams {
  mainProductCode: string; // 主保险的产品码
  gender: string; // 性别
  medicalLevel: string; // 医疗险-病房等级
  supplementaryBenefits: string[] = []; // 医疗险-附加保障
  endAge: number; // 结束年龄
  productCode: string; // 产品码
  coverageRegion: string; // 医疗险-保障地区
  deductible: number; // 医疗险-自费额
  currency: string; // 货币
  paymentTerm: string; // 主保险的年期
  age: number; // 年龄
}

// .对冲方案预览返回
export class HedgeRes {
  beginAge: string; // 开始年龄
  totalAmount: string; // 总额
  endAge: string; // 结束年龄
  @Transform((value) => plainToArray(Amounts, value.value))
  amounts: Amounts[] = []; // 金额列表
  currency: number; // 货币
}

export class Amounts {
  amount: string; // 金额
  policyYear: string; // 保单年度
  age: string; // 年龄
}

export class ProposalBalance {
  @Expose() alreadyUsed: number; // 已申请计划书数量
  @Expose() proposalLimit: number; // 每日计划书申请数量
  @Expose() limitStatus: boolean; // 是否展示限制
  @Expose() unit: number; // 单位
  @Expose() limitExceeded: boolean; // 超出限制
}

export class ProposalBalanceLimit {
  @Expose() unit: number; // 单位
  @Expose() limitStatus: boolean; // 是否展示限制
  @Expose() quantityLimit: number; // 限制数
}

export enum ProposalStatus {
  PENDING = 'PENDING', // 待处理
  COMPLETED = 'COMPLETED', // 已完成
  CANCELLED = 'CANCELLED', // 已取消
  PROCESSING = 'PROCESSING' // 处理中
}

export class ProposalListResp {
  @Expose() id: number;
  @Expose() proposalId: string;
  @Expose() accountName: string; // 申请人账户
  @Expose() salesName: string; // 销售姓名
  @Expose() companyLogo: string; // 公司Logo
  @Expose() productName: string; // 产品名称
  @Expose() paymentTerm: string; // 供款年期
  @Expose() premium: string; // 保费
  @Expose() sumAssured: string; // 保额
  @Expose() premiumFormat: string;
  @Expose() sumAssuredFormat: string;
  @Expose() gender: Gender; // 性别
  @Expose() age: number; // 年龄
  @Expose() currency: Currency; // 货币
  @Expose() smoke: SmokeCondition; // 吸烟状况
  @Type(() => Material)
  @Expose() url: Material; // 文件
  @Expose() statusNote: string; // 状态说明
  @Expose() salesStatusNote: string; // 新状态说明
  @Expose() status: ProposalStatus; // 状态
  @Expose() applicationTime: number; // 申请时间
  @Expose() reminder: boolean; // 是否提醒
  @Expose() messageCount: number; // 消息数
  @Expose() doable: boolean; // 可製作
  @Expose() failure: boolean; // 是否失敗
  @Expose() unReadCount: number; // 留言数未读数
  @Transform((value) => plainToArray(ProposalStatusNoteHistory, value.value))
  @Expose() statusNoteHistory: ProposalStatusNoteHistory[]; // 歷史狀態

  canRetry(): boolean {
    return this.doable && this.failure;
  }

}

export class ProposalStatusNoteHistory {
  @Expose() note: string; // 狀態說明
  @Expose() createTime: string; // 時間
  @Expose() salesNote: string; // 時間
}

export enum AgeUnit {
  DAY = 'DAY', // 天
  YEAR = 'YEAR', // 年
}

export class AgeRestriction {
  @Expose() min: number; // 最小年龄
  @Expose() minUnit: AgeUnit; // 最小年龄对应单位
  @Expose() max: number; // 最大年龄
  @Expose() maxUnit: AgeUnit; // 最大年龄对应单位

  get ageMin(): number {
    switch (this.minUnit) {
      case AgeUnit.DAY:
        return Math.floor(this.min / 365);
      case AgeUnit.YEAR:
        return this.min;
    }
    return 0;
  }

  get ageMax(): number {
    switch (this.maxUnit) {
      case AgeUnit.DAY:
        return Math.floor(this.max / 365);
      case AgeUnit.YEAR:
        return this.max;
    }
    return 0;
  }
}

export class AmountRestriction {
  @Expose() currency: Currency; // 貨幣
  @Expose() min: number; // 最小
  @Expose() max: number; // 最大
}

export enum WithdrawalRestrictionType {
  AGE = 'AGE', // 年龄
  YEAR = 'YEAR', // 年限
}

export class WithdrawalRestrictionItem {
  @Expose() type: WithdrawalRestrictionType; // 类型
  @Expose() value: number; // 值
}

export class WithdrawalRestriction {
  @Expose() from: WithdrawalRestrictionItem; // 从
  @Expose() to: WithdrawalRestrictionItem; // 至
  @Expose() calculation: string; // 运算符号：+ ; -
  @Expose() calculationValue: number; // 运算值
}

export class ProposalPremiumFinancing {
  @Type(() => BankInfo)
  @Transform((value) => plainToInstance(BankInfo, Object.assign(new BankInfo(), value.value)))
  @Expose() bankInfo: BankInfo = new BankInfo(); // 银行信息
  @Type(() => DiscountInfo)
  @Transform((value) => plainToInstance(DiscountInfo, Object.assign(new DiscountInfo(), value.value)))
  @Expose() discountInfo: DiscountInfo = new DiscountInfo(); // 折扣信息
}

// 银行信息
export class BankInfo {
  @Expose() name: string; // 银行名称
  @Expose() fullOrPartial: string;
  @Expose() loanRatio: number; // 貸款比例
  @Expose() prepaymentInterestRate: number; // 預付保費利息
  @Expose() policyLoanHandlingFeeRate: number; // 貸款手續費率
  @Expose() year: number; // 贷款年限
  @Expose() annualInterestRate: number; // 年利率
}

// 折扣信息
export class DiscountInfo {
  @Expose() firstYearPremiumDiscount: number; // 首年折扣率（单位：%）
  @Expose() firstYearAdditionalDiscount: number; // 首年额外折扣率（单位：%）
  @Expose() annualPremiumDiscount: number; // 年折扣率（单位：%）
  @Expose() premiumCashback: number; // 第二年保费回赠（单位：%）
  @Expose() name: string; // 名称
}

export class BankInfoSearch {
  @Expose() name: string; // 银行名称
}

// 银行信息返回
export class BankInfoSetting {
  @Expose() name: string; // 银行名称
  @Expose() loanRatio: number; // 貸款比例
  @Expose() loanYears: LoanYears[]; // 贷款年限
  @Expose() prepaymentInterestRate: number; // 預付保費利息
  @Expose() policyLoanHandlingFeeRate: number; // 貸款手續費率
}

export class LoanYears {
  @Expose() year: number; // 贷款年限
  @Expose() annualInterestRate: number; // 年利率
}

export class DiscountInfoSearch {
  @Expose() name: string; // 银行名称
}

// 折扣信息返回
export class DiscountInfoSetting {
  @Expose() id: number; // 主键id
  @Expose() name: string; // 配置名称
  @Expose() firstYearPremiumDiscount: number; // 首年折扣率（单位：%）
  @Expose() annualPremiumDiscount: number; // 年折扣率（单位：%）
  @Expose() premiumCashback: number; // 第二年保费回赠（单位：%）
  @Expose() firstYearAdditionalDiscount: number; // 首年额外折扣率（单位：%）
}

// 折扣信息返回
export class UpdateFinancing {
  @Expose() proposalId: string; // 计划书编号
  @Type(() => ProposalPremiumFinancing)
  @Transform((value) => plainToInstance(ProposalPremiumFinancing, Object.assign(new ProposalPremiumFinancing(), value.value)))
  @Expose() premiumFinancing: ProposalPremiumFinancing = new ProposalPremiumFinancing(); // 保费融资;
}

// 账号列表返回
export class AccountList {
  @Expose() companyCode: string; // 保险公司唯一识别
  @Expose() statusNote: string; // 账号状态说明
  @Expose() enable: boolean; // 启动(默认为1): 1-启动;0-不启用
  @Expose() companyName: string; // 保险公司名
  @Expose() id: number; // 主键
  @Expose() userName: string; // 账号
  @Expose() verifyStatus: string;
}

// 账号列表返回
export class CreateAccount {
  @Expose() id: number; // 编号
  @Expose() companyCode: string; // 保险公司唯一识别
  @Expose() password: string; // 密码
  @Expose() enable: boolean; // 启动(默认为1): 1-启动;0-不启用
  @Expose() userName: string; // 账号
}
