import { Component, OnInit } from '@angular/core';
import { WorkbenchService } from './workbench.service';
import {
  AccountInformation,
  DashboardTotal,
  Instance,
  Message,
  MessageListResp,
  MessageType,
  Passport
} from '../api/types';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MetadataService } from '../shared/service/metadata.service';
import { Router } from '@angular/router';
import { MessageService } from '../message/message.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BaseComponent } from '../base/base.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { plainToInstance } from 'class-transformer';
import { InformationDetailComponent } from '../information/information-detail/information-detail.component';
import { InformationService } from '../information/information.service';
import { InformationType } from '../information/information-types';
import { AccountService } from '../account/account.service';
import { log } from 'ng-zorro-antd/core/logger';

@Component({
  selector: 'app-workbench',
  templateUrl: './workbench.component.html',
  styleUrls: ['./workbench.component.less']
})
export class WorkbenchComponent extends BaseComponent implements OnInit {

  loading = false;
  passport: AccountInformation = new AccountInformation();
  dashboardTotal: DashboardTotal;
  instance: Instance;

  constructor(public workbenchService: WorkbenchService,
              private metadataService: MetadataService,
              private informationService: InformationService,
              private drawerService: NzDrawerService,
              private messageService: MessageService,
              private notificationService: NzNotificationService,
              private translate: TranslateService,
              private router: Router,
              private toastr: ToastrService,
              private accountService: AccountService) {
    super();
  }

  ngOnInit(): void {
    this.instance = this.workbenchService.mockInstance;
    this.passport = this.accountService.accountInfo;
    this.workbenchService.getDashboardTotal()
      .subscribe(
        data => {
          this.dashboardTotal = data;
        },
        error => {
        })
      .disposedBy(this.disposeBag);
  }

  messageTypeTranslate(messageType: MessageType): string {
    return this.metadataService.translate('messageType', messageType);
  }

  onStatisticsMore(): void {
    this.router.navigate(['/user/dashboard']).then();
  }

  onMessageMore(): void {
    this.router.navigate(['/user/message/message-main']).then();
  }

  onMessageDetail(resp: MessageListResp): void {
    const message = plainToInstance(Message, resp);
    this.messageService.messageDetail.emit(message);
  }

  onInformationMore(): void {
    // this.translate.get('ComingSoon').subscribe(
    //   (text: string) => {
    //     this.toastr.info(text);
    //   });
    this.router.navigate(['/user/information/information-main'], {queryParams: {promotion: false}}).then();
  }

  onInformationDetail(id: number): void {
    this.loading = true;
    this.informationService.info(id)
      .subscribe(
        information => {
          this.loading = false;
          const drawerRef = this.drawerService.create<InformationDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: InformationDetailComponent,
            nzContentParams: {
              information
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });
  }

  showName(): string {
    switch (this.passport.salesChannel) {
      case 'BANK':
        return this.passport.bankName;
      case 'CORPORATE':
        return this.passport.institutionName;
      default:
        return this.workbenchService.workbench.userName;
    }
  }

  onDataBoard(): void {
    console.log('onDataBoard');
    this.router.navigate(['/user/data-board']).then();
  }
}
