<div style="padding: 20px 20px 0">
  <div class="common-title" style="margin-bottom: 30px">
    {{'BookingImport' | translate}}
  </div>

  <nz-spin [nzSpinning]="spinning">
    <div *ngIf="bookings" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzFrontPagination]="false"
                [nzData]="bookings.list"
                [nzPageIndex]="bookings.pageNum"
                [nzPageSize]="bookings.pageSize"
                [nzTotal]="bookings.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1300'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">
                <div>
                  <span>{{'BookingList' | translate}}</span>
                </div>
                <div>
                  <a nz-button nzType="link" nzSize="small"
                     nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                     style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'BookingSearch' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onBookingSearch()">
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ bookings.total }}</span>
          </div>
        </ng-template>

        <ng-template #commonTrs>
          <tr>
            <th nzLeft nzWidth="165px">
              {{'BookingNo' | translate}}
            </th>
            <th nzWidth="160px">
              <span>{{'Referee' | translate}}</span>
            </th>
            <th nzWidth="260px">
              <span>{{'ProductName' | translate}}</span>
            </th>
            <th nzWidth="120px">
              <span>{{'Insurer' | translate}}</span>
            </th>
            <th nzWidth="120px">
              <span>{{'Holder' | translate}}</span>
            </th>
            <th nzWidth="120px">
              <span>{{'Insured' | translate}}</span>
            </th>
            <th>
              <span>{{'PaymentTerm' | translate}}</span>
            </th>
            <th nzWidth="100px"><span>{{'Status' | translate}}</span>
            </th>
            <th nzWidth="280px">
              <span>{{'Time' | translate}}</span>
            </th>
          </tr>
        </ng-template>

        <thead>
        <ng-container *ngTemplateOutlet="commonTrs"></ng-container>
        </thead>

        <tbody>
        <tr *ngFor="let booking of bookings.list; let i = index" appThrottleClick
            (throttleClick)="onBookingDetail(booking.bookingNo)">
          <td nzWidth="165px" nzLeft>
            <span style="font-weight: bold; font-size: 12px">
              #{{ booking.bookingNo }}
            </span>
          </td>
          <td>
            <div>
              <span>{{ dynamicEncryption('salesName', booking.salesName) }}</span>
              <span>{{ dynamicEncryption('accountName', booking.accountName) }}</span>
            </div>
            <div *ngIf="workbench.secondAccountName">
              <span>{{ dynamicEncryption('salesName', booking.secondSalesName) }}</span>
              <span>{{ dynamicEncryption('accountName', booking.secondAccountName) }}</span>
            </div>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="booking.companyLogo" alt=""/>
              <span>{{ booking.productName || 'N/A' }} <span
                *ngIf="booking.productYear">&nbsp;({{ booking.custom ? (booking.productYear | yearConversion) : (booking.productYear | yearsOrMonthOrAge)}}
                )</span> </span>
            </div>
          </td>
          <td>{{ booking.companyName }}<nz-tag *ngIf="booking.region" nzColor="processing">{{booking.region | metadataTranslate: 'productCompanyRegion'}}</nz-tag></td>
          <td>{{ booking.name }}<br>{{booking.namePinYin}}</td>
          <td>{{ booking.insuredName }}<br>{{booking.insuredPinyin}}</td>
          <td>{{ booking.productYear }}</td>
          <td>
            <div [ngSwitch]="booking.status">
              <div>
                <span *ngSwitchCase="'DRAFT'" class="text-warning">●</span>
                <span *ngSwitchCase="'PENDING_REVIEW'" class="text-warning">●</span>
                <span *ngSwitchCase="'APPLIED'" class="text-processing">●</span>
                <span *ngSwitchCase="'PRE_UNDERWRITING'" class="text-processing">●</span>
                <span *ngSwitchCase="'UNDERWRITING_APPROVAL'" class="text-processing">●</span>
                <span *ngSwitchCase="'CONFIRMED'" class="text-processing">●</span>
                <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                <span> {{ booking.status | metadataTranslate: 'bookingStatus' }}</span>
              </div>
            </div>
          </td>
          <td>
            <div [ngSwitch]="booking.status">
              <div>
                <div *ngSwitchCase="'DRAFT'">
                  <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                  {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                </div>
                <div *ngSwitchCase="'APPLIED'">
                  <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                  {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                </div>
                <div *ngSwitchCase="'CONFIRMED'">
                  <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                  {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                  <br>
                  <span *ngIf="!booking.hideTr">
                    <span style="color: #999999; font-size: 12px">{{'TechnicalRepresentative' | translate}} : </span>
                    {{booking.trName}}
                  </span>
                  <br *ngIf="!booking.hideTr">
                  <span style="color: #999999; font-size: 12px">{{'LocationOfReservation' | translate}} : </span>
                  <span nz-tooltip="true" [nzTooltipTitle]="booking.appointmentAddress">
                    {{booking.appointmentAddress | ellipsis: 8}}
                  </span>
                </div>
                <div *ngSwitchCase="'COMPLETED'">
                  <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                  {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                  <br>
                  <span *ngIf="!booking.hideTr">
                    <span style="color: #999999; font-size: 12px">{{'TechnicalRepresentative' | translate}} : </span>
                    {{booking.trName}}
                  </span>
                  <br *ngIf="!booking.hideTr">
                  <span style="color: #999999; font-size: 12px">{{'LocationOfReservation' | translate}} : </span>
                  <span nz-tooltip="true" [nzTooltipTitle]="booking.appointmentAddress">
                    {{booking.appointmentAddress | ellipsis: 8}}
                  </span>
                </div>
                <div *ngSwitchCase="'CANCELLED'">
                  <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                  {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>

  <nz-modal
    [(nzVisible)]="importVisible"
    [nzTitle]="importModalTit"
    [nzContent]="importModalContent"
    [nzFooter]="null"
    nzCentered
    [nzWidth]="'400px'"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="importVisible = false"
  >
    <ng-template #importModalTit style="text-align: center">{{'Import' | translate}}</ng-template>
    <ng-template #importModalContent>
      <div style="display: flex; justify-content: center; padding-bottom: 20px;">
        <nz-radio-group [(ngModel)]="importInfoValue">
          <label nz-radio [nzValue]="'holder'"><span>{{'OwnerImport' | translate}}</span></label>
          <label nz-radio [nzValue]="'insured'"><span>{{'InsuredImport' | translate}}</span></label>
        </nz-radio-group>
      </div>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default"
                appThrottleClick (throttleClick)="importVisible = false"
        >{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary"
                appThrottleClick (throttleClick)="openImportDrawer()"
        >{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>

</div>
